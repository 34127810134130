/**
 * @fileOverView
 * @description PersonMeter Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var Meter = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/meter";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        personKey: 'string',
        tier_id: 'number',
        number_vybits: 'number',
        count_daily: 'number',
        count_monthly: 'number',
        last_log_dts: 'string',
        monthly_reset_dts: 'string',
        paid_thru_dts: 'string',
        cap_vybits: 'number',
        cap_daily: 'number',
        cap_monthly: 'number',
        frac_daily: 'string',
        frac_monthly: 'string',
        warn_daily_sent: 'boolean',
        warn_monthly_sent: 'boolean',
        err_daily_sent: 'boolean',
        err_monthly_sent: 'boolean',
        err_payment_sent: 'boolean',
        warn_payment_sent: 'boolean',
        current_time: 'string',
        customer_id: 'string',
        session_url: 'string'
    },

    session: {
      name: ['string', true, ''],
      email: ['string', true, '']
    },

    derived: {

        plan: {
            deps: ['tier_id'],
            fn: function () {
                let plan = "NONE";
                if (this.tier_id == 1) plan = "FREE";
                if (this.tier_id == 2) plan = "BRONZE";
                if (this.tier_id == 3) plan = "SILVER";
                if (this.tier_id == 4) plan = "GOLD";
                if (this.tier_id == 5) plan = "TEST";
                return plan;
            }
        },

        linkedPlan: {
            deps: ['plan'],
            fn: function(){

                link = `<a href="https://${app.config.pwHost}?pk=${this.personKey}" target="_blank" onclick="app.utils.cancelBubble()">${this.plan}</a>`;

                if(app.platform == "app") link = `<a href="javascript:void(0)" onclick='cordova.InAppBrowser.open("https://${app.config.pwHost}?pk=${this.personKey}", "_system");'>${this.plan}</a>`;

                return link;
            }
        },

        vybits: {
            deps: ['cap_vybits'],
            fn: function () {
                return (app.vybs?.length || 0) + " / " + this.cap_vybits;
            }
        },

        daily_count: {
            deps: ['count_daily'],
            fn: function(){
                return this.count_daily + " / " + this.cap_daily
            }
        },

        daily_reset: {
            deps: ['last_log_dts'],
            fn: function(){
                const now = new Date()
                const nextMidnightUTC = new Date(now.getTime() + (86400000 - ((new Date()).getTime() % 86400000)));
                const timeString = nextMidnightUTC.toLocaleTimeString([], { hour: 'numeric' });
                const timeParts = timeString.split(' ');
                const formattedTime = `${timeParts[0].replace(/^0+/, '')} ${timeParts[1]}`;
                return 'resets at ' + formattedTime;
            }
        },

        monthly_count: {
            deps: ['count_monthly'],
            fn: function(){
                return this.count_monthly + " / " + this.cap_monthly
            }
        },

        monthly_reset: {
            deps: ['monthly_reset_dts'],
            fn: function(){
                const date = new Date(this.monthly_reset_dts);
                const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });

                return 'resets on ' + formattedDate;
            }
        }
    }
});

module.exports = Meter;
