/**
 * @fileOverView
 * @description Sound Candidate View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SoundCandidateTemplate = require('../../templates/widgets/soundcandidatetemplate.html');
var config = require("../../etc/config");
var Sound = require('../../models/sound');
var app = require('ampersand-app');

var SoundCandidateView = AmpersandView.extend({
    template: SoundCandidateTemplate,

    initialize: function(options){
        this.model.mode = options.mode ? options.mode : "sounds";
    },

    bindings: {
        "model.nameCleaned": {
            type: 'text',
            hook: 'soundName'
        },
        "model.descriptionDisplay" : {
            type: 'text',
            hook: 'description'
        },
        "model.actionMode" : {
            type: 'innerHTML',
            hook: 'vybName'
        },
        "model.creator" : {
            type: 'innerHTML',
            hook: 'creator'
        },

        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }
    },

    events: {
        "click .vdeltaimg, img.vdeltaimg, div.vsoundinfo" : "playSound",
        "click [data-hook=import], button.vcreatevybbutton, button.usesoundbutton" : "importSound",
        "click .vhidebutton" : "removeSound"
    },

    playSound: function(){
        this.model.set('pinging',true);
        this.model.playSound();
    },

    importSound: function(){


        var that = this;

        var meta = {
            source: this.model.url,
            license: this.model.license,
            username: this.model.username
        };

        var sound = new Sound();
        sound.save({
                name: this.model.nameCleaned,
                description: this.model.description.toString().substring(0,255),
                type: 'mp3',
                originalUri: this.model.soundFile,
                meta: meta
            },
            {
            success: function(model,response,options){
                that.parent.collection.remove(that.model);
                app.sounds.add(model,{at:0});
                that.createVyb(model);
            },
            error: function(model,response,options){
                console.log('error: sound is already in vybit library');
                that.parent.collection.remove(that.model);
                if(that.model.mode=='sounds'){
                    model.fetch({data:{
                        originalUri : model.originalUri
                    }});
                    that.createVyb(model);
                }
            }
        });

    },

    removeSound: function(){
      this.parent.collection.remove(this.model);
    },

    createVyb: function(sound){
        sound.mode = this.model.mode;
        app.utils.loadVybWithSound(sound);
    }
});

module.exports = SoundCandidateView;
