/**
 * @fileOverView
 * @description Personsounds Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var Sound = require("../models/sound");
var app = require("ampersand-app");

var Sounds = RestCollection.extend({

    model: Sound,

    indexes: ['key','name'],

    extraProperties: 'allow',

    curLength: 0,

    lastLength: 0,

    appendLock: false,

    searchQuery: '',

    url: function(){ return app.auth.authenticated ? app.config.restApiUrl + "/sounds_authd" : app.config.restApiUrl + "/sounds"; },

    ajaxConfig: app.utils.setAuthTokenHeader

});

module.exports = Sounds;
