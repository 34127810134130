/**
 * @fileOverView
 * @description Vybit Collection View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybsTemplate = require('../../templates/widgets/vybstemplate.html');
var VybView = require('./vybview');
var app = require('ampersand-app');

var VybsView = AmpersandView.extend({

    template: VybsTemplate,

    initialize: function(options){
        this.collection = app.vybs;
        this.entity = "vybs";
        this.lastLoad = Date.now();
    },

    render: function(){
        this.renderWithTemplate();
        if(app.vybs.models.length==0) return this;
        else this.el.querySelector("div.vfirst").remove();
        this.renderCollection(app.vybs,VybView,'#vybstable');

        var scrollClass = app.currentPageName=="MainPage" ? "vscrolltophalf" : "vscrollfull";
        this.el.classList.add(scrollClass);
    },

    events: {
        "scroll" : "infiniteScroll"
    },

    infiniteScroll: function(){ app.utils.infiniteScroll(this); }

});

module.exports = VybsView;
