/**
 * @fileOverView
 * @description Docs View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var DocBoxTemplate = require('../../templates/widgets/docboxtemplate.html');
var Doc = require("../../models/doc");
var app = require('ampersand-app');

var DocsView = AmpersandView.extend({

    template: DocBoxTemplate,

    initialize: function (options) {
        var self = this;
        this.model = new Doc();
        this.model.set('content','');
         this.model.fetch({
            wait: true,
            success : function(model, response, options){
                console.log('retrieved docs');

                if(app.platform!="browser" || !window.location.href.match('www')){
                    document.querySelectorAll("div.vweb").forEach(
                        function(el){
                            el.remove();
                        }
                    );
                }

                app.utils.openDocCardfromFrag();

                //set up example sounds
                document.querySelectorAll("li.vsl_li").forEach(function(el){
                    var sk = el.getAttribute("data-sk");
                    el.setAttribute("onclick","app.utils.playSoundFileFromKey('"+ sk +"')");
                });

                app.utils.setDisplayMode();

            },
            error: function(){
                console.log('cannot retrieve docs');
            }
        });
    },

    bindings: {
        "model.content" : {
            type: 'innerHTML',
            hook: 'content'
        },

        "model.demoSearch" : {
            type: 'innerHTML',
            selector: 'div.vsdemobox'
        }
    },

    events: {
        "click button[data-vybsubkey]" : "subscribeToVyb"
    },

    subscribeToVyb: function(event){
        var el = event.target;
        el = el.closest('[data-vybsubkey]');
        if(el.getAttribute('data-vybsubkey')){
            app.utils.subscribeToVybFromKey(el.getAttribute('data-vybsubkey'), el);
        }else{
            console.log('no subscription key found');
        }

    }

});

module.exports = DocsView;
