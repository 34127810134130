/**
 * @fileOverView
 * @description Xhr Model, general purpose<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var Xhr = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/" + this.type;
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        type : 'string',
        key : 'string'
    }
});

module.exports = Xhr;
