/**
 * @fileOverView
 * @description Doc Model, pulls current documentation<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var ServiceAuth = AmpersandModel.extend({

    urlRoot: function(){
        return app.config.restApiUrl + "/serviceauth";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        service: 'string'
    },

    derived: {


        displayName: {
            deps: ['service'],
            fn: function(){

                var service = "";
                if(app.queryParams.client_id!=undefined) service = app.config.serviceAuth&[app.queryParams.client_id].displayName || app.queryParams.client_id;
                else if(window.localStorage.getItem('serviceAuth')!=null) service = app.config.serviceAuth[window.localStorage.getItem('serviceAuth')].displayName || window.localStorage.getItem('serviceAuth');

                return service.toUpperCase();
            }
        }
    },

    processAllowRedirect: function() {

        //alertdesk
        if (this.service == "alertdesk") {
            this.alertDeskRedirect();

            //all others
        } else {
            this.defaultRedirect();
        }
    },

    defaultRedirect: function(){

        var redirectUrl = window.localStorage.getItem("redirect_uri")!=undefined ? decodeURIComponent(window.localStorage.getItem("redirect_uri")) : app.config.serviceAuth[this.service].redirect_uri;
        redirectUrl = redirectUrl + "?state=" + window.localStorage.getItem("state");
        redirectUrl = redirectUrl + "&code=" + app.profile.key;

        this.saveTokenAndRedirect(redirectUrl);

    },

    alertDeskRedirect: function(){
        var redirectUrl = window.localStorage.getItem("redirect_uri")!=undefined ? decodeURIComponent(window.localStorage.getItem("redirect_uri")) : app.config.serviceAuth[this.service].redirect_uri;
        var queryString = "?client_id=" + app.config.serviceAuth.alertdesk.clientId + "&redirect_uri=" + app.config.restApiUrl + "/servicetoken";
        redirectUrl += queryString;

        this.saveTokenAndRedirect(redirectUrl);
    },

    saveTokenAndRedirect: function(redirectUrl){
        this.fetch({
            data: {service: this.service},
            success: function(model,response){

                console.log(response.body);

                console.log('redirecting to ' + redirectUrl);
                window.localStorage.clear();
                app.queryParams = {};
                if(app.platform=="app") app.loadData();
                window.location.href = redirectUrl;
            },
            error: function(model,response){
                console.log('error in serviceauth', response.body);
            }
        });
    }

});

module.exports = ServiceAuth;
