/**
 * @fileOverView
 * @description SubscriptionPortal<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var moment = require('moment');
var app = require("ampersand-app");

var SubscriptionPortal = AmpersandModel.extend({

    idAttribute: 'key',

    url: app.config.subscriptionUri + "/portal",

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        sessionUrl: 'string',
    },

    derived: {}
});

module.exports = SubscriptionPortal;
