/**
 * @fileOverView
 * @description Top Level Main View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var mainTemplate = require('../templates/main.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var MainView = AmpersandView.extend({

    template : mainTemplate,

    initialize: function(options){

        this.pageView = new app.LogPageView({ parent: this });
        if(options.pageView!=null) {
            if (options.pageView == "VybListPage") {
                this.pageView = new app.VybListPageView({parent: this});

            } else if (options.pageView == "WwwPage") {
                this.pageView = new app.WwwPageView({parent: this});

            } else if (options.pageView == "PwPage") {
                this.pageView = new app.PwPageView({parent: this});

            } else if (options.pageView == "PublicVybsPage") {
                this.pageView = new app.PublicVybsPageView({parent: this});

            } else if (options.pageView == "SubscriptionCardPage") {
                this.pageView = new app.SubscriptionCardPageView({parent: this});

            } else if (options.pageView == "SoundsPage") {
                this.pageView = new app.SoundsPageView({parent: this});

            } else if (options.pageView == "ProfilePage") {
                this.pageView = new app.ProfilePageView({parent: this});
            }
        }
    },

    render: function () {
        // render our template
        this.renderWithTemplate();

        // set up our page switcher for that element
        app.pageSwitcher.el = this.queryByHook('page');
        ;
        app.currentPageName = this.pageView.pageName!=undefined ? this.pageView.pageName : "MainPage";

        app.pageSwitcher.set(this.pageView);

    }

});

module.exports = MainView;