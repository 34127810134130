/**
 * @fileOverView
 * @description Sound Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var CreateVybButton = require('../templates/components/createVybButton.html');
var UseSoundButton = require('../templates/components/useSoundButton.html');
var app = require("ampersand-app");

var SoundCandidate = AmpersandModel.extend({

    urlRoot: function(){
        return app.config.soundApiUrl + "/soundcandidate";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        id : 'number',
        name : 'string',
        description: 'string',
        username: 'string',
        license: 'string',
        url: 'string',
        previews: 'object'
    },

    session: {
        mode : ['string',true,'vyb'],
        pinging: ['boolean',true,false]
    },

    derived: {

        soundFile: {
            deps: ['previews'],
            fn: function(){
                return this.previews['preview-hq-mp3'];
            }
        },

        nameCleaned: {
            deps: ['name'],
            fn: function(){
                var name = this.name.replace(/[-_]/g," ");
                return name.replace(/\..{2,4}$/,"");
            }
        },

        actionMode: {
            deps: ['key','mode'],
            fn: function(){
                return this.mode=='vyb' ? UseSoundButton() : CreateVybButton();
            }
        },

        creator : {
            deps: ['username'],
            fn: function(){
                return this.username!=null && this.username!=undefined ? 'by <a class="vcreator" onclick="app.utils.cancelBubble()" target="_new" href="'+this.url+'">' + this.username + ' at freesound.org</a>.' : '';
            }
        },

        descriptionDisplay: {
            deps: ['description'],
            fn: function(){
                var el = document.createElement('div');
                el.innerHTML = this.description;
                var text = el.innerText;
                delete el;
                return text;
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        }
    },

    playSound: function(){
        var self = this;
        var file = this.key=="none" ? null : this.soundFile;
        var soundObject = new Audio(file);
        soundObject.onended = function(){
            self.set('pinging',false);
        };
        soundObject.currentTime=0;
        soundObject.volume = 1.0;
        soundObject.play();
        soundObject = {};
        soundObject = null;
        delete soundObject;
    }


});

module.exports = SoundCandidate;
