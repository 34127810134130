/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var LogPageTemplate = require('../../templates/pages/logpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var LogsView = require('../widgets/logsview');
var SearchView = require('../components/search');
var app = require('ampersand-app');

var LogsPageView = AmpersandView.extend({

    template : LogPageTemplate,

    pageName: "LogsPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybit Log',
                    noback: true
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        logs: {
            selector : '#content',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new LogsView({
                    el: el,
                    parent: this
                });
            }
        },

        search: {
            selector : 'div.vsearchtable',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'logs'
                });
            }
        }
    }
});

module.exports = LogsPageView;
