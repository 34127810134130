/**
 * @fileOverView
 * @description eventLog, app event logging and handlers<br>
 * @copyright 2015-present Flatirontek LLC
 **/

'use strict';
var app = require('ampersand-app');

module.exports = {

    records: [],

    add: function (message, type = 'notice') {

        var now = dayjs().format('YYYY-MM-DD HH:mm:ss');
        var msg = typeof(message)=="string" ? message : app.YAML.stringify(message);
        if(typeof(app.profile)!="undefined" && app.profile.type=="admin"){
            app.eventLog.records.push(now + " " + type.toString().toUpperCase() + ": " + msg);
        }
        if(typeof(message)=="object") console.dir(message);
        else console.log(message);
    },

    clear: function(){
        app.eventLog.records = [];
    }
}