/**
 * @fileOverView
 * @description Meter View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var MeterTemplate = require('../../templates/widgets/metertemplate.html');
var app = require('ampersand-app');

var MeterView = AmpersandView.extend({
    template: MeterTemplate,

    initialize: function (options) {
        this.model = app.meter;
        if(this.model?.tier_id==0) this.template = '<div style="margin-bottom:100px"></div>';
    },

    bindings: {
        "model.linkedPlan": {
            type: 'innerHTML',
            hook: 'plan'
        },
        "model.vybits": {
            type: 'text',
            hook: 'vybits'
        },
        "model.daily_count": {
            type: 'text',
            hook: 'daily'
        },
        "model.daily_reset": {
            type: 'text',
            hook: 'daily_reset'
        },
        "model.monthly_count": {
            type: 'text',
            hook: 'monthly'
        },
        "model.monthly_reset": {
            type: 'text',
            hook: 'monthly_reset'
        },
    }

});

module.exports = MeterView;
