/**
 * @fileOverView
 * @description Personsounds Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var SoundCandidate = require("../models/soundcandidate");
var config = require("../etc/config");
var app = require("ampersand-app");

var SoundCandidates = RestCollection.extend({

    model: SoundCandidate,

    url: function(){ return config.restApiUrl + "/soundcandidates"; },

    ajaxConfig: app.utils.setAuthTokenHeader,

    page : 1,

    next: 1,

    appendLock: false,

    indexes: ["id"],

    parse: function(data) {

        this.next = data.next;

        if(data.next!=undefined && data.next!=null){
            var match = data.next.match(/&page=([0-9]{1,})&/);
            if(match[1]!=undefined) this.page = match[1];
        }
        return data.results;
    }

});

module.exports = SoundCandidates;
