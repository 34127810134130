/**
 * @fileOverView
 * @description Vybit App Entry Point<br>
 * @copyright 2015-present Flatirontek LLC, all rights reserved
 **/
import firebase from 'firebase/app';
import 'firebase/auth';

var app = require('ampersand-app');
var domready = require('domready');
var MainView = require('./views/main');
var socket = require('socket.io-client');
var YAML = require('yamljs');

global.jQuery = require('jquery');
global.Tether = require('tether');
global.dayjs = require('dayjs');
require('bootstrap');

app.extend({

    version: '1.7.2',

    config: require("./etc/config"),

    serviceAuth: false,

    onBackgroundMessageFired: false,

    back: false,

    tmpDisableScroll: false,

    vyb: null,

    incomingUrl: window.location.href,

    queryString: window.location.search.substring(1),

    YAML: YAML,

    pageContainer: function(){ document.querySelector('div[data-hook="vc"]'); },

    mainPageView: function(){ return new this.LogPageView(); },

    soundViews: {},

    eventLog: require('./lib/eventlog'),

    //load utilities
    utils: require('./lib/utilities'),
    fsutils: require('./lib/fsutils'),
    sfutils: require('./lib/sfutils'),
    glutils: require('./lib/glutils'),

    initialize: function(){

        this.eventLog.add('initializing app');
        this.platform = document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1 ? 'app' : 'browser';
        this.eventLog.add('app is ' + this.platform);
        if (this.platform=='app') {

            document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
            document.addEventListener('resume', this.onDeviceResume.bind(this), false);

        } else {
            this.platformId = 'web';
            this.init();
        }
    },

    appendBaseTag: function(){
        var newBase = document.createElement("base");
        newBase.setAttribute("href", "/www");
        document.getElementsByTagName("head")[0].appendChild(newBase);
    },

    onDeviceReady: function() {
        this.eventLog.add('reached onDeviceReady');
        this.receivedEvent('deviceready');
    },

    onDeviceResume: function(){

        console.log('reached onDeviceResume');

        if(this.auth.authenticated){
            this.loadLogs(false);
            this.loadUsedSounds();
        }

        //TODO, else if oobCode and client_id, checkEmailSignIn
        if(this.queryParams.client_id!=undefined) this.auth.checkAuth();

    },

    receivedEvent: function(id) {
        var self = this;

        this.eventLog.add('reached received event');

        if(StatusBar!=undefined){
            StatusBar.hide();
            setTimeout(function(){StatusBar.show()},200);
        }
        this.platformId = cordova.platformId;
        
        if(typeof(universalLinks)!=="undefined"){
            universalLinks.subscribe('incoming', function (eventData) {
                app.eventLog.add('incoming deep link');
                app.eventLog.add(eventData);
                self.eventData = eventData;

                //email sign in
                if(typeof(eventData.params.ifl)!=="undefined" && eventData.params.ifl.match("oobCode")) self.checkEmailSignIn(eventData.params.ifl);

                //for ios
                if(typeof(eventData.params.link)!=="undefined" && eventData.params.link.match("sk=")) app.utils.captureSubscriptionKeyFromDeepLink(eventData.params.link);

                //subscriptions
                if(typeof(eventData.params.sk)!=="undefined"){
                    window.localStorage.setItem("subscriptionKey",eventData.params.sk);
                    if(app.auth.authenticated) app.utils.processSubscription();
                    else app.renderMainLoginPage();
                }
                if(typeof(eventData.path)!=="undefined" && eventData.path.match("subscribe")!=null){
                    window.localStorage.setItem("subscriptionKey", eventData.path.split("/").pop());
                    if(app.auth.authenticated) app.utils.processSubscription();
                    else app.renderMainLoginPage();
                }

                //serviceauth
                if(typeof(eventData.params.client_id)!=="undefined" && eventData.params.client_id.length>0) app.queryParams = eventData.params;

                //auth redirect, TODO, google sign-in proxy fix for ios
                // if(typeof(eventData.path)!=="undefined" && eventData.path.match("__/auth/handler")!=null){
                //     console.log("caught an auth redirect");
                //     console.log(eventData.url);
                // }

            });
        }

        this.init();
    },

    init: function () {

        this.eventLog.add('begin init');
        this.pageSwitcher = require('./switchers/pageswitcher');

        this.utils.captureQueryParams();

        this.loadTemplates();
        this.loadModels();
        this.loadCollections();
        this.loadPageViews();
        this.auth = new this.Auth();

        var self = this;

        if(this.platform=="app") this.utils.firebaseAuthPrep();

        this.firebase = firebase;

        //browsers
        if(this.platform=="browser") this.config.firebase.authDomain = this.config.hostName;

        //hack to make google login work for ios
        if(this.platformId=="ios") this.config.firebase.authDomain = this.config.firebase.iOsAuthDomain;

        //service authentication
        if(app.queryParams.client_id!=undefined || window.localStorage.getItem("serviceAuth")!=null){
            this.config.firebase.authDomain = this.config.firebase.serviceAuthDomain;
        }

        this.firebase.initializeApp(this.config.firebase);

        if(this.queryParams.oobCode!=undefined) this.checkEmailSignIn();

        this.firebase.auth().onAuthStateChanged(function(user) {

            if(self.platform=="app") cordova.plugins.backgroundMode.disable();

            self.eventLog.add('begin firebase.auth.onAuthStateChanged, user:');
            console.dir(user);
            if(user){

                window.sessionStorage.removeItem("authInProgress");
                self.auth.set('idToken', user._lat);
                self.auth.set('provider',user.providerData[0].providerId);
                self.auth.fetch({
                    success: function(model,response){
                        model.set('authenticated',response.authenticated);
                        model.set('jwtToken',response.jwtToken);
                        self.auth.checkAuth();
                        //self.lastActivity = Math.round((new Date()).getTime() / 1000);
                    },
                    error: function(model,response){
                        console.log('auth failed:');
                        console.log(response);
                        model.set('authenticated',false);
                        self.auth.checkAuth();
                    }
                });
            }else{
                self.auth.checkAuth();
            }
        });

        self.utils.startBackgroundRefresh();
    },

    checkEmailSignIn: function(link=this.incomingUrl){
        console.log('location for checkEmailSignIn is ' + link);

        if (app.firebase.auth().isSignInWithEmailLink(link)) {
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide the email for confirmation:');
            }
            app.firebase.auth().signInWithEmailLink(email, link)
                .then(function(result) {
                    window.localStorage.removeItem('emailForSignIn');
                    console.log('email sign in confirmed: ', result);
                })
                .catch(function(error) {
                    console.log('email sign in error: ', error);
                    if(error.code=="auth/invalid-action-code"){
                        alert('Error: Invalid Authentication Link');
                    }
                });
        }
    },

    renderMainLoginPage: function(){
        var modelOptions = {mainTitle : 'Welcome to Vybit!'};
        if(window.localStorage.getItem('demoSoundName')){
            modelOptions.loginMessage = '<span class="vib">Sign In to Create Your Vybit Using Sound&nbsp;</span>' +
                '<span class="vib">"' + window.localStorage.getItem('demoSoundName') + '"</span>' +
            ' <span class="vsdemoclear"> &#9447;</span>';
        }

        this.renderLoginPage(modelOptions);
    },

    renderWwwPage: function(){
        if(window.location.pathname=="/public"){
            this.renderPage("PublicVybsPage");
        }else{
            this.renderPage("WwwPage");
        }
    },

    renderPwPage: async function(){

        const modelOptions = {
            showGettingStarted: false,
            loginMessage: 'Sign In to Manage Subscription Plan'
        };

        if(this.auth.authenticated){

            if(app.queryParams.pk!=undefined && app.queryParams.pk != app.auth.personKey){
                app.auth.signOut();
                app.renderLoginPage(modelOptions);
            }else{
                app.loadProfile();
                app.loadMeter();
                this.renderPage("PwPage");
            }

        }else{
            this.renderLoginPage(modelOptions);
        }
    },

    renderLoginPage: function(modelOptions={}){
        var pageview = new app.LoginPageView({
            el: document.querySelector('body'),
            modelOptions: modelOptions
        });
        domready(function() {
            pageview.render();
        });
    },

    renderServiceAuth: function(){
        if(this.auth.authenticated){
            var pageview = new app.ServiceAuthPageView({
                el: document.querySelector('body')
            });
        }else{
            var pageview = new app.LoginPageView({
                el: document.querySelector('body'),
                modelOptions: {showGettingStarted: false}
            });
        }
        domready(function() {
            pageview.render();
        });
    },

    renderSubscriptionPage: function(){
        var pageview = new app.SubscriptionPageView({
                el: document.querySelector('body')
        });
        domready(function() {
            pageview.render();
        });
    },

    renderDemoSoundsPage: function(){
        var pageview = new app.DemoSoundsPageView({
            el: document.querySelector('body'),
            viewOptions: {"mode": "sounds"}
        });
        this.currentPage = pageview;
        domready(function() {
            pageview.render();
        });
    },

    renderVybPageForDemoSound: function(){
        var sound = new app.Sound({key: window.localStorage.getItem('demoSoundKey')});
        sound.fetch({
            success: function(model,response){
                window.localStorage.removeItem('demoSoundKey');
                window.localStorage.removeItem('demoSoundName');
                console.log('loading demo sound ' + model.key);
                app.sounds.add(model);
                app.utils.loadVybWithSound(model);
            },
            error: function(err){
                console.log('error loading vyb for demo sound');
                console.dir(err);
            }
        });
    },

    //initialize web socket
    initSocket: function(){
        var self = this;

        this.eventLog.add('initializing socket');

        this.socket = socket(this.config.webSocketUri,{
            reconnectionDelay: 120000,
            reconnectionDelayMax: 360000,
            transportOptions: {
                polling: {
                    extraHeaders: {
                        'Authorization': self.auth.jwtToken
                    }
                }
            }
        });

        var self = this;

        //update activity log
        this.socket.on('newlog', function(data){
            self.eventLog.add('incoming log!');
            self.eventLog.add('PING! ' + data.diagnostics.vybName +
                ': ' +
                '' + data.diagnostics.vybDescription);
            console.dir(data);
            if(self.logs!=undefined) self.logs.updateLog(data);
        });

        //trigger vybs
        this.socket.on('vybSignal', function(data){
            self.eventLog.add('incoming vyb!');
            self.eventLog.add('PING! ' + data.vybName +
                ': ' +
                '' + data.vybDescription);
            console.dir(data);
            if(self.logs!=undefined) self.logs.triggerVyb('socket',data);
        });

        this.socket.on('connect',function(data){
            self.eventLog.add('socket connected');
            if(typeof(this.sounds)=="undefined") self.loadData();
        });

        this.socket.on('disconnect',function(){
            self.eventLog.add('socket disconnected');
            self.socket.open();
        });
    },

    //models defined here
    loadModels: function(){
        this.Sound = require('./models/sound');
        this.Profile = require("./models/profile");
        this.Meter = require("./models/meter");
        this.Auth = require('./models/auth');
        this.VybFollowing = require('./models/vybfollowing');
        this.VybPeep = require('./models/vybpeep');
        this.Vyb = require('./models/vyb');
        this.Subscription = require('./models/subscription');
        this.Xhr = require('./models/xhr');
        this.Param = require('./models/param');
        this.SubscriptionPortal = require('./models/subscriptionportal');
    },

    //collections defined here
    loadCollections: function(){
        this.Sounds = require('./collections/sounds');
        this.SoundCandidates = require('./collections/soundcandidates');
        this.UsedSounds = require('./collections/usedsounds');
        this.Vybs = require('./collections/vybs');
        this.VybsFollowing = require('./collections/vybsfollowing');
        this.Logs = require('./collections/logs');
        this.VybPeeps = require('./collections/vybpeeps');
        this.Subscriptions = require('./collections/subscriptions');
    },

    //page views defined and some instantiated here
    loadPageViews: function(){
        this.LoginPageView = require('./views/pages/loginpage');

        this.LogPageView = require('./views/pages/logpage');
        this.logPageView = new this.LogPageView();

        this.VybListPageView = require('./views/pages/vyblistpage');
        this.vybListPageView = new this.VybListPageView();

        this.ProfilePageView = require('./views/pages/profilepage');
        this.profilePageView = new this.ProfilePageView();

        this.SoundsPageView = require("./views/pages/soundspage");
        this.soundsPageView = new this.SoundsPageView({mode:"sounds"});

        this.PeepsPageView = require("./views/pages/peepspage");

        this.VybPageView = require("./views/pages/vybpage");
        this.VybFollowingPageView = require("./views/pages/vybfollowingpage");
        this.SoundPageView = require("./views/pages/soundpage");
        this.PeepPageView = require("./views/pages/peeppage");
        this.SoundAcquisitionPageView = require("./views/pages/soundacquisitionpage");
        this.DocsPageView = require("./views/pages/docspage");
        this.WwwPageView = require("./views/pages/wwwpage");
        this.PwPageView = require("./views/pages/pwpage");
        this.EventLogPageView = require("./views/pages/eventlogpage");
        this.ServiceAuthPageView = require("./views/pages/serviceauthpage");
        this.DemoSoundsPageView = require("./views/pages/demosoundspage");
        this.SubscriptionPageView = require("./views/pages/subscriptionpage");
        this.SubscriptionCardPageView = require("./views/pages/subscriptioncardpage");
        this.PublicVybsPageView = require("./views/pages/publicvybspage");
    },

    renderPage: function(pageView=null){
        if(app.queryParams.p=="profile") pageView = "ProfilePage";
        var pageview = new MainView({
            el: document.querySelector('body'),
            pageView: pageView
        });

        domready(function() {
            pageview.render();
        });
    },

    loadLogs: function(renderPage=false){
        this.eventLog.add('loading logs');
        var self = this;
        this.logs.fetch({
            success: function(collection,response,options){
                console.log('logs loaded');
                self.loadVybsFollowing();
                self.loadMeter();
                if(renderPage) self.renderPage();
            },
            error: function(collection,response,options){
                self.eventLog.add('cannot retrieve logs','error');
            }
        });

    },

    loadVybsFollowing: function(){
        var self = this;
        app.vybsfollowing.fetch({
            success: function(collection,response,options){
                self.eventLog.add('loaded vybsfollowing');
                self.loadProfile();
            },
            error: function(collection,response,options){
                self.eventLog.add('cannot retrieve vybsfollowing','error')
            }
        });
    },

    loadSounds: function(params={}){

        var self = this;

        //handle incoming sound search
        if(app.queryParams.p!=undefined && app.queryParams.p=="sounds" && app.queryParams.squery!=undefined && app.queryParams.squery.length>0) params.squery = app.queryParams.squery;

        this.sounds = new this.Sounds();
        var data = {};
        if(params.squery!=undefined){
            data.query = decodeURIComponent(params.squery.replace(/\+/g, ' '));
            this.sounds.searchQuery = data.query;
        }

        this.soundCandidates = new this.SoundCandidates();

        this.sounds.fetch({
            data: data,
            success: function(collection,response,options){
                if(params.squery==undefined){
                    collection.add({key:'none', name:'none', type: 'mp3'},{at:0});
                }else{
                    if(collection.length>10 && self.auth.authenticated==false){
                        app.renderDemoSoundsPage();
                    }else{
                        app.loadSoundCandidates(params);
                    }
                }
            },
            error: function(collection,response,options){
                self.eventLog.add('cannot retrieve sounds','error');
            }
        });

    },

    loadSoundCandidates: function(){

        var self = this;

        this.soundCandidates.fetch({
            data: {
                query: app.sounds.searchQuery,
                filter: 'existing'
            },
            success: function(collection,response,options){
                console.log('retrieved candidate sounds for demo page');
                        if(self.auth.authenticated==false) app.renderDemoSoundsPage();
                        else app.renderPage("SoundsPage");
            },
            error: function(collection,response,options){
                console.log('cannot retrieve candidate sounds for demo page','error');
            }
        });
    },

    loadData: function(){

        this.eventLog.add("loading data");

        var self = this;

        this.vybsfollowing = new this.VybsFollowing();
        this.logs = new this.Logs();

        this.loadSounds();

        this.loadUsedSounds();

        this.loadSubscriptions();

        this.vybs = new this.Vybs();
        this.vybs.fetch({
            success: function(collection,response,options){
                self.loadLogs(true);
                console.log('loaded vybs');
            },
            error: function(collection,response,options){
                self.eventLog.add('cannot retrieve vybs','error')
            }
        });

        this.vybPeeps = new this.VybPeeps();

    },

    loadSubscriptions: function(){
        this.subscriptions = new this.Subscriptions();
        this.subscriptions.fetch({
            success: function(collection,response,options){
                console.log('loaded subscriptions');
            },
            error: function(collection,response,options){
                console.log('error loading subscriptions');
            }
        });
    },

    loadProfile: function(){
        var self = this;

        this.profile = new this.Profile();
        this.profile.fetch({
            success : function(model, response, options){
                app.profile = model;
                //initialize notifications
                if(self.platform=='app' && !app.serviceAuth){
                    self.loadPush();
                }else if(app.serviceAuth){
                    window.localStorage.setItem("key",model.key);
                }
                if(model.type=="user" && window.location.hostname!="app.vybit.net") window.console.log = function(){};
                console.log('loaded profile');
                if(app.meter){
                    app.meter.name = model.name;
                    app.meter.email = model.email;
                }
            },
            error: function(){
                self.eventLog.add('cannot retrieve profile','error');
            }
        });

    },

    loadMeter: function(){
        var self = this;

        this.meter = new this.Meter();
        this.meter.fetch({
            success : function(model, response, options){
                app.meter = model;
                console.log('loaded meter');
                if(app.currentPageName=="ProfilePage") app.renderPage("ProfilePage");
            },
            error: function(){
                self.eventLog.add('cannot retrieve meter','error');
            }
        });

    },

    loadUsedSounds: function(){
        this.usedSounds = new this.UsedSounds({
            key: 'none',
            name: 'none',
            type: 'mp3'
        });
        this.usedSounds.fetch({
            success: function(collection,response,options){
                collection.soundsSetUp();
            },
            error: function(collection,response,options){
                console.log('cannot retrieve used sounds','error');
            }
        });
    },

    loadTemplates: function(){
        this.templates = [];
        this.templates.TextTemplate = require('./templates/components/text.html');
        this.templates.TextAreaTemplate = require('./templates/components/textarea.html');
        this.templates.VybTypeTemplate = require('./templates/components/vybtype.html');
        this.templates.VybAccessTemplate = require('./templates/components/vybaccess.html');
        this.templates.TriggerTypeTemplate = require('./templates/components/triggertype.html');
        this.templates.VybFrequencyTemplate = require('./templates/components/vybfrequency.html');
        this.templates.VybTestTemplate = require('./templates/components/vybtest.html');
        this.templates.VybDeleteTemplate = require('./templates/components/vybdelete.html');
        this.templates.DisplayTemplate = require('./templates/components/display.html');
        this.templates.ScheduleEditTemplate = require('./templates/components/schedule_edit.html');
        this.templates.GeofenceEditTemplate = require('./templates/components/geofence_edit.html');
    },

    loadPush: function(){

        var self = this;

        cordova.plugins.firebase.messaging.requestPermission().then(function() {
            console.log("Push messaging is allowed");
        });

        this.fsutils.createDefaultPushChannel();
        this.fsutils.createSilentChannel();

        cordova.plugins.firebase.messaging.getToken().then(function(fcmToken) {

            self.eventLog.add('starting device push registration');
            console.log(fcmToken);
            self.auth.registerDevice({registrationId: fcmToken});

        }, function(error) {
            console.error('FCM getToken error:')
            console.error(error);
        });

        //handles notification taps and background notifications
        cordova.plugins.firebase.messaging.onBackgroundMessage(function(data){
            console.log('onbgm fired');
            console.dir(data);

            //needed to keep onMessage from firing
            app.onBackgroundMessageFired = true;

            if(data.runInBackground==undefined && app.currentPageName!="LogsPage") document.getElementById('logs').click();

            //link redirection for devices
            if(app.platform=="app" && data.runInBackground==undefined && data.linkUrl!=undefined && data.linkUrl.length>0){
                console.log('opening link ' + data.linkUrl);
                cordova.InAppBrowser.open(data.linkUrl,"_system");
            }

            setTimeout(function(){ app.onBackgroundMessageFired = false; },5000);

        });

        //handles notifications when app is in foreground when received
        cordova.plugins.firebase.messaging.onMessage(function(message) {

            if(self.onBackgroundMessageFired==true){
                console.log('obgm fired, bypassing onMessage');
                self.onBackgroundMessage = false;
                return;
            }

            self.eventLog.add('push notification received. data:');
            self.eventLog.add(message);

            if(!self.auth.authenticated){
                self.eventLog.add('not authenticated, terminating.');
                return;
            }

            //handle invitations
            if(typeof(message.fk)!=="undefined"){

                if(typeof message.diagnostics === 'string') message.diagnostics = JSON.parse(message.diagnostics);

                self.eventLog.add('incoming vyb follow push notification');
                if(self.logs!=undefined) self.logs.updateLog(message);
                return;


            } else if(typeof(message.vybKey)=="undefined"){
                self.eventLog.add("no vybKey, terminating push.");
                return;
            }

            //play sound in foreground
            if(typeof(message.soundKey)!="undefined"){
                if(typeof(app.usedSounds.get(message.soundKey,'key'))!="undefined"){
                    app.usedSounds.get(message.soundKey,'key').soundObjectLocal.play();

                }else if(typeof(app.usedSounds.get(message.soundKey,'key'))=="undefined"){
                    app.usedSounds.addSoundFromKey(message.soundKey,true);
                }
            }

            if(self.logs!=undefined){
                self.logs.triggerVyb('push',message);

            }else{
                self.eventLog.add('logs not available. retrieving unplayed vybs.');
                self.logs = new self.Logs();
                self.logs.getLogsWithUnplayedSounds();
            }
        }, function(error) {
            console.error(error);
        });
    }
});

app.initialize();

window.app = app;
