/**
 * @fileOverView
 * @description Docs View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SearchDemoTemplate = require('../../templates/components/demosearch.html');
var app = require('ampersand-app');

var SearchDemoView = AmpersandView.extend({

    template: SearchDemoTemplate,

    render: function(){
        var self = this;
        this.renderWithTemplate();
        if(this.query("input.vsdemo") && app.platform=="browser"){
            app.utils.runTicker(self);
            app.tickerInterval = setInterval(function(){
                app.utils.runTicker(self);
            }, 45000);
        }
    },

});

module.exports = SearchDemoView;
