/**
 * @fileOverView
 * @description Vyb Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var Logs = require('../collections/logs');
var Sound = require('./sound');
//var Log = require('./log');
var app = require("ampersand-app");
var YAML = require('yamljs');

var VybFollowing = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/vybfollow";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        vybName : 'string',
        description: 'string',
        ownerName: 'string',
        soundKey: 'string',
        soundType: 'string',
        status: 'string',
        accessStatus: 'string',
        subscriptionKey: 'string',
        vybKey: 'string',
        subscriptionKey: 'string',
        access: 'string',
        triggerType: 'string',
        geofence: 'object'
    },

    session: {
        updateSoundName: ['boolean', false, false],
        pinging: ['boolean',true,false]
    },

    derived: {

        name: {
            deps: ['vybName'],
            fn: function(){  return this.vybName; }
        },

        followKey: {
          deps: ['key'],
          fn: function(){ return this.key; }
        },

        onOff:{
            deps: ['status'],
            fn: function(){
                return this.status=='on' ? true: false;
            }
        },

        soundName: {
            deps: ['soundKey','updateSoundName'],
            fn: function(){
                if(app.usedSounds.get(this.soundKey)!=undefined){
                    var sound = app.usedSounds.get(this.soundKey);
                    var soundInfo = sound.name;
                    if(soundInfo!=null && sound.meta!=null && sound.meta.source!=null) soundInfo += ' &nbsp; <span class="vcredit">from <a class="vcreator" target="_new" href="'+sound.meta.source+'" onclick="event.stopImmediatePropagation()">freesound.org</a></span>';
                    return soundInfo;
                }else if(app.sounds.get(this.soundKey)!=undefined){
                    var sound = app.sounds.get(this.soundKey);
                    var soundInfo = sound.name;
                    if(soundInfo!=null && sound.meta!=null && sound.meta.source!=null) soundInfo += ' &nbsp; <span class="vcredit">from <a class="vcreator" target="_new" href="'+sound.meta.source+'" onclick="event.stopImmediatePropagation()">freesound.org</a></span>';
                    return soundInfo;
                }else{
                    var self = this;
                    var sound = new app.Sound({key:this.soundKey});
                    sound.fetch({
                        success: function(model){
                            app.sounds.add(model);
                            self.set('updateSoundName',true);
                        }
                    });
                }

                return null;
            }
        },

        soundFile: {
            deps: ['soundKey','soundType'],
            fn: function(){
                return this.soundKey + "." + this.soundType;
            }
        },

        partialDescription: {
            deps: ['description'],
            fn: function () {
                var desc = this.description.substr(0,128);
                if(this.description.length>128) desc += " . . . ";
                return desc;
            }
        },
        maintitle: {
            fn: function(){
                return 'Vyb Following'
            }
        },
        switchKeyInput:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },
        switchKeyLabel:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },

        buttonDisplay:{
            deps: ['accessStatus'],
            fn: function(){
                if(this.accessStatus!="invited"){
                    return '<button class="btn btn-danger btn-sm vparam vybdelete"> UNSUBSCRIBE </button>';
                }else{
                    return '<button class="btn btn-success btn-sm vparam vybaccept"> SUBSCRIBE </button>';
                }
            }
        },

        declineButtonDisplay:{
            deps: ['accessStatus'],
            fn: function(){
                if(this.accessStatus=="invited"){
                    return false
                }else{
                    return true;
                }
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        },

        hideSubscriptionLinks: {
          deps: ['access'],
          fn: function(){
              return this.access=='private' ? true : false;
          }
        },

        directLink: {
            deps: ['subscriptionKey','access'],
            fn: function(){
                return app.config.baseApiUrl + '/subscribe/' + this.subscriptionKey;
            }
        },

        cardLink: {
            deps: ['subscriptionKey','access'],
            fn: function(){
                return 'http://' + app.config.wwwHost + '/subscription/' + this.subscriptionKey;
            }
        },

        subscriptionLinks: {
            deps: ['subscriptionKey','access'],
            fn: function(){

                html = "";

                if(this.access!="private"){
                    var directLink =  app.config.baseApiUrl + '/subscribe/' + this.subscriptionKey;
                    var cardLink = app.config.wwwHost + '/subscription/' + this.subscriptionKey;

                    var html = '<div><span class="vslabel">DIRECT: </span><span id="directLink">'+directLink+'</span></div>';

                    html = html + '<div><span class="vslabel">CARD: &nbsp; </span><span id="cardLink"><a href="http://'+cardLink+'" target="_blank" onclick="app.utils.cancelBubble()">https://' + cardLink + '</a></span></div>';
                }

                return html;
            }
        },

        subscriptionCopyButtons: {
            deps : ['access'],
            fn: function(){
                var buttonDiv = '<button class="btn btn-sm btn-info vcopybutton" data-clipboard-text="'+ this.directLink +'">COPY</button>';
                buttonDiv += '<button class="btn btn-sm btn-info vcopybutton" data-clipboard-text="'+ this.cardLink +'">COPY</button>\n';

                return buttonDiv;
            }
        },

        ownerShip: {
            deps: ['ownerName'],
            fn: function(){
                return 'from ' + this.ownerName;
            }
        }

    },

    patch: function(data){

        var key = Object.keys(data)[0];
        var value = data[key];

        if(key=="soundKey" || this.soundKey.length>0){
            var soundKey = key=="soundKey" ? value : this.soundKey;
            var sound = app.sounds.get(soundKey,'key');
            app.fsutils.soundSetUp(sound);
            app.usedSounds.add(sound);
        }

        this.save(data,{
            patch: true,
            wait: true,
            success: function(model,response){
                app.header.set('editing',false);
            },
            error: function(model,response,options){
                console.log('error saving vybfollow');
            }
        })
    }

});

module.exports = VybFollowing;
