/**
 * @fileOverView
 * @description geo-location utilities. <br>
 * @copyright 2015-present Flatirontek LLC
 **/

'use strict';
var app = require('ampersand-app');

module.exports = {

    permissionRequested: false,
    permissionPermantlyDenied: false,
    permissionsGranted: [],

    manage: () => {
        if (typeof(VybitPlugin) == "undefined" || window.localStorage.getItem('managing_geofences')) return;

        console.log('existing geofences:');
        app.glutils.list("all");

        if(app.auth.deviceToken==null || app.vybs==undefined || app.vybsfollowing==undefined){
            console.warn("glutils.manage: missing deviceToken or vybs");
            return;
        }

        console.log("setting device token in manage");
        VybitPlugin.setDeviceToken(
            app.auth.deviceToken,
            app.config.restApiUrl,
            (result) => {
                console.log('successfully set device token in manage');
                console.dir(result);
                console.log("removing existing geofences in manage");
                VybitPlugin.removeGeoFence(
                    "all",
                    (result) => {
                        console.log('finished removing geofences in manage');
                        console.log(result);
                        console.log("retrieving existing geofences in manage");

                        let hasGeofences = false;
                        app.glutils.permissionsGranted = [];

                        //collect geofence vybs owned and followed
                        app.vybs.each((vyb)=>{
                            if(vyb.geofence!=null){
                                hasGeofences = true;
                                return false;
                            };
                        });
                        app.vybsfollowing.each((vyb)=>{
                            if(vyb.geofence!=null && vyb.geofence.subscribable!=undefined && vyb.geofence.subscribable=="yes"){
                                hasGeofences = true;
                                return false;
                            };
                        });

                        if(hasGeofences) app.glutils.setQualifiedGeofences('check_permissions');
                        else console.log('no geofences found');

                    },
                    (err) => console.error('error removing geofences in manage, ', err)
                );
            },
            (err) => {
                console.error('error setting deviceToken in manage');
                console.dir(err)
            }
        );
    },

    checkPermissions: (permission = null) => {

        if (typeof(VybitPlugin) == "undefined") return;

        const permissions = permission==null ? [] : [permission];

        let requiredPermissions = permission!=null ? [permission] : app.platformId=="ios" ? ['all'] : ['fine','background'];

        console.log('checking permissions, permissionsGranted: ' + app.glutils.permissionsGranted.length + ', requiredPermissions: ' + requiredPermissions.length);
        if(app.glutils.permissionsGranted.length == requiredPermissions.length) return;

        requiredPermissions.forEach(async (perm)=>{
            console.log('checking permission ' + perm);
            let perm_result = await VybitPlugin.checkPermissions(
                perm,
                (result) => {
                    console.log('successfully checked permission ' + perm);
                    console.dir(result);

                    if(result.match('granted')){
                        app.glutils.permissionsGranted.push(perm);
                    }else{
                        if(result.match('denied permanently') && app.profile?.permissions?.geofence == 1){
                            app.glutils.permissionPermantlyDenied = true;
                            app.glutils.disableGeofences();
                        }else{
                            app.glutils.askPermission(perm, 'checkPermissions');
                        }
                    }

                    if((app.platformId=='ios' && app.glutils.permissionsGranted.length==1) || app.glutils.permissionsGranted.length==2){
                        console.log('all required permissions granted, setting geofences');
                        app.glutils.setQualifiedGeofences('set_geofences');
                        app.glutils.notePermissionDisplay();
                    }
                },
                (err) => {
                    console.log("error checking permission " + perm);
                    console.error(err)
                }
            );
        });
    },

    checkPermission: (permission = null) => {
        if (typeof(VybitPlugin) == "undefined") return;

        console.log('checking permission ' + permission);
        VybitPlugin.checkPermissions(
            permission,
            (result) => {
                console.log('successfully checked permission ' + permission);
                console.dir(result);
            },
            (err) => {
                console.log("error checking permission " + permission);
                console.dir(err)
            }
        );
    },

    askPermission: async (permission="", mode = "") => {
        if (typeof(VybitPlugin) == "undefined" || app.glutils.permissionRequested || app.glutils.permissionPermantlyDenied) return;

        app.glutils.permissionRequested = true;

        console.log("asking for permission " + permission);
        app.glutils.permissionMessage(permission);

        VybitPlugin.askPermissions(
            permission,
            (result) => {
                console.log("successfully asked for permission " + permission);
                console.dir(result);
                if(result.match('denied')){
                    app.glutils.disableGeofences();
                    return;
                }
                app.glutils.permissionRequested = false;
                if(mode=="checkPermissions") app.glutils.checkPermissions();
            },
            (error) => {
                console.log("error asking for permission " + permission);
                console.dir(error);
                app.glutils.permissionRequested = false;
            }
        );
    },

    set: (params) => {
        if (typeof(VybitPlugin) == "undefined" || app.auth.deviceToken==null) return;
        console.log("geofence set params are ", params);

        VybitPlugin.setGeoFence(
            params,
            (result) => app.eventLog.add(result),
            (err) =>{
                console.error(err);
                console.error(params);
            }
        );
    },

    list: (geoFenceId = "all") => {
        if (typeof(VybitPlugin) == "undefined") return;
        VybitPlugin.getGeoFence(
            geoFenceId,
            (result) => console.dir(result),
            (err) => console.error(err)
        );
    },

    remove: (geoFenceId) => {
        if (typeof(VybitPlugin) == "undefined") return;
        VybitPlugin.removeGeoFence(
            geoFenceId,
            (result) => console.dir(result),
            (err) => console.error(err)
        );
    },

    setDeviceToken: () => {
        if (typeof(VybitPlugin) == "undefined" || app.auth.deviceToken==null) return;
        VybitPlugin.setDeviceToken(
            app.auth.deviceToken,
            app.config.restApiUrl,
            (result) => console.log(result),
            (err) => console.error(err)
        );
    },

    permissionMessage: (permission='all') => {

        if(permission=='all'){
            alert(
                'You have one or more geofence triggered vybits. ' +
                'To enable this feature set the device location service permissions for Vybit to "Always" and enable "Precise" accuracy. ' +
                'Vybit needs this permission to deliver geofence triggered notifications even when the app is closed. ' +
                'Other than the notifications, Vybit does not track or record your location data.'
            );
        }else if(permission == 'fine'){

            alert('Vybit collects location data to enable geofence triggered notifications even when the app is closed or not in use. ' +
                'Select "Precise" and "While using the app" on the next prompt. ' +
                'Other than the notifications, Vybit does not retain or share your location data.');

        }else if(permission == 'background'){

            alert('Vybit collects location data to enable geofence triggered notifications even when the app is closed or not in use. ' +
                'Select "Allow all the time" on the next prompt. ' +
                'Other than the notifications, Vybit does not retain or share your location data.');
        }

    },

    setQualifiedGeofences: (mode = 'check_permissions')=>{

        if(mode=='check_permissions'){
            app.glutils.checkPermissions();
            return;
        }

        const xhr = new XMLHttpRequest();
        console.log('retrieving qualified geofences');
        xhr.onload = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {

                    let geofences = JSON.parse(xhr.response);
                    geofences.forEach((fence)=>{
                        var vyb = app.vybs.get(fence.key) || app.vybsfollowing.get(fence.key,"vybKey");
                        app.glutils.set(vyb.geofence);
                     });

                }else{
                    console.log('error retrieving qualified geofences');
                    console.log('StatusCode: ' + xhr.status);
                    console.log('ErrorMessage: ' + xhr.statusText);
                }
            }
        };

        // create and send the reqeust
        xhr.open('GET', app.config.restApiUrl + "/geofence/queue");
        xhr.setRequestHeader('Authorization', app.auth.deviceToken);
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.send();
    },

    disableGeofences: () => {
        console.log('geofences are disabled');
        app.glutils.notePermissionDisplay();
        alert('Geofence triggered vybits are disabled. ' +
            'To enable go to device settings and set location permissions for Vybit to "Always" ' +
            'or remove the geofence vybits to avoid this message.');
    },

    //implies this message was shown, not necessarily permission granted
    notePermissionDisplay: () => {
        let permissions = app.profile?.permissions || {};
        permissions.geofence = 1;
        app.profile.set("permissions",permissions);
        app.profile.patch({permissions: permissions});
    },

    test: (message) => {
        if (typeof(VybitPlugin) == "undefined") return;
        VybitPlugin.test(
            message,
            (result) => console.dir(result),
            (err) => console.error(err)
        );
    },

    testGeofence: (key) => {
        var request = new app.Xhr();
        request.set('type','geofence');
        request.set('key',key);
        request.fetch({
            success: (model,response)=>{ console.log(response.body); },
            error: (err,response)=>{ console.error(response.body); }
        });
    }
}

