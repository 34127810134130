/**
 * @fileOverView
 * @description Peeps View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var PeepsTemplate = require('../../templates/widgets/peepstemplate.html');
var PeepView = require('./peepview');
var app = require('ampersand-app');

var VybPeepsView = AmpersandView.extend({

    template: PeepsTemplate,

    initialize: function (options) {
        this.collection = app.vybPeeps;
        this.collection.fetch();
        this.entity = "vybPeeps";
        this.lastLoad = Date.now();
    },

    render: function(){

        this.renderWithTemplate(this);
        this.renderCollection(this.collection,PeepView,'#peepstable');

    },

    events: {
        "scroll" : "infiniteScroll"
    },

    infiniteScroll: function(){ app.utils.infiniteScroll(this) }

});

module.exports = VybPeepsView;
