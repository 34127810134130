/**
 * @fileOverView
 * @description Vyb Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var Vyb = require('../models/vyb');
var config = require("../etc/config");
var app = require('ampersand-app');

var Vybs = RestCollection.extend({

    initialize : function(){
        this.personKey = config.key;
    },

    model:Vyb,

    indexes: ['key','soundKey','subscriptionKey'],

    url: function(){ return config.restApiUrl + "/vybs"; },

    ajaxConfig: app.utils.setAuthTokenHeader,

    triggerAlert: function(vk){
        if(this.get(vk,'key')!=undefined) this.get(vk,'key').triggerAlert();
        else{
            var vybs = this;
            var vyb = new Vyb();
            vyb.key = vk;
            vyb.fetch({
                success: function(model,response){
                    vybs.add(model);
                    model.triggerAlert();
                },
                error: function(model,response){
                    console.log('could not retrieve vyb ' + vk);
                }
            });
        }
    }
});

module.exports = Vybs;
