/**
 * @fileOverView
 * @description Sound Full View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SoundTemplate = require('../../templates/widgets/soundfulltemplate.html');
var config = require("../../etc/config");
var Params = require("../../collections/params");
var ParamView = require("./param");
var app = require("ampersand-app");

var SoundFullView = AmpersandView.extend({

    template: SoundTemplate,

    render: function(){
        this.renderWithTemplate();
    },

    events:{
        "scroll" : "infiniteScroll"
    },

    infiniteScroll: function(){
        if (this.el.scrollHeight - this.el.scrollTop - 10 <= this.el.clientHeight && app.sound.key!=null) {
            console.log('get more somethings');
        }
    }
});

module.exports = SoundFullView;
