/**
 * @fileOverView
 * @description On Off Switch View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var OnOffTemplate = require('../../templates/components/onoff.html');
var AmpersandView = require('ampersand-view');

var OnOffView = AmpersandView.extend({

    template : OnOffTemplate,

    bindings: {
        "model.onOff": {
            type: 'booleanAttribute',
            hook: 'onOff',
            name: 'checked'
        },
        "model.switchKeyInput": {
            type: "attribute",
            selector: "input[data-hook='onOff']",
            name: "id"
        },
        "model.switchKeyLabel": {
            type: "attribute",
            selector: "label.vswitchlabel",
            name: "for"
        }
    },

    events: {
          "click label.vswitchlabel" : "setStatus"
    },

    setStatus: function(){
        var status = this.model.status=="on" ? "off" : "on";
        this.model.save({status:status},
            {
                patch:true,

                success: function(model,response){
                    var cb = document.getElementById('switch-'+model.key);
                    var status = model.status;
                    if(status=='on' && cb.checked==false) cb.checked = true;
                    else if(status=="off" && cb.checked==true) cb.checked = false;
                }
            });

    }

});

module.exports = OnOffView;
