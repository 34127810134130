/**
 * @fileOverView
 * @description Form Param View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var ParamTemplate = require('../../templates/widgets/param.html');
var OnOffSwitch = require("../components/onoff");
var app = require("ampersand-app");
var VparamView = require('../components/vparam');
var YAML = require('yamljs');

var ParamView = AmpersandView.extend({

    extraProperties: "allow",

    template: ParamTemplate,

    initialize: function(){
        if(this.model.name=="diagnostics") this.listenTo(app.vyb,'change:diagnostics',this.render);
        if(this.model.name=="pollUri" || this.model.name=="frequency" || this.model.name=="eventUri") this.listenTo(app.vyb,'change:type',this.render);
        if(app.vyb!=undefined && app.vyb.key==undefined) this.listenTo(app.vyb,'change:key',this.render);
        if(this.model.name=="moderationDisplay") this.listenTo(app.vyb,'change:moderationShow',this.render);
        if(this.model.name=="moderationDisplay") this.listenTo(app.vyb,'change:moderationDisplay',this.render);
    },

    render: function(){
        this.model.set('hidden',false);

        if(app.currentPageName=="VybPage" && this.model.name!="name" && app.vyb!=undefined && app.vyb.key==undefined){
            if(this.model.doNotHide) this.model.set('hidden',false);
            else this.model.set('hidden',true);
        }else{
            if(this.model.name=="pollUri" || this.model.name=="frequency") this.model.set("hidden",(app.vyb.type=="event"));
            if(this.model.name=="eventUri") this.model.set("hidden",(app.vyb.type=="poll"));
            if(this.model.name=="diagnostics") this.model.set("hidden",app.vyb.testing==false);
        }

        this._initializeSubviews();
        this.renderWithTemplate();

        if(this.model.name=="eventUri"){
            this.query("button.vcopybutton").setAttribute("data-clipboard-text", app.vyb.eventUri);
            app.utils.clipboard();
        }


        if(this.model.name=="soundName"){
            this.el.querySelector("div.vright").classList.add('vdeltaclick');
            this.el.querySelector("div.vright").innerHTML = "<img class='vdeltaimg' src='images/bell1.png'/>";
        }

        //subscription copy buttons adjustment on access change
        if(this.model.name=="subscriptionLinks") this.model.set('rightColContent', app.vyb.subscriptionCopyButtons);

        //moderation field
        if(this.model.name=="moderationDisplay"){
            if(app.vyb.get('moderationShow')) this.model.set('hidden',false);
            else this.model.set('hidden',true);
        }
    },

    bindings: {
        "model.label" : {
            type: "text",
            selector: "label"
        },
        "model.hidden" : {
            type: "booleanClass",
            name: "vhide"
        },
        "model.rightColContent" : {
            type: function(el,value){
                el.innerHTML = value==undefined ? '' : value;
            },
            selector: "div.vright"
        },
        "model.name" : {
            type: "attribute",
            name: "id"
        },
        "model.labelNoWrap" :{
            type: "booleanClass",
            name: "vlabel-no-wrap",
            selector: "label"
        },

        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }
    },

    subviews: {
        vparam: {
            selector : 'div.vmid',
            prepareView: function(el) {
                if (this.model.inputType == "checkbox"){

                    return new OnOffSwitch({
                        el: el,
                        parent: this,
                        model: app.vyb
                    });

                }else return new VparamView({
                    el: el,
                    parent: this,
                    model: this.model
                });
            }
        }
    },

    events: {
        "click .vdeltaimg, .vdeltaclick" : "playSound",
        "click label.vlabel" : "editParam"
    },

    editParam: function(){
        if(this.model.type=="none" || this.model.type=="button"
            ||(app.currentPageName=="VybPage" && app.vyb.name==undefined && this.model.name!="name")
        ) return;

        if(this.model.inputType=="page"){
            if(this.model.name=='soundName'){
                var view =   new app.SoundsPageView({mode:"vyb", model: app.vyb });
            }else if(this.model.name=='displayFollowers'){
                var view =   new app.PeepsPageView({mode:"vyb", model: app.vyb });
            }
            app.pageSwitcher.set(view);
            return;
        }

        app.header.set({editing:true});
        this.model.set({editing:true});
    },

    playSound: function(){
        this.model.set('pinging',true);
        app.soundViews[app.vyb.soundKey] = this.model;
        app.utils.playSound(app.vyb.soundKey);
    }

});

module.exports = ParamView;
