/**
 * @fileOverView
 * @description Subscription Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var Subscription = require('../models/subscription');
var config = require("../etc/config");
var app = require('ampersand-app');

var Subscriptions = RestCollection.extend({

    model:Subscription,

    indexes: ['key'],

    url: function(){ return config.restApiUrl + "/subscriptions"; },

    ajaxConfig: app.utils.setAuthTokenHeader

});

module.exports = Subscriptions;
