/**
 * @fileOverView
 * @description Doc Model, pulls current documentation<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");
var demoSearchTemplate = require('../templates/components/demosearch.html');

var Doc = AmpersandModel.extend({

    urlRoot: function(){
        return app.config.restApiUrl + "/doc";
    },

    props: {
        content : 'string'
    },

    derived: {
        demoSearch: {
            deps: ['content'],
            fn: function(){
                return demoSearchTemplate();
            }
        }
    }

});

module.exports = Doc;
