/**
 * @fileOverView
 * @description Log Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var sortMixin = require('ampersand-collection-sort-mixin');
var Log = require('../models/log');
var Sound = require('../models/sound');
var config = require("../etc/config");
var app = require('ampersand-app');

var Logs = RestCollection.extend(sortMixin,{

    indexes: ["key","vybKey","dtStart"],

    props: {
        searchQuery : 'string'
    },

    initialize : function(){
        this.personKey = config.key;
    },

    model:Log,

    url: function(){
        var suffix = this.parent!=undefined && this.parent.key!=undefined ? '/vk/' + this.parent.key : '';
        return config.restApiUrl + "/log" + suffix;
    },

    session: {
        sortProps: "dtStart",
        sortDescending: true
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    updateLog: function(data){

        if(typeof data.diagnostics == 'string') data.diagnostics = JSON.parse(data.diagnostics);

        if(data?.diagnostics?.action=="reload_meter") app.loadMeter();

        if(data.fk!=undefined && data.diagnostics.fk==undefined) data.diagnostics.fk = data.fk;

        var log = new Log({
            key: data.lk,
            vybKey: data.vybKey || data.diagnostics.vybKey || data.vk,
            dtStart: data.diagnostics.dtStart,
            diagnostics: data.diagnostics
        });
        if(data.vk!=undefined) log.vybKey = data.vk;
        this.add(log,{at: 0});
        if(app.logs.models.length==1 && app.currentPageName=="LogsPage") app.renderPage();

        if(data.diagnostics.type=="subscribed" || data.diagnostics.mode=="revoke") app.loadVybsFollowing();

        //handle follow invites
        if(log.followKey!=null){
            if(app.vybsfollowing.get(data.fk,'key')==undefined && data.diagnostics.mode!="revoke") {
                var vybFollowing = new app.VybFollowing({key:log.followKey});
                app.eventLog.add('fetching ' + log.followKey);
                vybFollowing.fetch({
                    success: function(model,response){
                        app.vybsfollowing.add(model,{at:0});
                        if(model.soundKey!=undefined){
                            app.fsutils.loadSoundFromFile(model.soundKey, model.soundFile);
                            if(app.usedSounds.get(model.soundKey,'key')==undefined){
                                var usedSound = new app.Sound({key: model.soundKey});
                                usedSound.fetch({
                                    success: function(usound,response){
                                        app.usedSounds.add(usound);
                                    },
                                    error: function(){
                                        console.log('error fetching sound ' + usedSound.key + ' in logs.updateLog');
                                    }
                                });
                            }
                        }
                    },
                    error: function(model,response){
                        app.eventLog.add('could not fetch vybfollowing ' + data.fk);
                    }
                });
            }
        }

        //play sound
        if(data.diagnostics.soundKey!=undefined) app.utils.playSound(data.diagnostics.soundKey);

        //revoked invitations
        if(data.fk!=undefined && data.diagnostics.message!=undefined && data.diagnostics.message=='invitation revoked'){
            app.eventLog.add('removing invitation');
            app.vybsfollowing.remove(data.fk,'key',{silent:true});
        }

        //sync vyb
        if(data.diagnostics.syncVyb!=undefined && app.vybs.get(log.vybKey)) app.vybs.get(log.vybKey).fetch();

    },

    triggerVyb: function(source,data) {

        app.eventLog.add('vyb triggered from ' + source);

        var soundKey = data.soundKey!=undefined ? data.soundKey : 'none';
        var owns = "neutral";
        var vyb;

        let diagnostics = null
        if(typeof data?.diagnostics === 'string') diagnostics = JSON.parse(data.diagnostics);
        if(diagnostics?.action=="reload_meter") app.loadMeter();

        //own
        if (app.vybs.get(data.vybKey, 'key') != undefined) {
            app.eventLog.add('vyb ' + data.vybKey + ' is in app.vybs');
            vyb = app.vybs.get(data.vybKey, 'key');
            soundKey = vyb.soundKey;
            owns = true;

        //following
        } else if (app.vybsfollowing.get(data.vybKey, 'vybKey') != undefined) {
            app.eventLog.add('vyb ' + data.vybKey + ' is in app.vybsfollowing');

            vyb = app.vybsfollowing.get(data.vybKey, 'vybKey');
            soundKey = vyb.soundKey;
            owns = false;

            if(vyb.accessStatus=="invited" || vyb.accessStatus=="denied"){
                app.eventLog.add("vybfollow not granted access, aborting vyb.");
                return;
            }
        }

        //verify legit vyb
        if(owns=="neutral"){
            app.eventLog.add("vyb not found, aborting");
            app.loadLogs(true);
            return;
        }

        var params = owns ? {key: data.parentLogKey, owns: owns} : {key: data.parentLogKey, parentLogKey: data.parentLogKey, hasParentLog: true, owns: owns};

        //play sound
        if(data.trigger==1 && app.platform=="browser") app.utils.playSound(soundKey,true,data.parentLogKey);
        //TODO, will need log update for polling vyb returning false here

        //reload profile if instructed

        //reset geofences
        if(data.triggerType == "geofence"){
            console.log("geofence trigger, reloading geofences");
            app.glutils.manage()
        }

        //fetch log, add to collection
        var log = new Log(params);
        log.set('hasParentLog',params.owns==false);
        log.set('usp',true);
        log.fetch({
            success: function(response,model){
                app.logs.add(model,{at: 0});

                var turnedOffParentLog = false;
                if(log.hasParentLog){
                    log.hasParentLog = false;
                    turnedOffParentLog = true;
                } //needed for update to work

                log.set('hasParentLog',params.owns==false);

                app.eventLog.add('fetched log ' + params.key + ', owns ' + params.owns);

                //update meter
                app.loadMeter();

            },
            error: function(model,response) {
                app.eventLog.add('error fetching log ' + params.key);
            }
        });
    },

    getLogsWithUnplayedSounds: function(taskId=null){

        app.eventLog.add('retrieving unplayed logs');

        var self = this;
        var unheardLogs = new app.Logs();
        unheardLogs.fetch({
            data:{getunplayed: 1},
            success: function(collection,response,options){
                app.eventLog.add('number of unplayed vybs found is ' + collection.models.length);
                collection.each(function(log){
                        app.eventLog.add('found unplayed log ' + log.key);
                        if(app.logs.get(log.key,'key')==undefined){
                            app.logs.add(log,{at:0});
                        }
                        self.queueTriggerVyb(log);
                });
                //update meter
                app.loadMeter();
            },
            error: function(){
                app.eventLog.add('error retrieving logs with unplayed sounds');
            }
        });
        app.push.finish();
    },

    queueTriggerVyb: function(log){
        app.eventLog.add('playing unheard vyb log ' + log.key);
        log.diagnostics.parentLogKey = log.parentLogKey==null ? log.key : log.parentLogKey;
        if(log.diagnostics.vybKey==undefined) log.diagnostics.vybKey = log.vybKey;
        app.eventLog.add(log.diagnostics);
        app.logs.triggerVyb('background',log.diagnostics);
    }

});

module.exports = Logs;
