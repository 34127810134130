/**
 * @fileOverView
 * @description Configuration File<br>
 * @copyright 2015-present Flatirontek LLC
 **/
var hostname = window.location.host;
var env = "production"

var envConfig = {
    "development" : {
        "hostname" : hostname.match("www") ? "dev.vybit.net" : hostname,
        "soundLibHost" : "vybit.net",
        "wwwHost" : "dev.www.vybit.net"
    },

    "staging" : {
        "hostname" : hostname.match("www") ? "staging.vybit.net:8420" : hostname,
        "wwwHost" : "staging.www.vybit.net:8420"
    },

    "production" : {
        "hostname" : hostname.match("www") ? "vybit.net" : hostname,
        "wwwHost" : "www.vybit.net",
        "mobileHost" : "vybit.net",
        "pwHost" : "my.vybit.net",
        "pwApiKey" : "pk_live_51LKAizDGHpaPCWmyYRwmkbnJtYXMVF2IY0KJ8Yf1n6GURdVNQDul3heSgN3rQzBr8xj8yM124E8LIOgmvHtToI6I00ybSbFRBV",
        "pwPricingTableId" : "prctbl_1O8p65DGHpaPCWmyhcvFDZgv"
    },
    "common" : {
        androidSoundLib: "file:///data/user/0/com.vybit/files/vybitsounds/",
        iOsSoundLib: "cdvfile://localhost/library/Sounds/"
    }
}

if(typeof(cordova)=="object"){
    env = "production";
    envConfig[env].hostname = envConfig[env].mobileHost;
}
serverDomain = envConfig[env].hostname;

module.exports = {

    env: (function(){ return env; })(),

    hostName: (function(){
        return serverDomain;
    })(),

    baseApiUrl : (function(){
        return "https://" + serverDomain
    })(),

    restApiUrl : (function(){
        return "https://" + serverDomain + "/rest";
    })(),

    debug: (function(){
        return env=="development" || env=="staging" ? true : false;
    })(),

    webSocketUri : (function(){
        return "https://" + typeof("cordova")=="undefined" ? window.location.host : serverDomain;
    })(),

    subscriptionUri : (function(){
        return "https://" + serverDomain + "/stripe/v1";
    })(),

    /* key : (function(){
        return key;
    })(),  */

    soundLib : (function(){
        return 'https://vybit.net/sounds';
    })(),

    qrcodeLib: (function(){
        return 'https://vybit.net/qrcodes';
    })(),

    inactiveThreshold: (function(){
        return 70; //seconds
    })(),

    checkInterval: (function(){
        return 60; //minutes
    })(),

    defaultSoundKey: '4brgzvngimartu1d',

    firebase: (function(){

        return {
            apiKey: "AIzaSyCShECylQBTYoy7pMH_aa87ESfyjAg908c",
            authDomain: "signin.vybit.net",
            iOsAuthDomain: "auth1.vybit.net",
            serviceAuthDomain: "serviceauth.vybit.net",
            databaseURL: "https://vybr-139921.firebaseio.com",
            projectId: "vybr-139921",
            storageBucket: "vybr-139921.appspot.com",
            messagingSenderId: "951851584565",
            appId: "1:951851584565:web:26f5764095598d9196b57c"
        };

    })(),

    packageName: "com.vybit",

    dynamicLinkDomain: "vybit.page.link",

    androidSoundLib: envConfig.common.androidSoundLib,

    iOsSoundLib: envConfig.common.iOsSoundLib,

    localSoundDir: (function(){
        var dir = "";
        if(typeof(cordova)=="object"){
            dir = cordova.platformId=="ios" ? envConfig.common.iOsSoundLib : envConfig.common.androidSoundLib;
        }
        return dir;
    })(),

    wwwHost: (function(){
        var wwwHost = envConfig[env].wwwHost;
        return wwwHost;
    })(),

    pwHost: (function(){
        var pwHost = envConfig[env].pwHost;
        return pwHost;
    })(),

    pwApiKey: (function(){
        var pwApiKey = envConfig[env].pwApiKey;
        return pwApiKey;
    })(),

    pwPricingTableId: (function(){
        var pwPricingTableId = envConfig[env].pwPricingTableId;
        return pwPricingTableId;
    })(),

    serviceAuth: {
        ifttt: {
            displayName: 'IFTTT',
            redirect_uri: "https://ifttt.com/channels/vybit/authorize"
        },
        zapier: {
            displayName: 'Zapier',
            redirect_uri: "https://zapier.com/dashboard/auth/oauth/return/App118849CLIAPI/"
        },
        integromat: {
            displayName: 'Make',
            redirect_uri: "https://www.make.com/oauth/cb/app"
        },
        make: {
            displayName: 'Make',
            redirect_uri: "https://www.make.com/oauth/cb/app"
        },
        //alertdesk
        alertdesk: {
            clientId: "3ssn6j7m42835bckd4c2utavha",
            displayName: 'Alertdesk',
            redirect_uri: "https://api.alertdesk.com/oauth2/authorize"
        }
    },

    stripe: (()=>{
        return "https://billing.stripe.com/p/login/aEU7tkeB13Hv8AE9AA";
    })()

}

