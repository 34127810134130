/**
 * @fileOverView
 * @description Vyb Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var VybTest = AmpersandModel.extend({

    idAttribute: 'triggerKey',

    urlRoot: function(){
        var suffix = this.type=='event' ? 'trigger' : 'poll';
        return app.config.baseApiUrl + "/" + suffix;
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        triggerKey : 'string',
        diagnostics: 'string'
    },

    session: {
        type: ['string',true],
    }
});

module.exports = VybTest;
