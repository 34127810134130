/**
 * @fileOverView
 * @description Log View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybFollowing = require('../../models/vybfollowing');
var LogTemplate = require('../../templates/widgets/logtemplate.html');
var config = require("../../etc/config");
var app = require('ampersand-app');

var LogView = AmpersandView.extend({
    template: LogTemplate,

    bindings: {
        "model.logDate": {
            type: 'innerHTML',
            hook: 'logDate'
        },
        "model.vybName": {
            type: 'text',
            hook: 'vybName'
        },
        "model.message": {
            type: 'innerHTML',
            hook: 'message'
        },
        "model.connectedStatus": {
            type: 'innerHTML',
            hook: 'connectedStatus'
        },

        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }
    },

    events: {
        "click a[data-reviewkey]" : "switchToVybReview",
        "click .vdeltaimg, .vdeltaclick" : "playSound",
        "click .vybclick, .vlogvybname" : "switchToVybPage",
    },

    playSound: function(){
        app.utils.stopShakes();
        this.model.set('pinging',true);
        app.soundViews[this.model.soundKey] = this.model;
        app.utils.playSound(this.model.soundKey);
    },

    switchToVybPage: function(){
        if(window.getSelection().toString().length>0) return;
        app.utils.switchToVybPage(this.model);
    },

    switchToVybReview: function(event){
        app.utils.cancelBubble(event);
        console.log('reviewing');
    }

});

module.exports = LogView;
