/**
 * @fileOverView
 * @description Vyb Full View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybTemplate = require('../../templates/widgets/vybfulltemplate.html');
var config = require("../../etc/config");
var Params = require("../../collections/params");
var ParamView = require("./param");
var VybLogsView = require('../widgets/vyblogsview');
var eventUriCopyButton = require('../../templates/components/eventUriCopyButton.html');
var app = require("ampersand-app");
var moment = require('moment-timezone');

var VybFullView = AmpersandView.extend({

    template: VybTemplate,

    initialize: function(options){

        var entity = 'vyb';

        var soundParam = {
                "entity" : entity,
                "name" : "soundName",
                "label" : "SOUND",
                "inputType" : "page",
                "placeholder" : "none - find the perfect sound, choose wisely"
            };
        if(options.action=="showSound") soundParam.doNotHide = true;

        this.generalSettings = new Params([
            {
               "entity" : entity,
               "name" : "name",
               "label" : "NAME",
               "inputType" : "text",
               "template" : "TextTemplate",
               "placeholder" : "start with a fantastic name"
            },
            {
                "entity" : entity,
                "name" : "status",
                "label" : "OFF/ON",
                "inputType" : "checkbox"
            },

            soundParam,

            {
                "entity" : entity,
                "name" : "description",
                "label" : "DESCRIPTION",
                "inputType" : "text",
                "template" : "TextTemplate",
                "placeholder" : "an optional description for you and potential subscribers"
            },
            {
                "entity" : entity,
                "name" : "delete",
                "label" : "",
                "inputType" : "button",
                "template" : "VybDeleteTemplate",
                "cursor" : true
            },
            {
                "entity" : entity,
                "name" : "docCreateLink",
                "label" : "HELP",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : false,
                "doNotHide" : true
            },

            //polling parameters
            /*   {
        "entity" : entity,
        "name" : "type",
        "label" : "TYPE",
        "inputType" : "select",
        "template" : "VybTypeTemplate"
    },
    {
        "entity" : entity,
        "name" : "pollUri",
        "label" : "POLL URI",
        "inputType" : "text",
        "template" : "TextTemplate"
    },
    {
        "entity" : entity,
        "name" : "frequency",
        "label" : "POLL FREQUENCY",
        "inputType" : "select",
        "template" : "VybFrequencyTemplate"
    },

   {
         "entity" : entity,
        "name" : "diagnostics",
        "label" : "TEST DIAGNOSTICS",
        "inputType" : "none",
        "template" : "DisplayTemplate",
        "cursor" : true,
        "hidden" : true
    },
    */

        ]);

        this.triggerSettingsInit = [
            {
                "entity" : entity,
                "name" : "eventUri",
                "label" : "TRIGGER URI",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : true,
                "rightColContent" : eventUriCopyButton(),
                "labelNoWrap": true
            },
            {
                "entity" : entity,
                "name" : "triggerType",
                "label" : "TRIGGER TYPE",
                "inputType" : "select",
                "template" : "TriggerTypeTemplate",
                "labelNoWrap": true
            },
        ];

        this.triggerSettingComponents = {
            integrationLinks: {
                "entity" : entity,
                "name" : "integrationLinks",
                "label" : "INTEGRATIONS",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : true
            },
            schedule: {
                "entity" : entity,
                "name" : "schedule",
                "label" : "SCHEDULE",
                "inputType" : "none",
                "template" : "ScheduleEditTemplate",
                "cursor" : true,
                "bypassSave" : true
            },
            geofence: {
                "entity" : entity,
                "name" : "geofenceDisplay",
                "label" : "GEOFENCE",
                "inputType" : "none",
                "template" : "GeofenceEditTemplate",
                "cursor" : true,
                "bypassSave" : true
            },
            help: {
                "entity" : entity,
                "name" : "docTriggerLink",
                "label" : "HELP",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : false,
                "doNotHide" : true
            },
        },

        this.notificationSettings = new Params([
            {
                "entity" : entity,
                "name" : "message",
                "label" : "MESSAGE",
                "inputType" : "text",
                "template" : "TextTemplate",
                "placeholder" : "an optional default message to send with the notification"
            },
            {
                "entity" : entity,
                "name" : "imageUrl",
                "label" : "IMAGE URL",
                "inputType" : "text",
                "template" : "TextTemplate",
                "placeholder" : "an optional default image to attach to the vybit notification"
            },
            {
                "entity" : entity,
                "name" : "linkUrl",
                "label" : "LINK URL",
                "inputType" : "text",
                "template" : "TextTemplate",
                "placeholder" : "an optional redirect url when the vybit notification is tapped"
            },
            {
                "entity" : entity,
                "name" : "test",
                "label" : "",
                "inputType" : "button",
                "template" : "VybTestTemplate",
                "cursor" : true
            },
        ]);

        this.subscriptionSettings = new Params([
            {
                "entity" : entity,
                "name" : "subscriptionLinks",
                "label" : "SUBSCRIPTION LINKS",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : true,
                "rightColContent" : app.vyb.subscriptionCopyButtons
            },
            {
                "entity" : entity,
                "name" : "access",
                "label" : "SUBSCRIPTION ACCESS",
                "inputType" : "select",
                "template" : "VybAccessTemplate"
            },
            {
                "entity" : entity,
                "name" : "moderationDisplay",
                "label" : "MODERATION",
                "inputType" : "none",
                "cursor" : true,
                "template" : "DisplayTemplate",
                "bypassSave" : true
            },
            {
                "entity" : entity,
                "name" : "displayFollowers",
                "label" : "PEEPS",
                "inputType" : "page",
                "placeholder" : "invite people to subscribe to this vybit"
            },
            {
                "entity" : entity,
                "name" : "docSubscriptionLink",
                "label" : "HELP",
                "inputType" : "none",
                "template" : "DisplayTemplate",
                "cursor" : false,
                "doNotHide" : true
            },
        ]);

        this.listenToAndRun(this.model,'change:triggerType',function(){
            this.renderTriggerSettings();
        });
    },

    render: function(){
        this.renderWithTemplate();
        this.renderCollection(this.generalSettings,ParamView,'div.vset-general');
        this.renderTriggerSettings();
        this.renderCollection(this.notificationSettings,ParamView,'div.vset-notification');
        this.renderCollection(this.subscriptionSettings,ParamView,'div.vset-subscription');
    },

    renderTriggerSettings: function(){
        let triggerSettingsList = [...this.triggerSettingsInit];
        if(app.vyb.triggerType=="schedule") triggerSettingsList.push(this.triggerSettingComponents.schedule);
        else if(app.vyb.triggerType=="integration") triggerSettingsList.push(this.triggerSettingComponents.integrationLinks);
        else if(app.vyb.triggerType=="geofence") triggerSettingsList.push(this.triggerSettingComponents.geofence);
        triggerSettingsList.push(this.triggerSettingComponents.help);

        this.triggerSettings = new Params(triggerSettingsList);
        if(this.query('div.vset-trigger')) this.query('div.vset-trigger').innerHTML = '';

        this.renderCollection(this.triggerSettings,ParamView,'div.vset-trigger');
    },

    bindings: {
       "model.hideSettings" : {
           type: "booleanClass",
           selector: "div.vsettings-accordian",
           name: "vhide"
       }
    },

    events: {
        "click .vschedule-clear" : "clearSchedule",
        "click .vschedule-save" : "saveSchedule",
        "click .vgeofence-save" : "saveGeofence",
    },

    clearSchedule: function(){
        app.utils.configScheduleSettings('defaults');
    },

    saveSchedule: function(){
        var scheduleTable = document.querySelector("table.vts-table");
        if(!scheduleTable) return;
        var scheduleParams = [];
        scheduleTable.querySelectorAll("select").forEach((select)=>{
            var picked = [];
            for(i=0;i<=select.selectedOptions.length-1;i++){
                if(select.selectedOptions.length>0 && select.selectedOptions[i].value=='*'){
                    picked.push('*');
                    break;
                }
                picked.push(select.selectedOptions[i].value);
            }
            if(picked.length===0){
                picked = ["*"];
                if(select.getAttribute('name')=="cron_min") picked=['0'];
                if(select.getAttribute('name')=="cron_hour") picked=['12'];
            }
            scheduleParams.push(picked.join(","));
        });
        var timeZone = scheduleParams.pop();
        var scheduleCron = scheduleParams.join(" ");

        var payload = {
            crons: [
                {
                    cron: scheduleCron,
                    timeZone: timeZone
                }
            ]
        }

        app.vyb.set('triggerSettings',payload);
        app.vyb.patch({triggerSettings: payload});
        app.header.set('editing',false);
        this.renderTriggerSettings();
    },

    saveGeofence: function(){

        let location = this.query("input[name='glocation']").value;
        location = location.replace(/[ \(\)]/g,"");

        //parse location from map link
        let mapRegex = /[0-9\-][0-9\.]{1,},[0-9\-][0-9\.]{1,}/;
        if(location.match(mapRegex)){
            location = location.match(mapRegex)[0];
            console.log('found location in link: ' + location);
        }

        let latlon = location.split(",");
        let lat = latlon[0];
        let lon = latlon[1];
        let radius = this.query("input[name='gradius']").value;
        let radius_units = this.query("select[name='gradius_units']").value;
        let type = this.query("select[name='gtype']").value;
        let timeThrottle = this.query("input[name='gtime']").value;
        let subscribable = this.query("select[name='gsub']").value;

        //validate
        let regex1 = /^(-|)[\.0-9]+$/;
        let regex2 = /^[0-9\.]{1,}$/;

        if(lat.match(regex1)==null){ alert("Invalid value for latitude."); return; }
        if(lon.match(regex1)==null){ alert("Invalid value for longitude."); return; }
        if(radius.match(regex2)==null){ alert("Invalid value for radius."); return; }
        if(timeThrottle.match(regex2)==null){ alert("Invalid value for reset threshold."); return; }

        //convert radius to meters
        let displayRadius = radius;
        if(radius_units==="kilometers") radius = radius*1000;
        else if(radius_units==="miles") radius = radius*1609.34;
        radius = parseFloat(radius);
        if(radius < 100.00){
            radius = parseFloat(100.0);
            displayRadius = 100;
            if(radius_units==="kilometers") displayRadius = radius/1000;
            if(radius_units==="miles") displayRadius = (radius/1609.34).toPrecision(4);
        }

        var payload = {
                    id: app.vyb.key,
                    lat: parseFloat(lat),
                    lon: parseFloat(lon),
                    radius: radius.toFixed(6)*1,
                    radiusUnits: radius_units,
                    displayRadius: displayRadius,
                    type: type,
                    timeThrottle: timeThrottle,
                    subscribable: subscribable
        };
        
        app.vyb.set('geofence',payload);
        app.vyb.patch({geofence: payload});
        app.header.set('editing',false);
        this.renderTriggerSettings();
    }
});

module.exports = VybFullView;
