/**
 * @fileOverView
 * @description Utilities, basic functions attached to app<br>
 * @copyright 2015-present Flatirontek LLC
 **/

'use strict';
const moment = require('moment-timezone');
const Clipboard = require('clipboard');
const Rating = require('./rating_manager');
const app = require('ampersand-app');


module.exports = {

    setAuthTokenHeader: function(){
        return {
            headers: {
                'Authorization': app.auth.jwtToken
            }
            /* , xhrFields: {
                'withCredentials': true
            } */
        };
    },

    playSound : function(sk,isVyb=false,logParams=null){

        if(sk==null || sk=='none'){
            app.eventLog.add('playing sound of none');
            app.utils.stopShakes();
            return;
        }

        if(app.sounds.get(sk,'key')) app.sounds.get(sk,'key').playSound(isVyb,logParams);
        else{
            var sound = new app.Sound({key:sk});
            sound.fetch({
                success: function(model,response){
                    app.sounds.add(model);
                    if(app.vybs.get(model.key,'soundKey')!=undefined){
                        app.vybs.get(model.key,'soundKey').set('sound',model);
                        app.vybs.get(model.key,'soundKey').set('soundName',model.name);
                    }
                    model.playSound(isVyb,logParams);
                },
                error: function(){
                    app.eventLog.add('cannot retrieve sound ' + sk);
                }
            });
        };
    },

    retrieveSound : function(sk){

        if(sk==null || sk=='none'){
            app.eventLog.add('retrieving sound of none.')
        }

        if(app.sounds.get(sk,'key')){
            if(app.vybs.get(sk,'soundKey')!=undefined){
                app.vybs.get(sk,'soundKey').set('soundName',app.sounds.get(sk,'key').name);
            }
        }else{
            var sound = new app.Sound({key:sk});
            sound.fetch({
                success: function(model,response){
                    app.sounds.add(model);
                    if(app.vybs.get(model.key,'soundKey')!=undefined){
                        app.vybs.get(model.key,'soundKey').set({'soundName':model.name});
                    }
                },
                error: function(){
                    app.eventLog.add('cannot retrieve sound ' + sk);
                }
            });
        };
        return 1;
    },

    loadVybWithSound: function(sound){

        //save chosen sound from demo sound page
        if(!app.auth.authenticated && app.vybs==undefined){
            this.cacheChosenDemoSound(sound);
            return;
        }

        if(sound.mode=="sounds"){
            var vyb = app.vybs.get(sound.vybKey);
        }else{
            if(sound.vybKey!=null) return;
            var vyb = app.vyb;
            var oldSoundKey = vyb.soundKey;
            if(app.sounds.get(oldSoundKey)){
                var oldSound = app.sounds.get(oldSoundKey);
                console.log('changing old sound ' + oldSound.name);
                app.sounds.get(oldSoundKey).set('mode','sounds');
                app.sounds.get(oldSoundKey).set('toggle',oldSound.toggle ? false : true);
            }
            app.usedSounds.remove(oldSoundKey,'key');
            vyb.patch({soundKey: sound.key},null);
            app.usedSounds.add(sound);
        }

        var options = {model:vyb};
        if(sound.vybKey==null){
            options.soundKey = sound.key;
            options.soundName = sound.soundName;
            options.action = "showSound";
        }

        console.log('loading vyb page');

        var vybpageview =   new app.VybPageView(options);
        app.pageSwitcher.set(vybpageview);
    },

    loadVybFollowWithSound: function(sound){
        if(sound.mode=="sounds"){
            var vybfollow = app.vybsfollowing.get(sound.vybKey);
        }else{
            if(sound.vybKey!=null) return;
            var vybfollowing = app.vybfollowing;
            var oldSoundKey = vybfollowing.soundKey;
            app.usedSounds.remove(oldSoundKey,'key');
            vybfollowing.patch({soundKey:sound.key});
            app.usedSounds.add(sound);
        }

        var options = {model:vybfollowing};
        if(sound.vybKey==null){
            options.soundKey = sound.key;
            options.action = "showSound";
        }

        var vybfollowingpageview =   new app.VybFollowingPageView(options);
        app.pageSwitcher.set(vybfollowingpageview);
    },

    cacheChosenDemoSound: function(sound){
        console.log('caching chosen demo sound ' + sound.name + ', ' + sound.key);
        window.localStorage.setItem('demoSoundKey', sound.key);
        window.localStorage.setItem('demoSoundName', sound.name);
        window.location.reload();
        return;
    },

    captureQueryParams: function(){
        //parse out parameters
        var params = {}, queries, temp, i, l;
        queries = app.queryString.split("&");
        for ( i = 0, l = queries.length; i < l; i++ ) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        app.queryParams = params;

        //remove query string from location, except for dev env
        app.incomingUrl = window.location.href;
        if (app.incomingUrl.indexOf("?") > 0 && app.config.env!="developmentOFF") {
            var updatedUri = app.incomingUrl.substring(0, app.incomingUrl.indexOf("?"));
            window.history.replaceState({}, document.title, updatedUri);
            app.queryString = "";
        }

        //store subscription key
        if(app.queryParams.sk!=undefined){
            window.localStorage.setItem('subscriptionKey',app.queryParams.sk);
        }
    },

    captureSubscriptionKeyFromDeepLink: function(link){
        console.log('capturing sk from deep link ' + link);
        var parts = link.split("sk=");
        var sk = parts.pop();
        console.log('sk is ' + sk);
        window.localStorage.setItem('subscriptionKey',sk);
        if(app.auth.authenticated) this.processSubscription();
    },

    processSubscription: function(){
        var sk = window.localStorage.getItem('subscriptionKey');
        if(sk==null){
            console.log('no subscriptionKey');
            return;
        }
        app.eventLog.add('subscribing to ' + sk);
        var subscription = new app.VybFollowing();
        subscription.set('subscriptionKey',sk);
        window.localStorage.removeItem('subscriptionKey');
        subscription.save({},{
            success: (model,response) => {
                console.log("successfully subscribed to " + sk);
                app.renderPage();
            },
            error: (err) => {
                console.log("error subscribing to " + sk + ": ");
                console.dir(err);
            }
        });
    },
    
    checkForUnplayedVybs: function(){
        app.eventLog.add('begin check for unplayed vybs');
        app.logs.getLogsWithUnplayedSounds();
    },

    stopShakes: function(){
        app.eventLog.add('trying to stop shakes');
        var elements = document.getElementsByClassName('vybshake');
        while(elements.length > 0){
            elements[0].classList.remove('vybshake');
        }
    },

    startSpinner: function(){
        var spinnerDiv = document.querySelector("div.vspinner");
        if(spinnerDiv==null) return;
        spinnerDiv.classList.add("ispinner");
        spinnerDiv.classList.add("gray");
        spinnerDiv.classList.add("animating");

        var cover = document.querySelector('div.vcover');
        if(cover!=null) cover.classList.add("voverlay");

    },

    stopSpinner: function(){
        var spinnerDiv = document.querySelector("div.vspinner");
        if(spinnerDiv!=null){
            spinnerDiv.classList.remove("ispinner");
            spinnerDiv.classList.remove("gray");
            spinnerDiv.classList.remove("animating");
        }

        var cover = document.querySelector('div.vcover');
        if(cover!=null) cover.classList.remove("voverlay");

        app.utils.setDisplayMode();
    },

    cancelBubble: function(e) {
        if(e!=undefined && e.preventDefault) e.preventDefault();
        var evt = e ? e:window.event;
        if (evt.stopPropagation)    evt.stopPropagation();
        if (evt.cancelBubble!=null) evt.cancelBubble = true;
        return false;
    },

    infiniteScroll : function(view){

        var wait = 1000;
        var now = Date.now();

        if (view.el.scrollHeight - view.el.scrollTop - 20 <= view.el.clientHeight) {

            //throttle
            var diff = now - view.lastLoad;
            if(diff < wait){
                return;
            }else{
                view.lastLoad = now;
            }

            var data = {offset:view.collection.models.length};
            if(app[view.entity].searchQuery!=undefined && app[view.entity].searchQuery.length>0) data.query = app[view.entity].searchQuery;
            app.utils.startSpinner();
            view.collection.fetch({
                remove:false,
                reset:false,
                add:true,
                data:data,
                success: function(){
                    app.utils.stopSpinner();
                    console.log('loaded more ' + view.entity);
                    if(view.entity=="logs") app.meter.fetch();
                },
                error: function(){
                    app.utils.stopSpinner();
                    console.log('error loading new ' + view.entity);
                },
            });
        }

        if(view.el.scrollTop==0){

            //throttle
            var diff = now - view.lastLoad;
            if(diff < wait){
                return;
            }else{
                view.lastLoad = now;
            }

            app.utils.startSpinner();
            data = {};
            if(app[view.entity].searchQuery!=undefined && app[view.entity].searchQuery.length>0) data.query = app[view.entity].searchQuery;
            view.collection.fetch({
                data: data,
                success: function(){
                    app.utils.stopSpinner();
                    if(view.entity=="logs"){
                        app.vybsfollowing.fetch();
                        app.meter.fetch();
                    }
                },
                error: function(){
                    app.utils.stopSpinner();
                }
            });
        }
    },

    switchToVybPage: function(entity){

        //vybfollow
        if(entity.followKey!=null){

            //already loaded
            if(app.vybsfollowing.get(entity.followKey)!=undefined){
                var pageview =   new app.VybFollowingPageView({model:app.vybsfollowing.get(entity.followKey)});
                app.pageSwitcher.set(pageview);

            //fetch it
            }else{
                var vf = new app.VybFollowing({key:entity.followKey});
                vf.fetch({
                    success: function(model,response){
                        app.vybfollowing.add(model);
                        var pageview =   new app.VybFollowingPageView({model:model});
                        app.pageSwitcher.set(pageview);
                    },
                    error: function(model,response,error){
                        console.log('error fetch vybfollowing ' + entity.followKey);
                    }
                });
            }

        //vyb
        }else if(entity.key!=null || entity.vybKey!=null){

            if(entity.vybKey!=null) entity.key = entity.vybKey;

            //already loaded
            if(app.vybs.get(entity.key)!=undefined){
                var pageview =   new app.VybPageView({model:app.vybs.get(entity.key)});
                app.pageSwitcher.set(pageview);

            //fetch it
            }else{
                var vyb = new app.Vyb({key:entity.key});
                vyb.fetch({
                    success: function(model,response){
                        app.vybs.add(model);
                        var pageview =   new app.VybPageView({model:model});
                        app.pageSwitcher.set(pageview);
                    },
                    error: function(model,response,error){
                        console.log('error fetch vyb ' + entity.key);
                    }
                });
            }
        }
    },

    switchToSubscriptionPage: function(subscription){
        var pageview =   new app.SubscriptionCardPageView({model:subscription});
        app.pageSwitcher.set(pageview);
    },

    reviewVyb: function(vybKey){
      this.cancelBubble();
      this.switchToVybPage({vybKey:vybKey});
    },

    //assumes mp3
    playSoundFileFromKey: function(soundKey,soundType="mp3"){

        if(soundKey==null){
            this.stopShakes();
            return;
        }

        var soundFile = soundKey + "." + soundType;
        this.playSoundFile(soundFile,soundKey);
    },

    playSoundFile: function(soundFile,soundKey=null){

        var pel = null;
        var el = null;
        if(soundKey!=null){
            if(document.querySelector("[data-sk='"+soundKey+"'], [data-soundkey='"+soundKey+"']")!=null) pel = document.querySelector("[data-sk='"+soundKey+"'], [data-soundkey='"+soundKey+"']");
            if(pel!=null && pel.querySelector("img.vdeltaimg, img.vsubdeltaimg")!=null){
                el = pel.querySelector("img.vdeltaimg, img.vsubdeltaimg");
                el.classList.add("vybshake");
            }
        }

        var file = app.config.soundLib + '/' + soundFile;
        var sobject = new Audio(file);
        sobject.volume = 1.0;
        sobject.onended = function(){
            app.eventLog.add('play sound file ' + soundFile + ' completed');
            if(pel!=null) pel.blur();
            if(el!=null) el.classList.remove("vybshake");
        };
        sobject.play();
    },

    runTicker: function(view){
        var self = this;
        var message = ' search thousands of sounds. for your vybits, ' +
            'for example:. dog bark. chimes. thunder. try it! :). search sounds|';
        var ticker = view.query("input.vsdemo");
        var queue = message.split("");
        var cur = "";

        this.advanceTicker(self,ticker,queue,cur);
    },

    advanceTicker: function(self,ticker,queue,cur){
        var interval = queue[0]=="." ? 1600 : 70;
        (function(self,ticker,queue,cur){
            setTimeout(
                function(){
                    if(ticker==undefined) return;
                    var char = queue.shift();
                    cur = char=="." ? "" : cur + char;
                    if(char=="|") return;
                    ticker.setAttribute("placeholder",cur);
                    self.advanceTicker(self,ticker,queue,cur);
                },
                interval
            );
        }(self,ticker,queue,cur));
    },

    demoSoundSearch: function(){
        var searchQuery = document.querySelector("input.vsdemo").value;
        console.log('squery is ' + searchQuery);
        clearInterval(app.tickerInterval);
        var url = app.config.baseApiUrl + "?p=sounds&squery=" + encodeURIComponent(searchQuery);
        console.log('url is ' + url);
        window.location.href = url;
    },

    dssOnEnter: function(event){
        if(event.keyCode==13){
            this.demoSoundSearch();
        }
    },

    docSectionTitleMap: (section)=>{
        var map = {
            "what_is_vybit" : 'What is Vybit?',
            "vybit_awesome" : 'Why is Vybit AWESOME?',
            "getting_started" : 'Getting Started',
            "create" : 'Vybit Creation and Management',
            "trigger" : 'Triggering Vybits',
            "subscribe" : "Subscribing to Vybits",
            "sharing" : 'Sharing Vybits',
            "faqs" : 'FAQs and Troubleshooting',
            "support" : 'How Do I Contact Vybit Support?',
            "platforms" : 'Services and Platforms to use with Vybit',
            "white_label" : 'White Label Vybit for you App, Service, or Platform',
            "marketing" : 'Market to Your Customers with Vybit',
        };
        return map[section];
    },

    openDocCardfromFrag: function(){
        var map = {
            "what_is_vybit" : 'whatIsVybit',
            "vybit_awesome" : 'vybitAwesome',
            "getting_started" : 'gettingStarted',
            "create" : 'createVyb',
            "trigger" : 'triggerVyb',
            "subscribe" : "subscribeVyb",
            "sharing" : 'sharingVybs',
            "faqs" : 'faqs',
            "platforms" : 'platforms',
            "support" : 'support',
            "whitelabel" : 'whiteLabel',
            "marketing" : "market",
            "geofence" : "createVyb"
        };
        var frag = window.location.hash.substring(1);
        var elId = map[frag];

        if(elId!=undefined){

            var a = document.getElementById('S' + elId);
            if(a!=null) a.classList.add('show');

            var q = document.getElementById('H' + elId);
            if(q!=null){
                var container  = document.getElementById('docs');
                var containerTop = container.offsetTop;
                var topPos = q.parentElement.offsetTop;
                var childPos = 0;
                if(document.getElementById(frag)!=null){
                    childPos = document.getElementById(frag).offsetTop - topPos - containerTop + 160;
                }
                console.log('c top is ' + containerTop);
                console.log('topPos is ' + topPos);
                container.scroll({top: topPos - containerTop + childPos});
            }
        }
    },

    retrieveSubscriptionAndDisplay: function(loginModel){
        var subscription = new app.Subscription({key: window.localStorage.getItem('subscriptionKey')});
        subscription.fetch({
           success: function(model,response){
               loginModel.set('loginMessage','Sign In to subscribe to vybit <b>' + model.name + '</b> from <b>'+ model.ownerName +'</b>.');
           }
        });
    },

    removeParentEl: function(event,el){
        this.cancelBubble(event);
        el.parentElement.remove();
    },

    subscribeToVybFromKey: function(subKey=null, el=null){

        if(subKey==null){
            console.log('no subscription key found');
            return;
        }

        console.log('subKey is ' + subKey);

        if(window.location.host==app.config.wwwHost) window.open(app.config.baseApiUrl + "/subscribe/" + subKey);
        else{
            window.localStorage.setItem("subscriptionKey",subKey);
            this.processSubscription();
        }

        if(el!=null) el.blur();
    },

    validServiceClient: function(client_id=app.queryParams.client_id){
        return Object.keys(app.config.serviceAuth).includes(client_id);
    },

    firebaseAuthPrep: function(){
        /* Browsertab */
        window.cordova.plugins.browsertab = {};

        /* Browsertab - Is Available */
        window.cordova.plugins.browsertab.isAvailable = function( success ) {
            window.SafariViewController.isAvailable( success );
        }

        /* Browsertab - Open Url */
        window.cordova.plugins.browsertab.openUrl = function( url ) {
            url += "&nocache=1&buster=" + Math.floor(Math.random() * 1000000000 );
            window.SafariViewController.show( { url: url } , function() { console.log('opening url ' + url); } , function() {} );
        }

        /* Browsertab - Close */
        window.cordova.plugins.browsertab.close = function() {
            window.SafariViewController.hide();
        }
    },

    setDisplayMode: function(){
        if(app.header==undefined) return;
        if(app.header.get('mode')=="day"){
            document.querySelector("body").classList.remove("vnight");
            if(document.querySelector("img.vsearchimg")!=null) document.querySelector("img.vsearchimg").src = "images/search_25px.png";
            if(document.querySelector("img.vdeltaimg, img.vbell")!=null) document.querySelectorAll("img.vdeltaimg, img.vbell").forEach(function(el){ el.src = "images/bell1.png";});

            if(app.currentPage!=undefined) app.currentPage.queryAll("div.container, input, div.vname, div.vlogvybname, div.vtable, selector, label.vswitchlabel, a.vtab, div.card-header, button.vdochead, button.vcard, h2.vcard-header").forEach(function(el){
                el.classList.remove("vnight");
            });
        }else{
            document.querySelector("body").classList.add("vnight");
            if(document.querySelector("img.vsearchimg")!=null) document.querySelector("img.vsearchimg").src = "images/search_night_25px.png";
            if(document.querySelector("img.vdeltaimg, img.vbell")!=null) document.querySelectorAll("img.vdeltaimg, img.vbell").forEach(function(el){ el.src = "images/bell1_night.png";});
            app.currentPage.queryAll("div.container, input, div.vname, div.vlogvybname, div.vtable, selector, label.vswitchlabel, a.vtab, div.card-header, button.vdochead, button.vcard, h2.vcard-header").forEach(function(el){
                el.classList.add("vnight");
            });
        }
    },

    isAWebView: function(){

        if(app.platformId!="android"){
            var is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
            return is_uiwebview;

        }else{
            if( navigator.userAgent.includes ('wv')){
                return true;
            }else{
                return false;
            }
        }

        return false;
    },

    createDomElementFromString: function(html){
        var div = document.createElement('DIV');
        div.innerHTML = html;
        return div.firstChild;
    },

    startBackgroundRefresh: async function(){

        if(window.BackgroundFetch==undefined) return;

        var BackgroundFetch = window.BackgroundFetch;

        var onEvent = async function(taskId) {
            app.eventLog.add('BackgroundFetch.onEvent(' +taskId + ') background refresh started');
            app.loadData();
            setTimeout(()=>{
                app.eventLog.add('starting BackgroundFetch.finish(' + taskId + ')');
                BackgroundFetch.finish(taskId);
                },20000);
        };

        var onTimeout = async function(taskId) {
            app.eventLog.add('[BackgroundFetch] TIMEOUT: ', taskId);
            BackgroundFetch.finish(taskId);
        };

        var status = await BackgroundFetch.configure(
            {
                minimumFetchInterval: 60,
                requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY
            }, onEvent, onTimeout);
        app.eventLog.add('[BackgroundFetch] configure status: ');
        app.eventLog.add(status);
    },

    configScheduleSettings: function(mode='current', view=null){

        //currentSettings
        let currentSettings = {};
        if(app.vyb.triggerSettings!=null && app.vyb.triggerSettings.crons!=undefined && app.vyb.triggerSettings.crons[0]!=undefined){
            currentSettings = app.vyb.triggerSettings.crons[0];
        }

        //timezone
        var tzSelect = view!=null ? view.query("select[name='cron_timezone']") : document.querySelector("select[name='cron_timezone']");
        var timeZones = moment.tz.names();
        for (var i = 0; i<=timeZones.length-1; i++){
            var opt = document.createElement('option');
            opt.value = timeZones[i];
            opt.innerHTML = timeZones[i];
            tzSelect.appendChild(opt);
        }
        if(mode=='current' && currentSettings.timeZone!=undefined){
            tzSelect.value = currentSettings.timeZone;
        }else{
            tzSelect.value = moment.tz.guess();
        }

        var params = ['cron_min','cron_hour','cron_dom','cron_month','cron_dow'];
        //defaults
        var paramValues = {
            cron_min: '0',
            cron_hour: '12',
            cron_dom: '*',
            cron_month: '*',
            cron_dow: '*'
        };

        if(mode==='current' && currentSettings.cron!=undefined){
            var cronSettings = currentSettings.cron.split(" ");
            for(i=0; i<=cronSettings.length-1; i++){
                paramValues[params[i]] = cronSettings[i];
            }
        }

        params.forEach((param)=>{
            var select = view!=null ? view.query("select[name='"+ param +"']") : document.querySelector("select[name='"+ param +"']");
            select.value = null;

            var values = paramValues[param],
                options = Array.from(select.querySelectorAll('option'));

            values.split(',').forEach(function(v) {
                options.find(c => c.value == v).selected = true;
            });

        });
    },

    configGeofenceSettings: function(view){

        //currentSettings
        let currentSettings = {};
        if(app.vyb.geofence!=null){
            currentSettings = app.vyb.geofence;
        }

        const clickHereSpan = '<span class="vset">click here to set</span>'

        var params = {
            glocation: currentSettings.lat!=undefined ? currentSettings.lat + ', ' + currentSettings.lon : clickHereSpan,
            gradius: currentSettings.displayRadius!=undefined ? currentSettings.displayRadius : '200',
            gradius_units: currentSettings.radiusUnits!=undefined ? currentSettings.radiusUnits : 'meters',
            gtype: currentSettings.type!=undefined ? currentSettings.type : 'enter',
            gtime: currentSettings.timeThrottle!=undefined ? currentSettings.timeThrottle : '24',
            gsub: currentSettings.subscribable!=undefined ? currentSettings.subscribable : 'no',
        }

        var mapUri = params.glocation==clickHereSpan ? `https://google.com/maps/?q=current+location` : `https://google.com/maps/?q=${app.vyb.geofence.lat},${app.vyb.geofence.lon}`;
        var mapLink = `<a onclick="app.utils.cancelBubble()" href="${mapUri}" target="_blank">map link</a>`;

        view.query("input[name='glocation']").value = params.glocation==clickHereSpan ? '' : params.glocation;
        view.query("input[name='glocation']").placeholder = 'set coordinates in decimal format';
        view.query("input[name='gradius']").value = params.gradius;
        view.query("select[name='gradius_units'] option[value='"+params.gradius_units+"']").selected = true;
        view.query("select[name='gtype'] option[value='"+params.gtype+"']").selected = true;
        view.query("input[name='gtime']").value = params.gtime;
        view.query("select[name='gsub']").value = params.gsub;
        view.query("div[data-hook='gmap']").innerHTML = mapLink;

    },

    manageRating : () => {
        const now = Math.round(new Date().getTime() / 1000);
        //set request dts
        if(app.profile?.permissions?.rating === undefined) {

            var ratingStatus = {
                status: "soak",
                dts: now + app.config?.ratingSoakTime
            }

            let permissions = app.profile.permissions || {};
            permissions.rating = ratingStatus;
            app.profile.set('permissions', permissions);
            app.profile.patch({permissions: permissions});
            app.eventLog.add('setting rating permission:');
            app.eventLog.add(ratingStatus);

            //rating shown/completed
        }else if(app.profile.permissions?.rating?.status === "done"){
            app.eventLog.add('rating was shown/completed');

            //request rating
        }else if(app.profile.permissions?.rating?.status!=="done" && now > app.profile.permissions.rating.dts){
            app.eventLog.add('will ask for rating since ' + now + ' > ' + app.profile.permissions.rating.dts);
            Rating.askForRating()
        }else{
            app.eventLog.add('rating ask still soaking since ' + now + ' < ' + app.profile.permissions.rating.dts);
        }
    },

    isValidURL: (string) => {
        var res = string.match(/https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g);
        return (res !== null);
    },

    clipboard: () => {
        if(app.clipboard!=undefined) app.clipboard.destroy();
        app.clipboard = new Clipboard('button.vcopybutton');
        app.clipboard.on('success', function (e) {
            const element = e.trigger;
            element.innerText = "COPIED";
            setTimeout(function(){element.innerText = "COPY"; },4000);
            e.clearSelection();
        });
        app.clipboard.on('error', function (e) {
            console.error(e);
        });
    }
}