/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SoundsPageTemplate = require('../../templates/pages/soundspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var SoundsView = require('../widgets/soundsview');
var SearchView = require('../components/search');
var app = require('ampersand-app');

var SoundsPageView = AmpersandView.extend({

    template : SoundsPageTemplate,

    initialize: function(options){
        this.mode = options.mode;
        this.maintitle = "Sounds";
        if(options.mode=="vyb") this.maintitle = "Vybit Sound for " + app.vyb.name;
        if(options.mode=="vybfollow") this.maintitle = "Vybit Sound for " + this.model.vybName;
    },


    pageName: "SoundsPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: this.maintitle
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        sounds: {
            selector : '#content',
            prepareView: function(el) {
                return new SoundsView({
                    el: el,
                    parent: this,
                    mode: this.mode
                });
            }
        },

        search: {
            selector : 'div.vsearchtable',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'sounds'
                });
            }
        }
    },

    events: {
        "click a.vtab" : "saveActiveTab"
    },

    saveActiveTab: function(event){
        window.sessionStorage.setItem('SoundsPageActiveTab', event.delegateTarget.id);
    }
});

module.exports = SoundsPageView;
