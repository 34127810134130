/**
 * @fileOverView
 * @description Logs View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybLogsTemplate = require('../../templates/widgets/vyblogstemplate.html');
var EmptyLogsTemplate = require('../../templates/widgets/emptylogstemplate.html');
var LogView = require('./logview');
var app = require('ampersand-app');

var VybLogsView = AmpersandView.extend({

    template: VybLogsTemplate,

    initialize: function(){
        if(app.vyb.key==null) this.template = EmptyLogsTemplate;
    },

    render: function(){
        this.renderWithTemplate(this);
        var that = this;

        if(app.vyb.key!=null) app.vyb.logs.fetch({
            success: function(collection,response,options){
                that.renderCollection(collection,LogView,'#logstable');
            }
        });

    }

});

module.exports = VybLogsView;
