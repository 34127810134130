/**
 * @fileOverView
 * @description Personsounds Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var Sound = require("../models/sound");
var config = require("../etc/config");
var app = require("ampersand-app");

var UsedSounds = RestCollection.extend({

    model: Sound,

    indexes: ['key'],

    url: function(){ return config.restApiUrl + "/soundsfor"; },

    ajaxConfig: app.utils.setAuthTokenHeader,

    soundsSetUp: function(){
        if(app.platform=="browser") return;

        this.models.forEach(sound => {
            app.fsutils.soundSetUp(sound);
        });
    },

    addSoundFromKey: function(soundKey, thenPlay=false){
        var sound = new app.Sound({key: soundKey});
        if(this.get(soundKey,'key')!=undefined){
            sound = this.get(soundKey,'key');
            if(thenPlay) sound.soundObjectLocal.play();
            app.fsutils.loadSoundFromFile(soundKey, sound.soundFile);

        }else{
            sound.fetch({
                success: function(model,response){
                    app.fsutils.loadSoundFromFile(model.key, model.key + "." + model.type);
                    console.log('adding sound ' + soundKey + ' to usedSounds');
                    app.usedSounds.add(model);
                    if(thenPlay) model.soundObjectLocal.play();
                },
                error: function(){
                    console.log('error retrieving sound ' + soundKey + ' in usedSounds.addSoundFromKey');
                }
            })
        }
    }
});

module.exports = UsedSounds;
