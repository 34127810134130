/**
 * @fileOverView
 * @description Single Sound Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SoundPageTemplate = require('../../templates/pages/soundpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var SoundFullView = require('../widgets/soundfullview');
var Sound = require("../../models/sound");
var app = require('ampersand-app');

var SoundPageView = AmpersandView.extend({

    initialize: function(options){
      if(!this.model) this.model = options.model ? options.model : new Sound();
      app.sound = this.model;
    },

    template : SoundPageTemplate,

    pageName: "SoundPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    model: this.model,
                    maintitle: 'Add New Sound'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        sound: {
            selector : '#content',
            prepareView: function(el) {
                return new SoundFullView({
                    el: el,
                    parent: this,
                    model: app.sound
                });
            }
        }

    },

    events: {

    }

});

module.exports = SoundPageView;
