/**
 * @fileOverView
 * @description Params Collection, generally to handle form params for models<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var Collection = require('ampersand-collection');
var Param = require('../models/param');

var Params = Collection.extend({

    mainIndex: 'name',

    model:Param
});

module.exports = Params;
