/**
 * @fileOverView
 * @description Param State, generally to handle Vyb form parameters<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require('ampersand-app');

var Param = AmpersandModel.extend({

    idAttribute: "name",

    props: {
        entity : 'string',
        name : 'string',
        inputType : 'string',
        template: 'string',
        label: 'string',
        value: 'string',
        display: 'string',
        placeholder: 'string',
        rightColContent: 'any',
        displayName: 'string'
    },

    session: {
        editing: ["boolean",true,false],
        hidden: ["boolean",true,false],
        cursor: ["boolean",false,false],
        doNotHide : ["boolean",false,false],
        displayPlaceholder: ['boolean',false,false],
        pinging: ["boolean",true,false],
        bypassSave: ["boolean",true,false],
        labelNoWrap: ["boolean",true,false],
    },

    derived: {
        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        }
    }

});

module.exports = Param;
