/**
 * @fileOverView
 * @description Sound View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SoundTemplate = require('../../templates/widgets/soundtemplate.html');
var config = require("../../etc/config");
var app = require('ampersand-app');

var SoundView = AmpersandView.extend({
    template: SoundTemplate,

    initialize: function(options){
        this.deletingSound = false;
    },

    bindings: {
        "model.name": {
            type: 'text',
            hook: 'soundName'
        },
        "model.descriptionDisplay" : {
            type: 'text',
            hook: 'description'
        },
        "model.vybName" : {
            type: 'innerHTML',
            hook: 'vybName'
        },
        "model.used" : {
            type: 'booleanClass',
            selector: '.vrightcol',
            name: 'vused'
        },
        "model.creator" : {
            type: 'innerHTML',
            hook: 'creator'
        },

        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }
    },

    events: {
        "click .vsounddel" : "deleteSound",
        "click .vdeltaclick, img.vdeltaimg, div.vsoundinfo" : "playSound",
        "click [data-hook=vybName], div.vused" : "switchToVybPage",
        "click .vhidebutton" : "hideSound",
        //"click button.vcreatevybbutton" : "createVyb"
    },

    playSound: function(event){

        if(this.deletingSound){
            this.deletingSound = false;
            return;
        }

        app.utils.stopShakes();
        this.model.set('pinging',true);
        app.soundViews[this.model.key] = this.model;
        app.utils.playSound(this.model.key);
    },

    hideSound: function(){
        this.parent.collection.remove(this.model);
    },

    switchToVybPage: function(){
        if(this.model.mode=="vybfollow") app.utils.loadVybFollowWithSound(this.model);
        else app.utils.loadVybWithSound(this.model);

    },

    createVyb: function(){
        app.utils.loadVybWithSound(this.model);
    },

    deleteSound: function(e){
        this.deletingSound = true;

        if(confirm('Permanently Delete this Sound?')){
            console.log('deleting sound ' + this.model.key);
            if(app.sounds.get(this.model.key,'key')){
                app.sounds.remove(app.sounds.get(this.model.key,'key'));
            }
            if(app.platform=="app") app.fsutils.deleteFile(app.config.localSoundDir,this.model.soundFile);
            this.model.destroy();
        }
    }

});

module.exports = SoundView;
