/**
 * @fileOverView
 * @description app rating management. <br>
 * @copyright 2015-present Flatirontek LLC
 **/
'use strict';
var app = require('ampersand-app');

var MAX_DIALOG_WAIT_TIME_IOS = 5*1000;
var MAX_DIALOG_WAIT_TIME_ANDROID = 60*1000;
var ratingTimerId;

module.exports = {

    ratingDialogNotShown: function(){
        var msg;
        if(cordova.platformId === "android"){
            msg = "Rating dialog outcome not received (after " + MAX_DIALOG_WAIT_TIME_ANDROID + "ms)";
        }else if(cordova.platformId === "ios"){
            msg = "Rating dialog was not shown (after " + MAX_DIALOG_WAIT_TIME_IOS + "ms)";
        }
        app.eventLog.add(msg,'warn');
    },

    askForRating: function(){
        if(app.platform === "browser" || LaunchReview === undefined) return;
        const self = this;

        app.eventLog.add('rating ask attempt start');

        if(app.platformId === "android"){
            ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_ANDROID);
        }

        LaunchReview.rating(function(status){
            if(status === "requested"){
                if(app.platformId === "android"){
                    app.eventLog.add("Displayed rating dialog");
                    clearTimeout(ratingTimerId);
                    self.updateRatingStatus('done');
                }else if(app.platformId === "ios"){
                    app.eventLog.add("Requested rating dialog");
                    ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_IOS);
                }
            }else if(status === "shown"){
                app.eventLog.add("Rating dialog displayed");
                clearTimeout(ratingTimerId);
                self.updateRatingStatus('done');
            }else if(status === "dismissed"){
                app.eventLog.add("Rating dialog dismissed");
                clearTimeout(ratingTimerId);
                self.updateRatingStatus('soak');
            }
        }, function (err){
            console.error("Error launching rating dialog: " + err);
            clearTimeout(ratingTimerId);
            self.updateRatingStatus('soak');
        });
    },

    updateRatingStatus: function(status){
        let permissions = app.profile.permissions;
        if(status=='done'){
            permissions.rating.status = "done";
        }else if(status=='soak'){
            permissions.rating.status = "soak";
            permissions.rating.dts = new Date() + app.config.ratingSoakTime;
        }
        app.profile.set('permissions', permissions);
        app.profile.patch({permissions: app.profile.permissions});
    }
}