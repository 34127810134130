/**
 * @fileOverView
 * @description Login Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var LoginPageTemplate = require('../../templates/pages/loginpage.html');
var DocsPageTemplate = require('../../templates/pages/docspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var LoginModel = require('../../models/login');
var app = require('ampersand-app');

var LoginPage = AmpersandView.extend({

    template : LoginPageTemplate,

    initialize: function(options){

        var modelOptions = options.modelOptions!=undefined ? options.modelOptions : {};
        if(app.platform!="browser" || app.serviceAuth==true) modelOptions.showStoreLinks = false;
        this.model = new LoginModel(modelOptions);

        if(window.localStorage.getItem('subscriptionKey')!=null) app.utils.retrieveSubscriptionAndDisplay(this.model);

    },

    render: function(){
        var self = this;
        this._initializeSubviews();
        this.renderWithTemplate();
        if(this.query("input.vsdemo")!=null && app.platform=="browser"){
            app.utils.runTicker(self);
            app.tickerInterval = setInterval(function(){
                app.utils.runTicker(self);
            }, 45000);
        }
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {

                this.model.set('maintitle','Welcome to Vybit!');
                if(app.queryParams['client_id']!=undefined){
                    this.model.set('maintitle','Vybit Authentication for ' + app.queryParams['client_id'].toUpperCase());
                }

                return new HeaderView({
                    el: el,
                    parent: this,
                    noback: true,
                    login: true,
                    maintitle: this.model.maintitle
                });
            }
        }
    },

    bindings: {
        "model.displayGettingStarted" : {
            type: "innerHTML",
            hook: "getting_started"
        },
        "model.displayStoreLinks" : {
            type: "innerHTML",
            hook: "storelinks"
        },
        "model.displayLoginMessage" : {
            type: "innerHTML",
            selector: "div.vloginmessage"
        }
    },

    events: {
        "click #login-apple" : "signInApple",
        "click #login-google" : "signInGoogle",
        "click #login-facebook" : "signInFacebook",
        "click #login-email" : "signInEmail",
        "click button.vsdemo" : "demoSoundSearch",
        "keypress input.vsdemo" : "dssOnEnter",
        "click span.vsdemoclear" : "clearSearchDemo"

    },

    signInGoogle: function () {
        var provider = new app.firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({prompt: "select_account"});
        this.signIn('google',provider);
    },

    signInFacebook: function () {
        var provider = new app.firebase.auth.FacebookAuthProvider();
        this.signIn('facebook',provider);
    },

    signInApple: function () {
        var provider = new app.firebase.auth.OAuthProvider('apple.com');
        this.signIn('apple',provider);
    },

    signInEmail: function(){

        var self = this;

        var continueUrl = app.platform=="browser" ? "https://" + window.location.host : "https://" + app.config.dynamicLinkDomain;
        var dynamicLinkDomain = window.location.host=="app.vybit.net" ? "p8a2d.app.goo.gl" : app.config.dynamicLinkDomain;

        console.log('continueUrl is ' + continueUrl);

        //attach params for service authentications
        if(app.serviceAuth && window.localStorage.getItem('serviceAuth')!=null && window.localStorage.getItem('state')!=null){
            continueUrl = continueUrl + '?client_id=' + window.localStorage.getItem('serviceAuth') + '&state=' + window.localStorage.getItem('state');
            if(window.localStorage.getItem('redirect_uri')!=null){
                continueUrl = continueUrl + '&redirect_uri=' + window.localStorage.getItem('redirect_uri');
            }
            console.log('continueUrl is');
            console.log(continueUrl);
        }

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: continueUrl,
            // This must be true.
            handleCodeInApp: true,
            iOS: {
                bundleId: 'com.vybit'
            },
            android: {
                packageName: 'com.vybit',
                installApp: true,
                minimumVersion: '12'
            },
            dynamicLinkDomain: dynamicLinkDomain
        };

        var prompt = 'Send Authentication Link to this Email Address:';

        var email = window.prompt(prompt);

        app.firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function() {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                document.querySelector('div.vloginmessage').innerHTML = "Sign In Link sent to<br/>" + email;

                //for email link inside ifttt app
                if(window.location.host=="app.vybit.net" && navigator.userAgent.match("(iPhone|iPad|Android).+Mobile")
                ){
                    self.processEmailLink(window.prompt('If signing in from the IFTTT app, copy and paste the Email Link HERE and click OK:'));
                }

            })
            .catch(function(error) {
                console.log('sendSignInLinkToEmail error:', error);
                alert('Invalid Email Address. Try Again.');
            });
    },

    signIn: function(pname, provider){

        var self = this;

        document.querySelector('div.vloginmessage').innerHTML = "";
        app.utils.startSpinner();

        if(app.platform=="app") cordova.plugins.backgroundMode.enable();
        var authTries = 1;
        if(window.sessionStorage.getItem("authInProgress")) authTries = (window.sessionStorage.getItem("authInProgress"))*1 + 1;
        window.sessionStorage.setItem("authInProgress", authTries);

        //if(app.platformId=="ios" && window.sessionStorage.getItem("authInProgress")*1==1) alert("NOTE: If you encounter a message reading \"Unable to process request . . .\" temporarily turn off\nSettings -> Safari -> Prevent Cross-Site Tracking\nand retry.");

        app.firebase.auth().signInWithRedirect(provider)
            .then(function () {

                return app.firebase.auth().getRedirectResult();

            })
            .then(function (result) {

                console.log("successfully authenticated with " + pname);
                console.dir(result);

                if(result.user!=null){
                    window.sessionStorage.removeItem("authInProgress");
                }else{
                    if(window.sessionStorage.getItem("authInProgress")*1==1){
                        console.log("user is null, retrying auth");
                        self.signIn(pname,provider);
                    }else{
                        window.sessionStorage.removeItem("authInProgress");
                    }
                }

            })
            .catch(function (error) {

            console.log('not authenticated with ' + pname);
            console.dir(error);
            window.sessionStorage.removeItem("authInProgress");
            var failmsg = 'Authentication Failed. Try Again.';
            if(app.platformId=="ios") failmsg = "Authentication Failed.\nIn the device settings temporarily turn off\nSettings -> Safari -> Prevent Cross-Site Tracking\nand retry Sign In."
            alert(failmsg);

            app.utils.stopSpinner();

        });
    },

    processEmailLink: function(emailLink){
        var url = new URL(emailLink);
        var link = url.searchParams.get("link");
        if(link) window.location.href = link;
    },

    demoSoundSearch: function(){ app.utils.demoSoundSearch(); },

    dssOnEnter: function(event){ app.utils.dssOnEnter(event); },

    clearSearchDemo: function(){
        window.localStorage.removeItem("demoSoundKey");
        window.localStorage.removeItem("demoSoundName");
        window.location.reload();
    }

});

module.exports = LoginPage;
