/**
 * @fileOverView
 * @description Sounds View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SoundAcquisitionTemplate = require('../../templates/widgets/soundacquisitiontemplate.html');



var app = require('ampersand-app');

var SoundAcquisitionView = AmpersandView.extend({

    template: SoundAcquisitionTemplate,

    render: function(){
        this.renderWithTemplate();
    },

    events: {

    },


});

module.exports = SoundAcquisitionView;
