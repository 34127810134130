/**
 * @fileOverView
 * @description Personsounds Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var VybPeep = require("../models/vybpeep");
var config = require("../etc/config");
var app = require('ampersand-app');

var VybPeeps = RestCollection.extend({

    model: VybPeep,

    indexes: ['key','name'],

    url: function(){
        var suffix = '/' + app.vyb.key;
        return config.restApiUrl + "/vybpeep" + suffix;
    },

    ajaxConfig: app.utils.setAuthTokenHeader

});

module.exports = VybPeeps;
