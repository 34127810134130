/**
 * @fileOverView
 * @description dummy model for a page header view if none other is specified<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var app = require('ampersand-app');

var AmpersandModel = require('ampersand-model');

var Header = AmpersandModel.extend({

    props: {
        "maintitle" : 'string',
        "noback"  : 'boolean',
        "login"   : 'boolean'
    },

    session: {
        editing: ['boolean', true, false],
        ignoreclick: ['boolean', true, false],
        mode: ['string',true,'day'],
        novadd: ['boolean',true,false],
        bypassSave: ['boolean',true,false]
    },

    derived: {

        headerleft: {
            deps: ["noback","mode","maintitle"],
            fn: function(){
                var content = "";
                if(!this.noback){
                    content = "";
                }else if(this.mode=="night" && this.maintitle=="Vybit Log"){
                    content = '<span class="vmode">&nbsp; &#9790; &nbsp;</span>';
                }else if(this.mode=="day" && this.maintitle=="Vybit Log"){
                    content = '<span class="vmode">&nbsp; &#9788; &nbsp;</span>';
                }
                return content;
            }
        },
        headerright: {
            deps: ["editing","bypassSave"],
            fn: function(){
                return this.editing && this.bypassSave==false ? "SAVE" : "";
            }
        },
        headerleftclass: {
            deps: ["editing","noback"],
           fn: function(){
                if(this.noback) return "";
                return this.editing ? "vcancel" : "vback";
            }
        },
        headerrightclass: {
            deps: ["editing","novadd"],
            fn: function(){
                if(this.novadd) return "";
                return this.editing ? "vdone" : this.login ? "" : "vadd";
            }
        }
    }

});

module.exports = Header;
