/**
 * @fileOverView
 * @description Event Log Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SubscriptionCardPageTemplate = require('../../templates/pages/subscriptioncardpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var app = require('ampersand-app');
var SubscriptionCardView = require("../components/subscriptioncard");

var SubscriptionCardPageView = AmpersandView.extend({

    template : SubscriptionCardPageTemplate,

    pageName: "SubscriptionCardPage",

    initialize: function(options){
      if(options.model!=undefined) this.model = options.model;

        if(window.location.host!=app.config.wwwHost){
            this.subviews.mpfooter = {
                selector : 'footer',
                    prepareView: function(el) {
                    return new FooterView({
                        el: el,
                        parent: this
                    });
                }
            };
        }

        this._initializeSubviews();
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybit Subscription',
                    login: true,
                    noback: app.auth.authenticated ? false : true
                });
            }
        },

        subscription: {
            selector : '#content',
            prepareView: function(el) {

                var params = {
                   el: el,
                   parent: this
                    };
                if(this.model!=undefined) params.model = this.model;

                return new SubscriptionCardView(params);
            }
        }
    }

});

module.exports = SubscriptionCardPageView;
