/**
 * @fileOverView
 * @description Vyb Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");
var integrationLinksTemplate = require('../templates/widgets/integrations.html');
var moderationTemplate = require('../templates/components/moderation.html');
var ScheduleView = require('../views/components/schedule');
var GeofenceView = require('../views/components/geofence');


var Vyb = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/vyb";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        name : 'string',
        description: 'string',
        dtlastcheck: 'string',
        frequency: 'number',
        type: 'string',
        status: 'string',
        pollUri: 'string',
        soundKey: 'string',
        triggerKey: 'string',
        subscriptionKey: 'string',
        access: 'string',
        numberFollowers: 'number',
        imageUrl: 'string',
        linkUrl: 'string',
        message: 'string',
        geofence: 'object',
        moderationStatus: 'string',
        moderationMessage: 'string',
        triggerType: 'string',
        triggerSettings: 'object'
    },

    session: {
        editing: ['boolean', true, false],
        testing: ['boolean',true,false],
        diagnostics: ['object','true'],
        followersChanged : ['boolean',true,false],
        pinging: ['boolean',true,false]
    },

    derived: {

        partialDescription: {
            deps: ['description'],
            fn: function () {
                var desc = this.description.substr(0,128);
                if(this.description.length>128) desc += " . . . ";
                return desc;
            }
        },
        onOff:{
            deps: ['status'],
            fn: function(){
                return this.status=='on' ? true: false;
            }
        },
        switchKeyInput:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },
        switchKeyLabel:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },
        displayFrequency:{
            deps: ['frequency'],
            fn: function(){
                var freq = this.frequency;
                var map = {
                    "5" : "5 minutes",
                    "15" : "15 minutes",
                    "30" : "30 minutes",
                    "60" : "1 hour",
                    "120" : "2 hours",
                    "240" : "4 hours",
                    "480" : "8 hours",
                    "1440" : "1 day",
                    "10080" : "1 week"
                };
                return map[freq];
            }
        },

        displayFollowers:{
            deps: ['numberFollowers'],
            fn: function(){
                var msg = null;
                if(this.numberFollowers==1) msg = '1 peep is following this vybit';
                if(this.numberFollowers>1) msg = this.numberFollowers + ' peeps are following this vybit';
                return msg;
            }
        },

        eventUri: {
            deps: ['type','triggerKey'],
            fn: function(){
                return app.config.baseApiUrl + '/trigger/' + this.triggerKey;
            }
        },
        subscriptionLinks: {
            deps: ['subscriptionKey','access'],
            fn: function(){
                var directLink =  app.config.baseApiUrl + '/subscribe/' + this.subscriptionKey;
                var cardLink = app.config.wwwHost + '/subscription/' + this.subscriptionKey;

                var html = '<div><span class="vslabel">DIRECT: </span><span id="directLink">'+directLink+'</span></div>';

                html = html + '<div><span class="vslabel">CARD: &nbsp; </span><span id="cardLink"><a href="http://'+cardLink+'" target="_blank" onclick="app.utils.cancelBubble()">https://' + cardLink + '</a></span></div>';

                return html;
            }
        },

        subscriptionCopyButtons: {
            deps : ['access'],
            fn: function(){
                var buttonDiv = '<button class="btn btn-sm btn-info vcopybutton" data-clipboard-target="#directLink">COPY</button>';
                buttonDiv += '<button class="btn btn-sm btn-info vcopybutton" data-clipboard-target="#cardLink">COPY</button>\n';

                return buttonDiv;
            }
        },

        docCreateLink: {
            deps: ['key'],
            fn: ()=> app.vyb.docLink('create')
        },

        docTriggerLink: {
            deps: ['key'],
            fn: ()=> app.vyb.docLink('trigger')
        },

        docSubscriptionLink: {
            deps: ['key'],
            fn: ()=> app.vyb.docLink('sharing')
        },

        headerleft: {
            deps: ["editing"],
            fn: function(){
                return this.editing ? "" : "";
            }
        },
        headerright: {
            deps: ["editing"],
            fn: function(){
                return this.editing ? "" : "";
            }
        },
        headerleftclass: {
            deps: ["editing"],
            fn: function(){
                return this.editing ? "vback" : "vback";
            }
        },
        headerrightclass: {
            deps: ["editing"],
            fn: function(){
                return this.editing ? "" : "";
            }
        },
        displayDiagnostics: {
            deps: ["diagnostics"],
            fn: function(){
                var diagnostics = app.YAML.stringify(this.diagnostics);
                return diagnostics.toString().replace(/response/,"<br/>response");
            }
        },
        maintitle: {
            fn: function(){
                return 'Vyb';
            }
        },

        soundName: {
            deps: ['soundKey'],
            fn: function(){

                var sound = null;

                if(app.usedSounds.get(this.soundKey)!=undefined){
                    sound = app.usedSounds.get(this.soundKey);
                }else if(app.sounds.get(this.soundKey)!=undefined){
                    sound = app.sounds.get(this.soundKey);
                }

                soundInfo = sound!=null ? sound.name : "";

                if(sound!=null && sound.status!=undefined && sound.status=="private"){
                    soundInfo += ' <span class="vcredit">private: ' + sound.meta.source + ' ' + sound.originalUri +'</span>';

                }else if(sound!=null && sound.meta!=null && sound.meta.source!=null){
                    soundInfo += ' &nbsp; <span class="vcredit">from <a class="vcreator" target="_new" href="'+sound.meta.source+'" onclick="event.stopImmediatePropagation()">freesound.org</a></span>';
                }

                return soundInfo;
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        },

        integrationLinks: {
            deps: ['key'],
            fn: function(){
                return integrationLinksTemplate();
            }
        },

        moderationShow: {
            deps: ['access'],
            fn: function(){
                if(this.access=="public") return true;
                else return false;
            }
        },

        moderationDisplay: {
            deps: ['moderationStatus','access'],
            fn: function(){
                if(this.access==undefined || this.moderationStatus==undefined) return '';
                var modEl = app.utils.createDomElementFromString(moderationTemplate());
                var status = this.moderationStatus;
                if(status=='review') status = 'Under Review';

                if(this.moderationStatus!="editing") modEl.querySelector('button.vmod_button').remove();

                var moderationMessageDisplay = this.moderationMessage || 'notes following review will appear here.';

                if(app.profile.type=="admin"){
                    modEl.querySelector("button.btn-primary").classList.remove("vhide");
                    modEl.querySelector("button.btn-danger").classList.remove("vhide");
                    moderationMessageDisplay = '<textarea name="moderationMessage" class="vtextarea">'+ this.moderationMessage +'</textarea>';
                }

                modEl.querySelector('span.vmod_modstatus').textContent = status;
                modEl.querySelector('div.vmod_message').innerHTML = '<b>REVIEW NOTES:</b> ' + moderationMessageDisplay;
                return modEl.outerHTML;
            }
        },

        ownerShip: {
            deps: ['access','moderationStatus'],
            fn: function(){
                if(this.access=="public"){
                    var vstatus = "vreviewing";
                    if(this.moderationStatus=="approved") vstatus = "vapproved";
                    if(this.moderationStatus=="rejected") vstatus = "vrejected";
                    var peeps = this.numberFollowers>1 ? "peeps" : "peep";
                    return '<span class="vpublic '+ vstatus +'">PUBLIC &nbsp; </span>' +
                        '<span class="vpeeps">'+ this.numberFollowers +' peeps</span>';

                }else if(this.access=="unlisted"){
                    return '<span class="vunlisted">UNLISTED &nbsp; </span>' +
                        '<span class="vpeeps">'+ this.numberFollowers +' peeps</span>';

                }else if(this.access=="private" && this.numberFollowers>0){
                    return '<span class="vprivate">PRIVATE &nbsp; </span>' +
                        '<span class="vpeeps">'+ this.numberFollowers +' peeps</span>';
                }
                return '';
            }
        },

        hideSettings: {
            deps: ['key'],
            fn: function(){
                if(this.key==undefined) return true;
                else return false;
            }
        },

        schedule: {
            deps: ['triggerType','triggerSettings'],
            fn: function(){
                var schedule = null;
                if(this.triggerType!='schedule') return '';
                const clickHereSpan = '<span class="vset">click here to set</span>'
                if(this.triggerSettings==null
                    || this.triggerSettings.crons==undefined
                    || this.triggerSettings.crons[0]==undefined) return '<div class="vplaceholder">'+clickHereSpan+'</div>';
                else schedule = this.triggerSettings.crons[0].cron; //TODO, add support for array of crons
                var scheduleArray = schedule.split(" ");

                let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                let monthsArray = scheduleArray[3].split(",");
                monthsArray = monthsArray.map(month=> months[month] );
                monthsDisplay = monthsArray.join(" ");

                let dow = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
                let dowArray = scheduleArray[4].split(",");
                dowArray = dowArray.map(day=> dow[day] );
                dowDisplay = dowArray.join(" ");

                var scheduleView = new ScheduleView({
                    cron_min: scheduleArray[0],
                    cron_hour: scheduleArray[1]=="*" ? 'all' : scheduleArray[1],
                    cron_dom: scheduleArray[2]=="*" ? 'all' : scheduleArray[2],
                    cron_month: scheduleArray[3]=="*" ? 'all' : monthsDisplay,
                    cron_dow: scheduleArray[4]=="*" ? 'all' : dowDisplay,
                    cron_timezone: this.triggerSettings.crons[0].timeZone
                });
                scheduleView.render();
                return scheduleView.el.outerHTML;
            }
        },

        geofenceDisplay: {
            deps: ['triggerType','geofence'],
            fn: function(){
                if(this.triggerType!='geofence') return '';
                const clickHereSpan = '<span class="vset">click here to set</span>'
                if(this.geofence==null) return '<div class="vplaceholder">'+clickHereSpan+'</div>';

                var mapUri = `https://google.com/maps/?q=${this.geofence.lat},${this.geofence.lon}`;
                var mapLink = `<a onclick="app.utils.cancelBubble()" href="${mapUri}" target="_blank">map link</a>`;

                var geofenceView = new GeofenceView({
                    glocation: `${this.geofence.lat}, ${this.geofence.lon}`,
                    gmap: mapLink,
                    gradius: this.geofence.displayRadius,
                    gradius_units: this.geofence.radiusUnits || 'meters',
                    gtype: `${this.geofence.type || 'enter'}ing`,
                    gtime: `${this.geofence.timeThrottle} hours`,
                    gsub: `${this.geofence.subscribable==undefined || this.geofence.subscribable=="no" ? 'only my devices' : 'allow subscriber devices'}`
                });
                geofenceView.render();
                return geofenceView.el.outerHTML;
            }
        },

    },

    patch: function(data,paramModel=null){

        var key = Object.keys(data)[0];
        var value = data[key];

        //strip html
        if(typeof(value)=="string") value = value.replace( /(<([^>]+)>)/ig, '');

        //prepend urls with https if needed
        if(key.match("Url") && value.length>0){
            value = value.match(/^http(s|):\/\//) ? value : "https://" + value;
        }

        data[key] = value;

        //validation
        if(!this.validated(key, value)) return;

        var newVyb = key=="name" && this.key==undefined ? true : false;
        var patch = newVyb ? false : true;

        //moderation related
        if(!newVyb && key.match(/^(name|description|message|imageUrl|linkUrl|soundKey)$/)){
            this.set('moderationStatus','editing');
            this.set('moderationMessage','');
            data['moderationStatus'] = this.moderationStatus;
            data['moderationMessage'] = this.moderationMessage;
        }

        if(key=="soundKey" && (this.soundKey!=undefined && this.soundKey.length>0)){
            var soundKey = key=="soundKey" ? value : this.soundKey;
            var sound = app.sounds.get(soundKey,'key');
            if(sound!=undefined){
                app.fsutils.soundSetUp(sound);
                app.usedSounds.add(sound);
            }else{
                sound = new app.Sound({key:soundKey});
                sound.fetch({
                    success: function(model,response){
                        app.fsutils.soundSetUp(sound);
                        app.usedSounds.add(sound);
                    },
                    error: function(error){
                        console.log('error loading sound ' + soundKey);
                    }
                });
            }
        }

        this.save(data,{
            patch: patch,
            wait: true,
            success: function(model,response){
                app.header.set('editing',false);
                if(newVyb){
                    app.vybs.add(model,{at:0});
                    app.loadMeter();
                }
                if(paramModel!=null){
                    paramModel.set('editing',false);
                }
                if(app.sounds.get(model.soundKey)){
                    var sound = app.sounds.get(model.soundKey);
                    sound.set('toggle',sound.toggle ? false:true);
                }

                //handle vyb name change
                if(data.name!=undefined) app.header.set('maintitle',data.name);

                //refresh geofences if modified
                if(app.vyb.triggerType=="geofence"){
                    if(data.triggerType!=undefined || data.geofence!=undefined) app.glutils.manage(); 
                }
            },
            error: function(model,response,options){
                console.log('error');
                if(response.statusCode==400 && response.body.error == "duplicate_name") alert('Vybit name "'+data.name+'" is invalid or already exists.');
                else if(response.statusCode==403  && response.body.error == "vybit_limit_reached") alert('Vybit limit reached. Visit my.vybit.net to increase account limit.')
                if(paramModel!=null) paramModel.set('editing',false);
            }
        });
    },

    validated: (key, value) => {

        //urls
        if(key.match("Url") && value.length>0 && !app.utils.isValidURL(value)){
            alert("Invalid URL");
            return false;
        }

        return true;
    },

    docLink: (section) => {
            return '<a href="http://www.vybit.net#'+ section +'" target="_blank" onclick="app.utils.cancelBubble()">'+ app.utils.docSectionTitleMap(section)+'</a>';
    }
});

module.exports = Vyb;
