/**
 * @fileOverView
 * @description Profile Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var Profile = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/profile";
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        name : 'string',
        email: 'string',
        provider: 'string',
        type: 'string',
        permissions: 'object',
        tier_id: 'number'
    },

    session: {
        version: ['string',true,app.version]
    },

    derived: {
        admin: {
            deps: ['type'],
            fn: function(){
                return this.type=='admin' ?
                    '<div class="vdesc vpointer sound-acq">SOUND ACQUISITION</div><br/>' +
                    '<div class="vdesc vpointer event-log">EVENT LOG</div>'
                    : '';
            }
        },

        switchKeyInput:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },
        switchKeyLabel:{
            deps: ['key'],
            fn: function(){
                return "switch-" + this.key;
            }
        },

        providerDisplay: {
            deps: ['provider'],
            fn: function(){
                return this.provider=="password" ? "email link" : this.provider;
            }
        }

    },

    getHour: function(hour){
        var value = "";
        if(hour==0) value = "12 am";
        else if(hour==1) value = "1 am";
        else if(hour==2) value = "2 am";
        else if(hour==3) value = "3 am";
        else if(hour==4) value = "4 am";
        else if(hour==5) value = "5 am";
        else if(hour==6) value = "6 am";
        else if(hour==7) value = "7 am";
        else if(hour==8) value = "8 am";
        else if(hour==9) value = "9 am";
        else if(hour==10) value = "10 am";
        else if(hour==11) value = "11 am";
        else if(hour==12) value = "12 pm";
        else if(hour==13) value = "1 pm";
        else if(hour==14) value = "2 pm";
        else if(hour==15) value = "3 pm";
        else if(hour==16) value = "4 pm";
        else if(hour==17) value = "5 pm";
        else if(hour==18) value = "6 pm";
        else if(hour==19) value = "7 pm";
        else if(hour==20) value = "8 pm";
        else if(hour==21) value = "9 pm";
        else if(hour==22) value = "10 pm";
        else if(hour==23) value = "11 pm";

        return value;
    },

    patch: function(data,formView=null){
        var self = this;

        this.save(data,{
            patch: true,
            wait: true,
            success: function(model,response){
                app.header.set('editing',false);
                if(formView!=null) formView.set('editing',false);
            },
            error: function(model,response,options){
                console.log('error saving profile name:', response.body);

                if(response.statusCode==422 || response.statusCode==409){
                    alert('Profile Name "'+data.name+'" is invalid or already exists.');
                    formView.set('editing',false);
                }
            }
        })
    }

});

module.exports = Profile;
