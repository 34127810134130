/**
 * @fileOverView
 * @description Event Log Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SubscriptionPageTemplate = require('../../templates/pages/subscriptionpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var app = require('ampersand-app');
var Subscription = require("../../models/subscription");

var SubscriptionPageView = AmpersandView.extend({

    template : SubscriptionPageTemplate,

    pageName: "SubscriptionPage",

    props: {
      cancelLink: 'string'
    },

    initialize: function(){
        var self = this;
        this.cancelLink = "https://" + app.config.wwwHost;
        this.sk = app.queryParams.sk;
        this.model = new Subscription();
        this.model.set('key',app.queryParams.sk);
        this.model.fetch({
            success: function(model,resposonse){
                console.log('retrieved subscription ' + model.key);
            },
            error: function(model,response){
                console.log('error fetching subscription ' + model.key);
            }
        });
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybit Subscription',
                    noback: true,
                    login: true
                });
            }
        }
    },

    bindings: {
        "model.name" : {
            "type" : "text",
            "hook" : "subscriptionName"
        },
        "model.ownerName" : {
            "type" : "text",
            "hook" : "owner"
        },
        "model.link" : {
            "type" : "attribute",
            "name" : "href",
            "hook" : "subscribe"
        },
        "cancelLink" : {
            "type" : "attribute",
            "name" : "href",
            "hook" : "cancel"
        }
    }

});

module.exports = SubscriptionPageView;
