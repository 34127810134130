/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var PeepsPageTemplate = require('../../templates/pages/peepspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var VybPeepsView = require('../widgets/vybpeepsview');
var SearchView = require('../components/search');
var InviteView = require('../components/invite');
var app = require('ampersand-app');

var PeepsPageView = AmpersandView.extend({

    template : PeepsPageTemplate,

    pageName: "PeepsPage",

    initialize: function(options){
        this.maintitle = "Peeps following this Vybit";
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: this.maintitle
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        peeps: {
            selector : '#content',
            prepareView: function(el) {
                return new VybPeepsView({
                    el: el,
                    parent: this,
                });
            }
        },

        search: {
            selector : 'div.vsearchtable',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'vybPeeps'
                });
            }
        },

        invite: {
            selector : 'div.vinvitetable',
            prepareView: function(el) {
                return new InviteView({
                    el: el,
                    parent: this,
                });
            }
        }
    }
});

module.exports = PeepsPageView;
