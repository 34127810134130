/**
 * @fileOverView
 * @description Peep Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var VybPeep = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        var url =  app.config.restApiUrl + "/vybpeep";
        return url;
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        email: 'string',
        name : 'string',
        accessStatus: 'string',
        vybKey: 'string',
        updatedAt: 'string'
    },

    derived: {

        statusDisplay: {
            deps: ['accessStatus'],
            fn: function(){
                if(this.accessStatus=="invited") return '<span class="vpending">invited</span>';
                else return ' &nbsp; &nbsp ';
            }
        }

    }
});

module.exports = VybPeep;
