/**
 * @fileOverView
 * @description Header View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SearchTemplate = require('../../templates/components/search.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var SearchView = AmpersandView.extend({

    props: {
        searchQuery: 'string',
        pinging: ['boolean',true,false],
        entity: 'string'
    },

    derived: {
        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        },

        placeHolderDisplay: {
            deps: ['entity'],
            fn: function(){
                var entity = this.entity;
                let display_entity = this.entity;
                if(entity=="vybs") display_entity = "vybits"
                if(entity=="vybPeeps") display_entity = "peeps";
                if(entity=="logs") display_entity = "log";
                if(entity=="vybsfollowing") display_entity = "following";
                return '    search ' + display_entity;
            }
        },

        searchId: {
            deps: ['entity'],
            fn: function(){
                return "vsearch-" + this.entity;
            }
        }

    },

    template : SearchTemplate,

    initialize: function(options) {
        this.entity = options.entity;
        this.lastLoad = Date.now();
    },

    render: function(){

        if(app[this.entity]!=undefined) this.set('searchQuery',app[this.entity].searchQuery);
        this.renderWithTemplate(this);

        this.query("span.vsearchclear").addEventListener("mousedown",function(event) {
            event.preventDefault();
        });

        return this;
    },

    bindings: {
        "searchQuery" : {
            type: 'value',
            selector: 'input.vsearchinput'
        },

        "shakeElement" : {
            type: "booleanClass",
            name: "vybshake",
            selector: "img.vsearchimg"
        },

        "placeHolderDisplay": {
            type: "attribute",
            name: "placeholder",
            selector: "input.vsearchinput"
        },

        "searchId": {
            type: "attribute",
            name: "id",
            selector: "input.vsearchinput"
        }
    },

    events: {
        "keypress input.vsearchinput" : "keyPressSearch",
        "click span.vsearchrefresh" : "searchEntity",
        "click span.vsearchclear" : "clearSearch",
        "click .vsearchimg" : "searchFocus",
        "blur input.vsearchinput" : "searchEntity"
    },

    searchFocus: function(){
        document.querySelector("input.vsearchinput").focus();
    },

    keyPressSearch: function(event){
        if(event.keyCode==13){
            this.searchEntity();
        }
    },

    searchEntity : function(){

        //throttle
        var wait = 1000;
        var now = Date.now();
        var diff = now - this.lastLoad;
        if(diff < wait){
            return;
        }else{
            this.lastLoad = now;
        }

        app.utils.startSpinner();
        this.set('pinging',true);
        var self = this;
        var input = this.query("#vsearch-" + this.entity);
        var query = input.value;
        this.set('searchQuery',query);
        console.log('search ' + this.entity + ' for ' + query);
        app[this.entity].searchQuery = query;
        input.blur();
        if(this.entity=='sounds'){
            app.sounds.curLength = 0;
            app.sounds.lastLength = 0;
            app.soundCandidates.reset();
            app.soundCandidates.page = 1;
            app.soundCandidates.next = 1;
            app.sounds.appendLock = true;
        }
        app[this.entity].fetch({
            data:{query:encodeURI(query)},
            reset: true,
            wait: true,
            success: function(collection,response,options){
                if(self.parent.mode!=undefined){
                    collection.each(function(entity){
                        entity.mode = self.parent.mode;
                        if(self.entity=="logs"){
                            if(entity.diagnostics.soundKey!=undefined && entity.diagnostics.soundFile!=undefined){
                                app.fsutils.loadSoundFromFile(entity.diagnostics.soundKey, entity.diagnostics.soundFile);
                            }else if(entity.diagnostics.soundKey!=undefined){
                                app.fsutils.loadSoundFromKey(entity.diagnostics.soundKey);
                            }
                        }
                    });
                }
                if(document.querySelector('div.vscrollfull')) document.querySelector('div.vscrollfull').scrollTop = 0;
                if(self.entity=="sounds") app.sounds.appendLock = false;
                if(self.entity=="logs") app.vybsfollowing.fetchAndUpdate();
                self.set('pinging',false);
                app.utils.stopSpinner();
                console.log('loaded latest ' + self.entity);

                //for empty results
                if(self.entity!="sounds" && app[self.entity].models.length==0) document.querySelector("div.vtable").innerHTML = '<div class="vnoresults">no results</div>';
                else if(document.querySelector("div.vnoresults")!=null) document.querySelector("div.vnoresults").remove();
            },
            error: function(){
                self.set('pinging',false);
                app.utils.stopSpinner();
            }
        });
    },

    clearSearch : function(){
        console.log('clearing search');
        var input = this.query("#vsearch-" + this.entity);
        input.focus();
        this.set('searchQuery',"");
        input.value = "";
        app.utils.stopSpinner();
    }
});

module.exports = SearchView;
