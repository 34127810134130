/**
 * @fileOverView
 * @description Vybit View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybTemplate = require('../../templates/widgets/vybtemplate.html');
var config = require("../../etc/config");
//var OnOffSwitch = require("../../views/components/onoff");
var VybPageView = require("../pages/vybpage");
var VybFollowingPageView = require("../pages/vybfollowingpage");
var app = require('ampersand-app');


var VybView = AmpersandView.extend({
    template: VybTemplate,

    bindings: {
        "model.name": {
            type: 'text',
            hook: 'name'
        },
        "model.partialDescription": {
            type: 'text',
            hook: 'partialDescription'
        },

        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        },

        "model.ownerShip" : {
            type: "innerHTML",
            "hook" : "ownership"
        }
    },

    /* subviews: {
        onoff: {
            selector : 'div.vstatusOff',
            prepareView: function(el) {
                return new OnOffSwitch({
                    el: el,
                    parent: this,
                    model: this.model
                });
            }
        }
    }, */

    events: {
        "click .vybclick, div.vname" : "switchToVybPage",
        "click .vdeltaimg, vdeltaclick" : "playSound",
    },

    switchToVybPage: function(){
        app.utils.switchToVybPage(this.model);
    },

    playSound: function(){
        this.model.set('pinging',true);
        app.soundViews[this.model.soundKey] = this.model;
        app.utils.playSound(this.model.soundKey);
    }

});

module.exports = VybView;
