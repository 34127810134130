/**
 * @fileOverView
 * @description Event Log Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var EventLogPageTemplate = require('../../templates/pages/eventlogpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var EventLogView = require('../widgets/eventlogview');
var app = require('ampersand-app');

var EventLogPageView = AmpersandView.extend({

    template : EventLogPageTemplate,

    pageName: "EventLogPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Event Log'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        eventlog: {
            selector : '#content',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new EventLogView({
                    el: el,
                    parent: this
                });
            }
        }
    },

    events: {
        "click #refresh" : "refreshEventLog",
        "click #clear" : "clearEventLog"
    },

    refreshEventLog: function(){
        console.log('refresh');
        var records = [...app.eventLog.records];
        document.getElementById("content").innerHTML = records.reverse().join('<br/><br/>\n');
        delete records;
    },

    clearEventLog: function(){
        console.log('clear');
        app.eventLog.clear();
        this.refreshEventLog();
    }

});

module.exports = EventLogPageView;
