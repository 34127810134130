/**
 * @fileOverView
 * @description Profile View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var ProfileTemplate = require('../../templates/widgets/profiletemplate.html');
var Param = require("../../models/param");
var ParamView = require("./param");
var MeterView = require("./meterview");
var app = require('ampersand-app');

var ProfileView = AmpersandView.extend({
    template: ProfileTemplate,

    initialize: function (options) {
        this.model = app.profile;

        this.nameParam = new Param({
            "entity" : "profile",
            "name" : "name",
            "label" : "NAME",
            "inputType" : "text",
            "template" : "TextTemplate",
            "placeholder" : "your public Vybit handle"
        });
    },

    subviews: {
        name: {
            hook : 'editname',
            prepareView: function(el) {
                return new ParamView({
                    el: el,
                    parent: this,
                    model: this.nameParam
                });
            }
        },

        meter: {
            hook : 'meter',
            prepareView: function(el) {
                return new MeterView({
                    el: el,
                    parent: this,
                    model: app.meter
                });
            }
        }
    },

    bindings: {
        "model.name": {
            type: 'text',
            hook: 'name'
        },
        "model.email": {
            type: 'text',
            hook: 'email'
        },
        "model.providerDisplay": {
            type: 'text',
            hook: 'provider'
        },
        "model.version": {
            type: 'text',
            hook: 'version'
        },
        "model.admin" : {
            type: 'innerHTML',
            hook: 'admin'
        }
    },

    events: {
        "click .sound-acq" : "switchToSACQPage",
        "click .event-log" : "switchToEventLogPage",
        "click .vyblogout" : "logout",
        "click [data-hook='docs']" : "switchToDocsPage",
        "click button.vbtndelete" : "deleteAccount"
    },

    switchToSACQPage: function(){
        var view =   new app.SoundAcquisitionPageView({viewOptions: {mode:'lib'}});
        app.pageSwitcher.set(view);
    },

    switchToEventLogPage: function(){
        var view =   new app.EventLogPageView();
        app.pageSwitcher.set(view);
    },

    switchToDocsPage: function(){
        var view =   new app.DocsPageView();
        app.pageSwitcher.set(view);
    },

    logout: function () {
        app.auth.signOut();
    },

    deleteAccount: function(){
        if(confirm('Are you sure you want to permanently delete your Vybit account including all Vybits and Logs?')){
            app.auth.deleteAccount();
        }
    }

});

module.exports = ProfileView;
