/**
 * @fileOverView
 * @description Vyb Collection<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var RestCollection = require('ampersand-rest-collection');
var VybFollowing = require('../models/vybfollowing');
var config = require("../etc/config");
var app = require('ampersand-app');

var VybsFollowing = RestCollection.extend({

    initialize : function(){
        this.personKey = config.key;
    },

    model:app.VybFollowing,

    indexes: ['key','soundKey','vybKey','subscriptionKey'],

    url: function(){ return config.restApiUrl + "/vybfollows"; },

    ajaxConfig: app.utils.setAuthTokenHeader,

    fetchAndUpdate: function(){
        this.fetch({
            success: function(collection,response){
                for(i=0;i<collection.models.length;i++){
                    var vf = collection.models[i];
                    if(vf.soundKey!=undefined){
                        app.usedSounds.addSoundFromKey(vf.soundKey);
                    }
                }
            },
            error: function(){
                console.log('error retrieving vybfollows from fetchAndUpdate');
            }
        });
    },

    triggerAlert: function(fk){
        if(this.get(fk,'key')!=undefined) this.get(fk,'key').triggerAlert();
        else{
            var vybsfollowing = this;
            var vybfollowing = new VybFollowing();
            vybfollowing.key = fk;
            vybfollowing.fetch({
                success: function(model,response){
                    vybsfollowing.add(model);
                    model.triggerAlert();
                },
                error: function(model,response){
                    console.log('could not retrieve vybfollowing ' + fk);
                }
            });
        }
    }

});

module.exports = VybsFollowing;
