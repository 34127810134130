/**
 * @fileOverView
 * @description Peep Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var Peep = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/peep";
    },

    props: {
        key : 'string',
        name : 'string',
        email: 'string',
        status: 'string',
        vybKey: 'string'
    },

    session: {
        mode: ['string',true,'peeps'],
        following: ['boolean',true,false]
    },

    derived: {

        followingDisplay: {
            deps: ['following','mode'],
            fn: function(){
                console.log('peep!');
                if(this.mode=="peeps") return "";
                if(this.following && app.vyb.followers.get(this.key,'peepKey')!=undefined) return '&#10004;';
                else return ' &nbsp; ';
            }
        },

        statusDisplay: {
            deps: ['status'],
            fn: function(){
                if(this.status=="sent") return '<span class="vpending">pending</span>';
                else if(this.status=="received") return  '<span class="vaccept">ACCEPT</span>';
                else return ' &nbsp; &nbsp ';
            }
        },

        removeDisplay: {
            deps: ['mode'],
            fn: function(){
                return this.mode=="peeps" ? "&#10005;" : "";
            }
        }

    }
});

module.exports = Peep;
