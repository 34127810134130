/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SoundsPageTemplate = require('../../templates/pages/demosoundspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var SoundsView = require('../widgets/soundsview');
var SearchView = require('../components/search');
var app = require('ampersand-app');

var DemoSoundsPageView = AmpersandView.extend({

    template : SoundsPageTemplate,

    initialize: function(options){
        this.mode = options.mode ? options.mode : "sounds";
        this.maintitle = "Find a Sound for Your First Vybit!";
    },


    pageName: "DemoSoundsPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: this.maintitle,
                    noback: true,
                    novadd: true
                });
            }
        },

        /* mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        }, */

        sounds: {
            selector : '#content',
            prepareView: function(el) {
                return new SoundsView({
                    el: el,
                    parent: this,
                    mode: this.mode
                });
            }
        },

        search: {
            selector : 'div.vsearchtable',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'sounds'
                });
            }
        }
    }
});

module.exports = DemoSoundsPageView;
