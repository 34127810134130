/**
 * @fileOverView
 * @description Header View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var SubscriptionCardTemplate = require('../../templates/components/subscriptionCard.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var SubscriptionCardView = AmpersandView.extend({

    autoRender: false,

    template : SubscriptionCardTemplate,

    initialize: function(){
        var self = this;

        if(this.model==undefined){
            this.model = new app.Subscription();
            this.model.set('description','');

            var sk = app.queryParams.scard!=undefined ? app.queryParams.scard : window.location.pathname.match(/[a-z0-9]{16}$/)[0];

            this.model.set('key',sk);
            this.model.fetch({
                wait: true,
                success: function(model,response){
                    self.render();
                    console.log('sucessfully retrieved subscription ' + model.key);
                },
                error: function(){
                    console.log('error retrieving subscription ' + sk);
                }
            })
        }
;
    },

    bindings: {
        "model.name" : {
            "hook" : "vsubname",
            "type" : "text"
        },
        "model.description" : {
            "hook" : "vsubdesc",
            "type" : "innerHTML"
        },
        "model.soundKey" : {
            "hook" : "soundKey",
            "type" : "attribute",
            "name" : "data-soundKey"
        },
        "model.key" : {
            "hook" : "vsubkey",
            "type" : "attribute",
            "name" : "data-vybsubkey"
        },
        "model.imgLink" : {
            "hook" : "vsubimg",
            "type" : "innerHTML"
        },
        "model.subscriptionButton" : {
            "hook" : "vsubkey",
            "type" : "innerHTML"
        },
        "model.qrCode" : {
            "hook" : "vqrcode",
            "type" : "innerHTML"
        },
    },

    events: {
        "click button.vsublisten" : "playSound",
        "click button.vsubscribe" : "subscribeToVyb"
    },

    playSound: function(){
        app.utils.playSoundFile(this.model.soundFile,this.model.soundKey);
    },

    subscribeToVyb: function(event){
        var el = event.target;
        el = el.closest('[data-vybsubkey]');
        if(el.getAttribute('data-vybsubkey')){
            app.utils.subscribeToVybFromKey(el.getAttribute('data-vybsubkey'));
        }else{
            console.log('no subscription key found');
        }
    }

});

module.exports = SubscriptionCardView;
