/**
 * @fileOverView
 * @description Event Log View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var EventLogTemplate = require('../../templates/widgets/eventlogtemplate.html');
var app = require('ampersand-app');

var EventLogView = AmpersandView.extend({

    template: function(){ return '<div id="content" class="vscrollfull animated vcontent veventlog">\n' +
    [...app.eventLog.records].reverse().join('<br/><br/>\n') +
    '    </div>';},

    render: function(){
        this.renderWithTemplate();
    }

})

module.exports = EventLogView;
