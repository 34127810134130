/**
 * @fileOverView
 * @description dummy model for a page header view if none other is specified<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var app = require('ampersand-app');

var AmpersandModel = require('ampersand-model');
var demoSearchTemplate = require('../templates/components/demosearch.html');

var Login = AmpersandModel.extend({

    session: {
        "maintitle": ['string',true,'Welcome to Vybit!'],
        "showGettingStarted" : ['boolean',true,true],
        "showStoreLinks" : ['boolean', true, true],
        "loginMessage" : ['string',true,'']
    },

    derived: {
        displayGettingStarted: {
            deps: ['showGettingStarted'],
            fn: function(){
                if(this.showGettingStarted){
                    return '<a href="http://www.vybit.net" target="_new">Getting Started</a>';
                }else{
                    return "";
                }
            }
        },

        displayLoginMessage: {
            deps: ['loginMessage'],
            fn: function() {

                //preset
                if(window.localStorage.getItem('loginMessage')!=null){
                    this.loginMessage = window.localStorage.getItem('loginMessage');
                    window.localStorage.removeItem('loginMessage');

                //default for browser
                }else if(app.platform=="browser" && this.loginMessage=="" && app.queryParams.client_id==undefined && app.queryParams.sk==undefined){

                    this.loginMessage = demoSearchTemplate();

                //service auth
                }else if(app.queryParams.client_id!=undefined && app.queryParams.client_id.length>0){
                    let serviceName = app.config.serviceAuth[app.queryParams.client_id]?.displayName ? app.config.serviceAuth[app.queryParams.client_id]?.displayName : app.queryParams.client_id;
                    this.loginMessage = "Sign In to allow " + serviceName.toUpperCase() + " access to your Vybit account."

                }

                return this.loginMessage;
            }
        },

        displayStoreLinks: {
            deps: ['showStoreLinks'],
            fn: function(){
                if(this.showStoreLinks){
                    return '    <div class="vstorelinks vweb">\n' +
                        '        <span>\n' +
                        '                            <a target="_ios" href="https://apps.apple.com/us/app/vybit/id1519874653">\n' +
                        '                                <img src="images/40px-Download_on_the_App_Store_Badge.png"/>\n' +
                        '                            </a>\n' +
                        '                        </span> &nbsp;\n' +
                        '        <span>\n' +
                        '                            <a target="_android" href="https://play.google.com/store/apps/details?id=com.vybit">\n' +
                        '                                <img src="images/googleplay.png"/>\n' +
                        '                            </a>\n' +
                        '                        </span>\n' +
                        '    </div>';
                }else{
                    return "";
                }
            }
        }
    },



});

module.exports = Login;
