/**
 * @fileOverView
 * @description Event Log Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var ServiceAuthPageTemplate = require('../../templates/pages/serviceauthpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var ServiceAuthView = require('../widgets/serviceauthview');
var ServiceAuthModel = require('../../models/serviceauth');
var app = require('ampersand-app');

var ServiceAuthPageView = AmpersandView.extend({

    template : ServiceAuthPageTemplate,

    pageName: "ServiceAuthPage",

    initialize: function(){
        this.model = new ServiceAuthModel();
        var client = "";
        if(app.queryParams.client_id!=undefined) client = app.queryParams.client_id;
        else if(window.localStorage.getItem('serviceAuth')!=null) client = window.localStorage.getItem('serviceAuth');
        this.model.set('service',client);
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybit Authentication for ' + this.model.displayName,
                    noback: true,
                    login: true
                });
            }
        },

        serviceAuth: {
            selector : '#content',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new ServiceAuthView({
                    el: el,
                    parent: this,
                    model: this.model
                });
            }
        }
    },

    events: {
        "click #refresh" : "refreshServiceAuth",
        "click #clear" : "clearServiceAuth"
    },

    refreshServiceAuth: function(){
        console.log('refresh');
        var records = [...app.serviceAuth.records];
        document.getElementById("content").innerHTML = records.reverse().join('<br/><br/>\n');
        delete records;
    },

    clearServiceAuth: function(){
        console.log('clear');
        app.serviceAuth.clear();
        this.refreshServiceAuth();
    }

});

module.exports = ServiceAuthPageView;
