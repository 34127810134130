/**
 * @fileOverView
 * @description Authentication Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");
var YAML = require('yamljs');

var Auth = AmpersandModel.extend({

    url: function(){
        //return app.config.restApiUrl + "/checkauth";
        if(this.mode =='signout') return app.config.baseApiUrl + "/signout";
        else if(this.mode=='register') return app.config.baseApiUrl + "/register";
        else if(this.mode=='delete') return app.config.baseApiUrl + "/delete";
        else return app.config.baseApiUrl + "/firebase";  //signin
    },

    props: {
        authenticated : 'boolean',
        provider: 'string',
        idToken: 'string',
        deviceID: 'string',
        mode: 'string',
        jwtToken: 'string'
    },

    session: {
        deviceToken: ['string',true,null]
    },

    derived: {
        personKey: {
            deps: ['jwtToken'],
            fn: function () {
                if(this.jwtToken) return JSON.parse(atob(this.jwtToken.split('.')[1])).personKey;
                else return null;
            }
        }
    },

    initialize: function(){
        this.set('authenticated',false);
        this.mode = 'signin';
        console.log('authenticated is ' + this.authenticated);
    },

    ajaxConfig: function () {
        if(this.mode=='signout' || this.mode=="register" || this.mode=="delete"){
            return {
                headers: {
                    'Authorization': this.jwtToken
                }
            };

        }else{
            return {
                headers: {
                    'Authorization': 'Bearer ' + this.idToken
                }
            };
        }
    },

    checkAuth: function() {

        //capture serviceAuth params if present
        if(app.queryParams.client_id!=undefined /*&& app.utils.validServiceClient() */) {
            app.serviceAuth = true;

            window.localStorage.setItem('serviceAuth', app.queryParams.client_id);
            window.localStorage.setItem('dtSet', Math.floor(Date.now() / 1000));
            if(app.queryParams.state) window.localStorage.setItem('state', app.queryParams.state);
            if(app.queryParams.redirect_uri) window.localStorage.setItem('redirect_uri', app.queryParams.redirect_uri);

        //remove expired serviceAuth artifacts
        }else if(this.authenticated && window.localStorage.getItem('serviceAuth')){
            var dtNow = Math.floor(Date.now() / 1000);

            if(dtNow - window.localStorage.getItem("dtSet") > 120){
                window.localStorage.clear();
                app.serviceAuth = false;
            }else{
                app.serviceAuth = true;
            }
        }

        //subscription cards
        if(app.queryParams.scard!=undefined || window.location.pathname.match('/subscription/')) {
            app.renderPage("SubscriptionCardPage");

        //www
        }else if(window.location.host==app.config.wwwHost) {
            app.renderWwwPage();

        //pay wall page
        }else if(window.location.host==app.config.pwHost){
            app.renderPwPage();

        //authenticated
        }else if(this.authenticated) {
            console.log('authenticated');

            //service authentication
            if(app.serviceAuth) {
                app.loadProfile();
                app.renderServiceAuth();

            //logs
            }else{

                //socket only for browser
                if(app.platform=="browser") app.initSocket();
                else app.loadData();

                app.renderPage();
                app.utils.startSpinner();
            }

        //not authenticated
        }else{

            //service auth
            if(app.serviceAuth) {
                app.renderServiceAuth();

            //demo sound page
            }else if(app.queryParams.p!=undefined && app.queryParams.p=="sounds") {
                var params = {};
                if (app.queryParams.squery != undefined) {
                    params.squery = app.queryParams.squery;
                    delete app.queryParams.squery;
                }
                delete app.queryParams.p;
                app.loadSounds(params);

            //handle subscription requests for iPhone/iPad
            }else if(app.queryParams.sk!=undefined && app.platform=="browser" && !app.queryParams.skclick
                && navigator.userAgent.match(/iPhone|iPad|Android/)
            ){
                app.renderSubscriptionPage();

            //login
            }else {
                app.renderMainLoginPage();
            }
        }

        app.utils.stopSpinner();
    },

    registerDevice: function(data){
        this.set('mode','register');
        var self = this;
        console.log('registering device');
        console.log(data);
        
        this.save({
                deviceID: data.registrationId
            },
            {
            patch:true,
            success: function(model,response){
                console.log('device registered');
                if(response.jwtToken!=undefined) model.set('jwtToken',response.jwtToken);
                if(response.deviceEncoded!=undefined){
                    console.log('deviceEncoded');
                    model.set('deviceToken',response.jwtToken);

                    //set geofences and manage other device services
                    if(app.platform=="app" && typeof("VybitPlugin")!=undefined){
                        app.glutils.manage();
                        app.utils.manageRating();
                    }

                }
                self.set('mode','signin');
            },
            error: function(){
                self.set('mode','signin');
            }
        });
    },

    signOut: function () {
        var self = this;
        this.set('mode','signout');
        this.set('authenticated', false);
        var self = this;
        app.queryParams = {};
        window.localStorage.clear();
        this.fetch({
            success: function(model,response){
                model.set('jwtToken',response.jwtToken);
                self.set('mode','signin');
                app.firebase.auth().signOut().then(
                    function(result){
                        console.log('successfully signed out');
                    }
                ).catch(function(error){
                    console.log(error);
                });
            },
            error: function(model,response){
                self.set('mode', 'signin');
                console.log('signout error');
            }
        });

        if(typeof(app.socket)!="undefined") app.socket.close();
    },

    deleteAccount: function(){
        var self = this;
        this.set('mode','delete');
        this.set('authenticated', false);
        var self = this;
        app.queryParams = {};
        window.localStorage.clear();
        this.fetch({
            success: function(model,response){
                model.set('jwtToken',response.jwtToken);
                self.set('mode','signin');
                window.localStorage.setItem('loginMessage','Your account has been successfully deleted.');
                app.firebase.auth().signOut().then(
                    function(result){
                        console.log('successfully signed out and deleted account');
                    }
                ).catch(function(error){
                    console.log(error);
                });
            },
            error: function(model,response){
                self.set('mode', 'signin');
                console.log('delete account error');
            }
        });

        if(typeof(app.socket)!="undefined") app.socket.close();
    }
});

module.exports = Auth;
