/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var PublicVybsPageTemplate = require('../../templates/pages/publicvybspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var SubscriptionsView = require('../widgets/subscriptionsview');
var SearchView = require('../components/search');
var app = require('ampersand-app');

var PublicVybsPageView = AmpersandView.extend({

    template : PublicVybsPageTemplate,

    pageName : "PublicVybsPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybit Subscriptions',
                    noback: true,
                    novadd: true
                });
            }
        },

        subscriptions: {
            selector : '#subscriptions',
            prepareView: function(el) {
                return new SubscriptionsView({
                    el: el,
                    parent: this
                });
            }
        },

        search: {
            selector : 'div.vsearchtable',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'subscriptions'
                });
            }
        }
    }
});

module.exports = PublicVybsPageView;
