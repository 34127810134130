/**
 * @fileOverView
 * @description Event Log View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var ServiceAuthTemplate = require('../../templates/widgets/serviceauthtemplate.html');
var ServiceAuthModel = require("../../models/serviceauth");
var app = require('ampersand-app');

var ServiceAuthView = AmpersandView.extend({

    template: ServiceAuthTemplate,

    initialize: function(options){
        this.model = options.model;
    },

    bindings: {
        "model.displayName": {
            type: 'text',
            hook: 'serviceClient'
        }
    },

    events: {
        "click #auth-service-allow" : "allowService",
        "click #auth-service-decline" : "declineService"
    },

    allowService: function(){ this.model.processAllowRedirect(); },

    declineService: function(){

        var redirectUrl = window.localStorage.getItem("redirect_uri")!=undefined ? decodeURIComponent(window.localStorage.getItem("redirect_uri")) : app.config.serviceAuth&[this.model.service].redirect_uri || null;

        window.localStorage.clear();
        app.queryParams = {};

        if(redirectUrl == null){
            app.loadData();
            return;
        }

        redirectUrl = redirectUrl + "?error=access_denied";

        console.log('redirecting to ' + redirectUrl);

        if(app.platform=="app") app.loadData();
        window.location.href = redirectUrl;
    }

})

module.exports = ServiceAuthView;
