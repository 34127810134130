/**
 * @fileOverView
 * @description Header View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var HeaderTemplate = require('../../templates/widgets/header.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');
var HeaderModel = require('../../models/header');

var HeaderView = AmpersandView.extend({

    template : HeaderTemplate,

    initialize: function(options){
        this.model = new HeaderModel(options);

        if(app.header!=undefined && app.header.get('mode')!=null){
            this.model.set('mode',app.header.get('mode'));
        }else if(window.localStorage.getItem('displayMode')!=null){
            this.model.set('mode',window.localStorage.getItem('displayMode'));
        }

        app.header = this.model;
    },

    bindings: {

        "model.maintitle" : {
            type: "text",
            selector: "div.maintitle"
        },

        "model.headerleft" : {
            type: "innerHTML",
            selector: "div.vheadleft"
        },

        "model.headerright" : {
            type: "text",
            selector: "div.vheadright"
        },

        "model.headerleftclass" : {
            type: "class",
            selector: "div.vheadleft"
        },

        "model.headerrightclass" : {
            type: "class",
            selector: "div.vheadright"
        }
    },

    events: {
        "click .vback" : "goBack",
        "click .vadd" : "add",
        "click .vmode" : "toggleMode"
        //"click div.maintitle" : "clickMain",
    },

    goBack: function(){
        app.back = true;
        app.pageSwitcher.set(app.lastPage);
    },

    add: function(){
        if(this.model.ignoreclick){
            this.model.ignoreclick = false;
            return;
        }
        var pageview = {};
        pageview = new app.VybPageView({});
        app.pageSwitcher.set(pageview);
    },

    clickMain: function(){
        console.log('click');
        app.sounds.each(function(sound){
            sound.playSound();
            sound.soundObject.pause();
        });
    },

    toggleMode: function(){
        var mode = this.model.get('mode')=="day" ? "night" : "day";
        window.localStorage.setItem('displayMode',mode);
        this.model.set('mode', mode);
        app.utils.setDisplayMode();
    }

});

module.exports = HeaderView;
