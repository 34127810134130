/**
 * @fileOverView
 * @description Footer View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var FooterTemplate = require('../../templates/widgets/footer.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var FooterView = AmpersandView.extend({

    template : FooterTemplate,

    events: {
        "click #home": "homePage",
        "click #vyblist": "vybListPage",
        "click #logs": "logsPage",
        "click #sounds": "soundsPage",
        "click #profile": "profilePage"
    },

    homePage: function(){
        var view =   new app.MainPageView();
        app.pageSwitcher.set(view);
    },

    vybListPage: function(){
        app.pageSwitcher.set(app.vybListPageView);
    },

    logsPage: function(){
        app.pageSwitcher.set(app.logPageView);
    },

    soundsPage: function(){
        app.vyb = null;
        app.pageSwitcher.set(app.soundsPageView);
    },
    
    profilePage: function(){
        app.pageSwitcher.set(app.profilePageView);
    }

});

module.exports = FooterView;
