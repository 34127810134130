/**
 * @fileOverView
 * @description Header View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var InviteTemplate = require('../../templates/components/invite.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var InviteView = AmpersandView.extend({

    props: {
      email: 'string'
    },

    template : InviteTemplate,

    events: {
        "click #sendinvite" : "sendInvite"
    },

    sendInvite: function(){
        var input = document.querySelector('#vinvite');
        var email = input.value;
        var validEmail = /^[^.\@\s][^\@\s]*@[a-z0-9][a-z0-9._-]*\.[a-z]{2,}$/;
        if(email.match(validEmail)){
            var peep = new app.VybPeep();
            var data = {email:email, accessStatus:"invited"};
            data.vybKey = app.vyb.key;
            peep.save(data,{
                success: function(model,response){
                    input.value = "";
                    input.setAttribute('placeholder','email invitation to new peep');
                    app.vybPeeps.fetch();
                },
                error: function(model,response,error){
                    console.log('error inviting peep');
                    console.log(error.message);
                }
            });
        }else{
            input.setAttribute('placeholder','invalid email, try again');
            input.value="";
        }
    }
});

module.exports = InviteView;
