/**
 * @fileOverView
 * @description PW View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

const PwPageTemplate = require('../../templates/pages/pwpage.html');
const AmpersandView = require('ampersand-view');
const HeaderView = require('../widgets/header');

const app = require('ampersand-app');

const PwPage = AmpersandView.extend({

    template : PwPageTemplate,

    initialize: function(){
        this.model = app.meter;
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                return new HeaderView({
                    el: el,
                    parent: this,
                    noback: true,
                    login: true,
                    maintitle: 'Vybit Subscription Plan'
                });
            }
        }
    },

    bindings: {

        "model.name" : {
            type: "text",
            hook: "name"
        },

        "model.email" : {
            type: "text",
            hook: "email"
        },

        "model.plan": {
            type: "text",
            hook: "plan"
        },

        "model.cap_vybits": {
            type: "text",
            hook: "cap_vybits"
        },

        "model.cap_daily": {
            type: "text",
            hook: "cap_daily"
        },

        "model.cap_monthly": {
            type: "text",
            hook: "cap_monthly"
        },

        "model.customer_id": {
            type: function(el, value, previousValue){ this.renderManagement(el, value, previousValue); },
            hook: "manage"
        }
    },

    events: {
        "click .vyblogout" : "logout",
        "click .manage_plan" : "managePlan"
    },

    renderManagement: function(el, value, previousValue){
        console.log(value);
        if(value){
            el.innerHTML = '<a href="#" class="manage_plan">Manage Subscription Plan</a>';
        }else{
            el.innerHTML = `
                <div id="pt" class="vdesc" data-hook="pricing_table">
                Choose a subscription plan that fits your usage. Notifications sent from 
                your Vybits to yourself and your followers count toward the daily and monthly limits. 
                Notifications that you receive from Vybits you are following 
                are always unlimited. You can modify or cancel your subscription anytime.<br/><br/>&nbsp;
                    <stripe-pricing-table pricing-table-id="${app.config?.pwPricingTableId}"
                                          publishable-key="${app.config?.pwApiKey}"
                                          client-reference-id="${this.model.personKey}">
                    </stripe-pricing-table>
                    
                    <div class="vcenter">Need even more? Contact platinum@vybit.net.</div>
                </div>
            `;
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            document.head.appendChild(script);
        }
    },

    managePlan: function() {
        let session = new app.SubscriptionPortal();
        session.fetch({
            success: function(model, response, options){
                window.location.href = model.sessionUrl;
            },
            error: function(model, response, options){
                console.log()
                alert("An error occurred. Try again. Contact support if the problem persists.");
            }
        })

    },

    logout: function () {
        app.auth.signOut();
    },

});

module.exports = PwPage;
