/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var VybListPageTemplate = require('../../templates/pages/vyblistpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var VybsView = require('../widgets/vybsview');
var VybsFollowingView = require('../widgets/vybsfollowingview');
var SubscriptionsView = require('../widgets/subscriptionsview');

var SearchView = require('../components/search');
var app = require('ampersand-app');

var VybPageView = AmpersandView.extend({

    template : VybListPageTemplate,

    pageName : "VybListPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Vybits'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        vybs: {
            selector : '#vybs',
            prepareView: function(el) {
                return new VybsView({
                    el: el,
                    parent: this
                });
            }
        },

        searchvybs: {
            selector : '#searchvybs',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'vybs'
                });
            }
        },

        vybsfollowing: {
            selector : '#vybsfollowing',
            prepareView: function(el) {
                return new VybsFollowingView({
                    el: el,
                    parent: this
                });
            }
        },

        searchfollows: {
            selector : '#searchfollowing',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'vybsfollowing'
                });
            }
        },

        vybssubscribe: {
            selector : '#subscriptions',
            prepareView: function(el) {
                return new SubscriptionsView({
                    el: el,
                    parent: this
                });
            }
        },

        searchsubscribes: {
            selector : '#searchsubscribes',
            prepareView: function(el) {
                return new SearchView({
                    el: el,
                    parent: this,
                    entity: 'subscriptions'
                });
            }
        },

    },

    events: {
        "click .vcreate" : "vybPage",
        "click a.vtab" : "saveActiveTab"
    },

    vybPage: function(){
        var view =   new app.VybPageView({model:app.vyb});
        app.pageSwitcher.set(view);
    },

    saveActiveTab: function(event){
        window.sessionStorage.setItem('VybListPageActiveTab', event.delegateTarget.id);
    }
});

module.exports = VybPageView;
