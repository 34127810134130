/**
 * @fileOverView
 * @description Vybit Collection View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var SubscriptionsTemplate = require('../../templates/widgets/subscriptionstemplate.html');
var SubscriptionView = require('./subscriptionview');
var app = require('ampersand-app');

var SubscriptionsView = AmpersandView.extend({

    template: SubscriptionsTemplate,

    initialize: function(options){
        var self = this;

        if(app.subscriptions==undefined) app.loadSubscriptions();

        this.collection = app.subscriptions;
        this.entity = "subscriptions";
        this.lastLoad = Date.now();

    },

    render: function(){
        this.renderWithTemplate();
        this.renderCollection(
            app.subscriptions,
            SubscriptionView,
            "#subscriptionstable",
            {
                filter: function(model){
                    if(app.auth.authenticated==false || window.location.host==app.config.wwwHost) return true;
                    if(app.vybsfollowing.get(model.key,'subscriptionKey')) return false;
                    if(app.vybs.get(model.key,'subscriptionKey')) return false;
                    else return true;
                }
            }
        );
    },

    events: {
        "scroll" : "infiniteScroll"
    },

    infiniteScroll: function(){ app.utils.infiniteScroll(this); }

});

module.exports = SubscriptionsView;
