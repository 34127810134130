/**
 * @fileOverView
 * @description Log Model<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var moment = require('moment');
var app = require("ampersand-app");

var Log = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        var url = app.config.restApiUrl + "/log";
        if(this.hasParentLog==true) url = url + "/plk";
        return url
    },

    url: function(){
        var url = this.urlRoot() + '/' + this.key;
        if(this.usp!=undefined) url = url + "?usp=1";
        return url;
    },

    ajaxConfig: app.utils.setAuthTokenHeader,

    props: {
        key : 'string',
        dtStart: 'string',
        diagnostics: 'object',
        vybKey: 'string',
        parentLogKey: 'string',
        playedSound: 'number'
    },

    session: {
        pinging: ['boolean',true,false],
        hasParentLog: ['boolean',true,false],
        usp: ['boolean',false]
    },

    derived:{
        vybName: {
            deps: ['diagnostics'],
            fn: function(){
                var content="";
                if(this.diagnostics.vybName!=undefined) content = this.diagnostics.vybName;
                else if(this.diagnostics.type=="peepConnectedTo") content = 'peep invite accepted by';
                else if(this.diagnostics.type=="peepConnectedFrom") content = 'peep invite accepted for';
                else if(this.diagnostics.type=="peepInviteReceived") content = 'peep invite received from';
                else if(this.diagnostics.type=="peepInviteSent") content = 'peep invite sent to';
                else if(this.diagnostics.type=="peepRemovedTo") content = 'peep connection removed by';
                else if(this.diagnostics.type=="peepRemovedFrom") content = 'peep connection removed for';
                return content;
            }
        },

        vybDescription: {
            deps: ['diagnostics'],
            fn: function(){
                return this.diagnostics.vybDescription!=undefined ? this.diagnostics.vybDescription : '';
            }
        },

        message: {
            deps: ['diagnostics'],
            fn: function(){

                //potential components of the log
                //vybDescription (deprecated)
                //notification
                //message
                //ownerName
                //imageUrl
                //linkUrl
                //log

                //construct message
                var message = [];

                if(this.diagnostics.message!=undefined && this.diagnostics.message.length>0) message.push(this.diagnostics.message);
                else if(this.diagnostics.notification!=undefined && this.diagnostics.notification.length>0) message.push(this.diagnostics.notification);

                if(this.diagnostics.imageUrl && this.diagnostics.imageUrl.length>0) message.push('<img alt="" class="vlogimg" src="'+ this.diagnostics.imageUrl +'" />');

                var messageHtml = message.join("<br/>");

                //wrap in link if present
                if(this.diagnostics.linkUrl!=undefined && this.diagnostics.linkUrl.length>0){
                    if(messageHtml.length>0){
                        var messageHtml = '<a target="_blank" href="' + this.diagnostics.linkUrl + '">' +
                            messageHtml + '</a>';
                    }else{
                        messageHtml = '<a target="_blank" href="'+ this.diagnostics.linkUrl +'" >View</a>';
                    }
                }

                //construct log
                var log = [messageHtml];
                if(this.diagnostics.log!=undefined && this.diagnostics.log.length>0) log.push('<div class="vlog">' + this.diagnostics.log + '</div>');
                if(this.diagnostics.ownerName!=undefined && this.diagnostics.ownerName.length>0) log.push('<div class="vlogfrom">from ' + this.diagnostics.ownerName + '</div>');

                //append log for subscriptions
                if(app.platform=="browser" && log[0].match("now following this vybit")){
                    var appLink = "https://" + app.config.dynamicLinkDomain + "?link=" + encodeURIComponent("http://" + app.config.wwwHost);
                    var appendNote = "<div class='vnote'>NOTE: <a href='"+ appLink +"' target='_blank'>Install the Vybit Mobile App</a> and Sign In there to hear this vybit notification on your device! <span onclick='app.utils.removeParentEl(event,this)' class='vclose'>&#9447;</span></div>";
                    log.push(appendNote);

                }

                //append log for geofence subscriptions
                if(log[0].match("now following this vybit") && this.diagnostics.triggerType!=undefined && this.diagnostics.triggerType=="geofence"){
                    var geofenceNote = "<div class='vnote'>NOTE: This vybit has a geofence trigger, cool! To ensure it works verify that location permissions for Vybit are set to \"Always\" in your device settings. <span onclick='app.utils.removeParentEl(event,this)' class='vclose'>&#9447;</span></div>";
                    log.push(geofenceNote);
                }
                
                var logHtml = log.join("\n");

                var logEl = document.createElement("div");
                logEl.innerHTML = logHtml;
                var children = logEl.getElementsByTagName("a");
                for (var i = 0; i < children.length; i++) {
                    if(children[i].nodeName=="A"){
                        if(this.noLinkExceptions(children[i])) children[i].setAttribute("onclick", "app.utils.cancelBubble()");
                        if(children[i].getAttribute("target")==null) children[i].setAttribute("target","_blank");
                    }
                }

                return logEl.innerHTML;
            }
        },

        logDate:{
            deps: ['dtStart'],
            fn: function(){
                var date = moment(this.dtStart);
                var hi =  date.format('h:mm a');
                var ymd = date.format('DD MMM YYYY');
                return "<span class='text-nowrap'>" + hi + "</span>" + "<br/>" + ymd;
            }
        },

        connectedStatus:{
            deps: ['diagnostics'],
            fn: function(){

                var opacity = "";
                if(this.diagnostics.connected==0) opacity = "vimgopaque";
                if(this.diagnostics.connected==1 && this.diagnostics.pollStatus==undefined && this.diagnostics.trigger==0) opacity = "vimgopaque";

                var img = "bell1.png";

                if(this.diagnostics.type!=undefined && this.diagnostics.type.match("peep")) return "";
                else if(this.diagnostics.pollStatus!=undefined && this.diagnostics.pollStatus==false) img = 'minus.png';

                return "<img class='vdeltaimg "+ opacity +"' src='images/"+ img +"'/>";

            }
        },

        followKey: {
            deps: ['diagnostics'],
            fn: function(){
                if(this.diagnostics.vybPersonKey!=undefined) fk = this.diagnostics.vybPersonKey;
                else if(this.diagnostics.fk!=undefined) fk = this.diagnostics.fk;
                else fk = null;
                return fk;
            }

        },

        soundKey:{
            deps: ['diagnostics'],
            fn: function(){
                return this.diagnostics.soundKey ? this.diagnostics.soundKey : null;
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        },

        triggerType: {
            deps: ['diagnostics'],
            fn: function(){
                return this.diagnostics.triggerType || "event";
            }
        }
    },

    noLinkExceptions: function(anchor){
        if(app.profile==undefined || app.profile.type=="user") return true;
        if(app.profile.type=="admin" && anchor.getAttribute('data-reviewKey')){
            var vybKey = anchor.getAttribute('data-reviewKey');
            anchor.setAttribute('onclick','app.utils.reviewVyb("'+vybKey+'")');
            return false;
        }
        return true;
    }
});

module.exports = Log;
