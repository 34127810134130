/**
 * @fileOverView
 * @description Vyb Full View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybFollowingTemplate = require('../../templates/widgets/vybfollowingtemplate.html');
var config = require("../../etc/config");
var app = require("ampersand-app");

var VybFollowingView = AmpersandView.extend({

    template: VybFollowingTemplate,

    render: function(){
        this.renderWithTemplate();
        app.utils.clipboard();
    },

    bindings: {
        "model.vybName": {
            type: 'text',
            hook: 'name'
        },

        "model.description": {
            type: 'text',
            hook: 'description'
        },

        "model.ownerName": {
            type: 'text',
            hook: 'owner'
        },
        "model.onOff": {
            type: 'booleanAttribute',
            hook: 'onOff',
            name: 'checked'
        },
        "model.switchKeyInput": {
            type: "attribute",
            selector: "input",
            name: "id"
        },
        "model.switchKeyLabel": {
            type: "attribute",
            selector: "label.vswitchlabel",
            name: "for"
        },
        "model.soundName": {
            type: 'innerHTML',
            hook: 'soundName'
        },
        "model.buttonDisplay": {
            type: 'innerHTML',
            hook: 'button'
        },

        "model.declineButtonDisplay": {
            type: 'booleanClass',
            hook: 'decline',
            name: 'vhide'
        },
        "model.hideSubscriptionLinks": {
            type: 'booleanClass',
            selector: '#subscriptionLinks',
            name: 'vhide'
        },
        "model.subscriptionLinks": {
            "type" : "innerHTML",
            "hook" : "subscriptionLinks"
        },
        "model.subscriptionCopyButtons" : {
            "selector" : "div.vsubcopy",
            "type" : "innerHTML"
        },
        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }

    },

    events: {
        "click label.vswitchlabel" : "setStatus",
        "click button.vybdelete" : "unfollowVyb",
        "click button.vybaccept" : "acceptInvite",
        "click button.vybdecline" : "declineInvite",
        "click div[data-hook='soundName']" : "setSound",
        "click img.vdeltaimg, .vdeltaclick" : "playSound"
    },

    setStatus: function(){
        var status = this.model.status=="on" ? "off" : "on";
        this.model.save({status:status},
            {
                patch:true,

                success: function(model,response){
                    var cb = document.getElementById('switch-'+model.key);
                    var status = model.status;
                    if(status=='on' && cb.checked==false) cb.checked = true;
                    else if(status=="off" && cb.checked==true) cb.checked = false;
                }
            });

    },

    acceptInvite: function(){
        this.model.save({accessStatus:"granted"},
            {
                patch:true,

                success: function(model,response){
                    app.loadLogs(true);
                }
            });

    },

    unfollowVyb: function(){
        if(confirm('Unfollow this Vybit?')){
            console.log('unfollowing ' + this.model.key);
            this.model.destroy();
            app.pageSwitcher.set(new app.LogPageView());
        }
    },

    declineInvite: function(){
        console.log('clicked decline');
        if(confirm('Decline Invitation to Follow this Vybit?')){
            console.log('declining');
            this.model.destroy();
            app.pageSwitcher.set(new app.LogPageView());
        }
    },

    setSound: function(){
        return; //TODO
        var view =   new app.SoundsPageView({mode:"vybfollow", model: this.model });
        app.pageSwitcher.set(view);
    },

    playSound: function(){
        var self = this;
        this.model.set('pinging',true);
        app.utils.playSound(this.model.soundKey);
        setTimeout(function(){ self.model.set('pinging',false); },10000);
    }
});

module.exports = VybFollowingView;
