/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var VybPageTemplate = require('../../templates/pages/vybpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var VybFullView = require('../widgets/vybfullview');
var Vyb = require("../../models/vyb");
var VybTest = require("../../models/vybtest");
var Logs = require('../../collections/logs');
var app = require('ampersand-app');

var VybPageView = AmpersandView.extend({

    initialize: function(options){

      if(!this.model) this.model = options.model ? options.model : new Vyb();
      if(options.soundKey!=undefined) this.model.soundKey = options.soundKey;
      app.vyb = this.model;
      //TODO refactor, if(app.vyb.key!=undefined) app.vyb.followers.fetch();

      this.action = options.action != undefined ? options.action : null;

    },

    template : VybPageTemplate,

    pageName: "VybPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    model: this.model,
                    maintitle: this.model.key ? this.model.name : 'New Vybit'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        vyb: {
            selector : '#content',
            prepareView: function(el) {
                return new VybFullView({
                    el: el,
                    parent: this,
                    model: app.vyb,
                    action: this.action
                });
            }
        }
    },

    events: {
        "click div.vcancel" : "cancelEdit",
        "click div.vdone" : "saveEdit",
        "click button.vybdelete" : "deleteVyb",
        "click button.vmod_button.btn-success" : "moderationReview",
        "click button.vmod_button.btn-primary" : "moderationApprove",
        "click button.vmod_button.btn-danger" : "moderationReject",

    },

    cancelEdit: function(){
        app.header.set({editing:false});
    },

    saveEdit: function(){
        app.header.set('editing',false);
    },

    deleteVyb: function(){
        if(confirm('Permanently Delete this Vybit?')){
            console.log('deleting vyb ' + this.model.key);
            if(app.vybs.get(this.model.key,'vybKey')){
                app.vybs.remove(app.vybs.get(this.model.key,'key'));
            }
            if(app.sounds.get(this.model.soundKey)){
                var sound = app.sounds.get(this.model.soundKey,'key');
                sound.set('mode', 'sounds');
            }
            if(app.usedSounds.get(this.model.soundKey)) app.usedSounds.remove(this.model.soundKey,'key');
            this.model.destroy();
            app.vyb = null;
            app.pageSwitcher.set(new app.LogPageView());
            app.loadMeter();
        }
    },

    moderationReview: function(){
        this.model.set('moderationStatus','review');
        this.model.set('moderationMessage','');
        this.model.patch({moderationStatus: this.model.moderationStatus, moderationMessage: this.model.moderationMessage});
    },
    moderationApprove: function(){
        var moderationStatus = 'approved';
        var moderationMessage = this.query('textarea').value;
        this.model.set('moderationMessage',moderationMessage);
        this.model.set('moderationStatus',moderationStatus);
        this.model.patch({moderationStatus: moderationStatus, moderationMessage: moderationMessage});
    },
    moderationReject: function(){
        var moderationStatus = 'rejected';
        var moderationMessage = this.query('textarea').value;
        this.model.set('moderationMessage',moderationMessage);
        this.model.set('moderationStatus',moderationStatus);
        this.model.patch({moderationStatus: moderationStatus, moderationMessage: moderationMessage});
    }
});

module.exports = VybPageView;
