/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var DocsPageTemplate = require('../../templates/pages/docspage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var DocsView = require('../widgets/docsview');
var app = require('ampersand-app');

var DocsPageView = AmpersandView.extend({

    template : DocsPageTemplate,

    pageName: "DocsPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Support'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        docs: {
            selector : '#content',
            prepareView: function(el) {
                return new DocsView({
                    el: el,
                    parent: this
                });
            }
        }
    }
});

module.exports = DocsPageView;
