/**
 * @fileOverView
 * @description Header View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var ScheduleTemplate = require('../../templates/components/schedule.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var ScheduleView = AmpersandView.extend({

    autoRender: true,

    template : ScheduleTemplate,

    initialize: function(options){
        for (const key in options){
            this[key] = options[key];
        }
    },

    bindings: {
        cron_min : {
            "hook" : "cron_min",
            "type" : "text"
        },
        cron_hour : {
            "hook" : "cron_hour",
            "type" : "text"
        },
        cron_dom : {
            "hook" : "cron_dom",
            "type" : "text"
        },
        cron_month : {
            "hook" : "cron_month",
            "type" : "text"
        },
        cron_dow : {
            "hook" : "cron_dow",
            "type" : "text"
        },
        cron_timezone : {
            "hook" : "cron_timezone",
            "type" : "text"
        }
    },


});

module.exports = ScheduleView;
