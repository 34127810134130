/**
 * @fileOverView
 * @description sound file utilities. <br>
 * @copyright 2015-present Flatirontek LLC
 **/

'use strict';
const MicRecorder = require('mic-recorder-to-mp3');
var moment = require('moment');
var app = require('ampersand-app');

module.exports = {

    uploadSound: function(event,view){

        var self = this;

        event.preventDefault();

        console.log('begin sound file upload');

        //sound urls
        if(view.query("input[name='fileurl']").value.length>0){

            if(document.querySelector('[name=audio]').files[0]!=undefined){
                view.clearForms();
                alert('error: cannot specify both local and remote files at the same time');
                return;
            }

            var url = view.query("input[name='fileurl']").value
            if(!self.validUrl(url) || !url.match(/mp3|m4a/)){
                view.resetButtons();
                alert('Invalid URL. Sound files must be mp3 or m4a format.');
                return;
            }

            if(document.getElementById("nameupload").value.length==0){
                view.resetButtons();
                alert('ERROR: missing sound name.');
                return;
            }

            this.processWebFile(view);

            return;
        }

        //validation
        if(view.soundFileName==undefined && document.querySelector('[name=audio]').files[0]==undefined){

            alert('ERROR: No sound found.');
            view.resetButtons();
            return;

        }else if(document.getElementById("name").value.length==0 && document.getElementById("nameupload").value.length==0) {
            alert('ERROR: missing sound name.');
            view.resetButtons();
            return;
        }

        if(view.soundFileDuration>11.00){
            alert('ERROR: sound file duration exceeds 10 second limit');
            view.resetButtons();
            return;

        }else if((!view.soundFileValidExt || !view.soundFileValidFormat) && app.platformId!="android"){

            alert("ERROR: sound file must be mp3 or m4a format");
            view.resetButtons();
            return;
        }

        view.message('saving sound file ' + view.soundFileName);

        //upload app recorded sound
        if(app.platform=="app" && view.soundSource=="recorded"){
            var params = {
                soundFileName: view.soundFileName,
                name: document.getElementById("name").value,
                description: document.getElementById("desc").value,
                source: view.soundSource,
                soundUsername: view.soundUsername,
                soundFileType: view.soundFileType,
                view: view
            };

            app.fsutils.uploadSoundFile(params);
            return;
        }

        //for non-recorded files
        if(view.soundFile==undefined){

            const files = document.querySelector('[name=audio]').files;
            view.soundFile = files[0];
            view.soundFileName = files[0].name;
            view.soundSource = 'uploaded';

        }

        var soundNameField = view.soundSource=="uploaded" ? "nameupload" : "name";
        var soundDescField = view.soundSource=="uploaded" ? "descupload" : "desc";

        const formData = new FormData();
        formData.append('file', view.soundFile);
        formData.append('fileName',view.soundFileName);
        formData.append('name',document.getElementById(soundNameField).value);
        formData.append('description',document.getElementById(soundDescField).value);
        formData.append('source',view.soundSource);
        formData.append('type',view.soundFileType);

        // post form data
        const xhr = new XMLHttpRequest();

        console.log('uploading sound');

        //handle response
        xhr.onload = () => {
            if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                    console.log('sound successfully uploaded');
                    var soundData = JSON.parse(xhr.response);
                    var sound = new app.Sound(soundData);
                    sound.set('mode', app.vyb!=undefined && app.vyb!=null ? 'vyb' : 'sounds');
                    app.sounds.add(sound,{at:0});
                    if(app.platform=="app") app.fsutils.soundSetUp(sound);
                    view.query('[id="nav-search-tab"]').click();
                    view.clearForms();
                    self.deleteRecording(view);
                }else{
                    console.log('error uploading sound, status: ' + xhr.status);
                    var response = JSON.parse(xhr.response);
                    if(response.error){
                        console.log('ERROR: ' + response.error);
                        view.message("error saving sound file: " + response.error, "error");
                        view.resetButtons();
                    }
                }
            }
        };

        // create and send the reqeust
        xhr.open('POST', app.config.restApiUrl + "/audiofile");
        xhr.setRequestHeader('Authorization', app.auth.jwtToken);
        xhr.send(formData);

    },

    startRecording: function(view){

        if(view.recording) return;
        view.recording = true;

        var self = this;

        view.message("recording . . .");

        //instantiate recorder
        if(app.platform=="browser" && view.recorder==undefined){

            view.recorder = new MicRecorder({
                bitRate: 128
            });

        }

        //start recording
        //browser
        if(app.platform=="browser"){

            if(view.soundFile!=undefined) this.deleteRecording(view);

            view.recorder.start().then(() => {
                console.log('recording');
            }).catch((e) => {
                console.log('error recording');
                console.error(e);
            });

        //app
        }else{

            if(view.recorder!=undefined) {
                delete view.recorder;
            }

            this.setSoundFileName(view);

            view.soundFilePath = view.soundFileDir + view.soundFileName;
            view.soundFilePath =   view.soundFilePath.replace('file://', '');

            console.log('start recording file ' + view.soundFilePath);

            view.recorder = new Media(view.soundFilePath,

                // success callback
                function() {
                    console.log("Media recording success");
                },

                // error callback
                function(err) {
                    console.log("Media recording error:");
                    console.dir(err);
                },

                //status
                function(status){
                    console.log('media status is ' + status);
                    if(status==4) view.recording = false;
                }

            );

            view.recorder.startRecord();

        }

        view.recordingTimer = setTimeout(()=>{
            if(view.recording){
                view.message("recording stopped at 10 seconds");
                self.stopRecording(view);
            }
        },10000);

    },

    stopRecording: function(view){
        view.recording = false;
        var self = this;

        console.log('stopping recording');

        clearTimeout(view.recordingTimer);

        view.resetButtons();

        //browser
        if(app.platform=="browser"){

            if(view.soundFile!=undefined && view.player!=undefined){
                view.player.pause();
                view.resetButtons();
                return;
            }

            if(view.recorder==undefined){
                view.resetButtons();
                return;
            }

            view.recorder
                .stop()
                .getMp3().then(([buffer, blob]) => {

                if(blob.size==0) return;

                self.setSoundFileName(view);

                view.soundFile = new File(buffer, view.soundFileName, {
                    type: blob.type,
                    lastModified: Date.now()
                });
                view.soundSource = 'recording';

                var createdmsg = 'created sound file ' + view.soundFileName;
                var append = view.query(".vrmessage").innerHTML.match("10 seconds")!=null ? true : false;
                view.message(createdmsg,'notice',append);

                view.player = new Audio(URL.createObjectURL(view.soundFile));
                view.player.onended = ()=>{
                    console.log('finished playing recording');
                    view.resetButtons();
                };

                console.log('recording saved, player created');

            }).catch((e) => {
                console.log('error capturing recording');
                console.log(e);
                view.message('recording error','error');

            });

        //app
        }else{
            view.recorder.stopRecord();
            if(view.soundFileName!=undefined){
                var createdmsg = 'created sound file ' + view.soundFileName;
                var append = view.query(".vrmessage").innerHTML.match("10 seconds")!=null ? true : false;
                view.message(createdmsg,'notice',append);
            }
        }
    },

    playRecording: function(view){
        console.log('playing recording');

        //browser
        if(app.platform=="browser" && view.player!=undefined){
            view.player.play();

        //app
        }else if(app.platform=="app" && view.recorder!=undefined){
            view.recorder.play();

        }else{
            view.resetButtons();
        }
    },

    deleteRecording: function(view){

        if(app.platform=="app"){
            if(view.recorder!=undefined) view.recorder.release();
            app.fsutils.deleteRecordedFiles(view.soundFileDir, view.soundUsername);
        }

        delete view.soundFile;
        delete view.soundFileName;
        delete view.soundFileType;
        delete view.soundSource;
        delete view.player;

        console.log('recording deleted');
    },

    setSoundFileName: function(view){
        var dts = Date.now();
        dts = moment().format('YYYYMMDDHHmmss');
        view.soundFileType = app.platformId=="ios" ? "m4a" : "mp3";
        view.soundFileName = view.soundUsername + '_' + dts + '.' + view.soundFileType;
        view.soundFileValidExt = true;
        view.soundFileValidFormat = true;
        view.soundFileDuration = 10;
        view.soundSource = 'recorded';
    },

    validateAudioFile: function(view){
        var self = this;
        var audio = document.createElement('audio');

        view.query("#file").addEventListener('change', function(event){
            var target = event.currentTarget;
            var file = target.files[0];
            
            if (target.files && file) {
                var reader = new FileReader();

                view.soundFileName = file.name;
                view.query("#filechosen").innerHTML = view.soundFileName;

                var ext = file.name.split('.').pop();
                view.soundFileType = ext;
                view.soundFileValidExt = ext=="mp3" || ext=="m4a" ? true : false;

                //override for android
                if(file.name.match(/\./)==null && app.platformId=="android"){
                    view.soundFileType = "mp3";
                    view.soundFileValidExt = true;
                }

                reader.onload = function (e) {
                    audio.src = e.target.result;
                    audio.addEventListener('loadedmetadata', function(){
                        view.soundFileDuration = audio.duration;
                        view.soundFileValidFormat = audio.src.match("audio/mpeg")!=null ? true : false;
                        console.dir('sfvm is ' + view.soundFileValidFormat);
                        console.log("duration: " + view.soundFileDuration + " seconds");
                        console.dir(audio);
                    },false);
                };

                reader.readAsDataURL(file);
            }
        }, false);
    },

    processWebFile: function(view){
        console.log('processing sound file url');
        view.message("downloading sound file . . .");

        const formData = new FormData();
        formData.append('fileurl',view.query("input[name='fileurl']").value);
        formData.append('name',document.getElementById("nameupload").value);
        formData.append('description',document.getElementById("descupload").value);
        const type = view.query("input[name='fileurl']").value.match("m4a") ? "m4a" : "mp3";
        formData.append('type', type);

        var soundFile = new Audio(view.query("input[name='fileurl']").value);

        soundFile.addEventListener('error', function(event) {
            console.log('error downloading sound file');
            console.dir(soundFile.error);
            view.message("error: cannot download sound file, not found","error");
            view.resetButtons();
        })

        soundFile.addEventListener('loadedmetadata', function(){
            if(soundFile.duration>11.0){
                view.resetButtons();
                alert('ERROR: sound file is longer than 10 seconds');
                return;
            };

            // post form data
            const xhr = new XMLHttpRequest();

            //handle response
            xhr.onload = () => {
                if (xhr.readyState === xhr.DONE) {
                    if (xhr.status === 200) {
                        console.log('sound successfully uploaded from web');
                        var soundData = JSON.parse(xhr.response);
                        var sound = new app.Sound(soundData);
                        app.sounds.add(sound,{at:0});
                        if(app.platform=="app") app.fsutils.soundSetUp(sound);
                        view.query('[id="nav-search-tab"]').click();
                        view.clearForms();
                    }else{
                        console.log('error uploading sound from URL, status: ' + xhr.status);
                        var response = JSON.parse(xhr.response);
                        if(response.error){
                            console.log('ERROR: ' + response.error);
                            view.message("error saving sound file: " + response.error, "error");
                            view.resetButtons();
                        }
                    }
                }
            };

            // create and send the reqeust
            xhr.open('POST', app.config.restApiUrl + "/webaudiofile");
            xhr.setRequestHeader('Authorization', app.auth.jwtToken);
            xhr.send(formData);

        },false);

        soundFile.load();

    },

    validUrl: function (string) {
        let givenURL ;
        try {
            givenURL = new URL (string);
        } catch (error) {
            return false;
        }
        return true;
    }

};