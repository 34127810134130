/**
 * @fileOverView
 * @description Form Text Input View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var VybTest = require("../../models/vybtest");
var app = require('ampersand-app');
var AmpersandView = require('ampersand-view');

var VparamView = AmpersandView.extend({

    template: app.templates.DisplayTemplate,

    bypassBlurSave: false,

    initialize: function(){
               this.listenTo(this.model,'change:editing',function(){
                   this.render();
               } );

               //keep moderation display updated
               if(this.model.name==="moderationDisplay"){
                   this.listenTo(app.vyb,'change:access', function(){
                       this.render();
                   } );
               }

               //keep these updated
               if(this.model.name==="eventUri"
                   || this.model.name==="schedule"
                   || this.model.name==="geofenceDisplay"
                   || this.model.name=="subscriptionLinks"){
                   this.listenTo(app.vyb,'change:' + this.model.name, function(){
                       this.render();
                   } );

                   this.listenTo(app.vyb,'change:triggerType', function(){
                       this.render();
                   } );

               }
    },

    render: function(){
        var value = "";

        //vyb specific
        if(this.model.entity=="vyb" && app.vyb[this.model.name]!=null && app.vyb[this.model.name].length>0){
            value = app.vyb[this.model.name];
            if(this.model.name=="frequency") this.model.display = app.vyb.displayFrequency;
            if(this.model.name=="type" && app.vyb.key==undefined) this.model.display="event";
            this.model.displayPlaceholder = false;

        //new vyb
        }else if(this.model.entity=="vyb" && (app.vyb.get(this.model.name)==null || app.vyb.get(this.model.name).length==0) && this.model.placeholder!=undefined) {
            value = this.model.placeholder;
            this.model.displayPlaceholder = true;

        //display
        }else if(!this.model.editing && this.model.displayName!=undefined && app[this.model.entity][this.model.displayName]!=undefined){
            value = app[this.model.entity][this.model.displayName];

        //misc
        } else if (app?.[this.model.entity]?.[this.model.name] != null) {
            value = app[this.model.entity][this.model.name];

        }

        this.model.value = value.toString();
        this.model.display = this.model.value;
        if(this.model.name=="frequency") this.model.display = app.vyb.displayFrequency;
        /*if(this.model.name=="displayFollowers"){
            this.model.display = app.vyb.displayFollowers.length ? app.vyb.displayFollowers : this.model.placeholder;
        }*/

        //imageUrl
        if(this.model.name=="imageUrl" && this.model.value.match(/^http/)) this.model.display = '<img class="vlogimg" src="'+ this.model.value +'" />';

        //edit mode
       if(this.model.editing || this.model.inputType=="button"){
            this.template = app.templates[this.model.template];

        //display mode
        }else{
            this.template = app.templates.DisplayTemplate;
            if(this.model.name=="diagnostics"){
                this.model.value = app.vyb.diagnostics.toString();
                this.model.display = YAML.stringify(app.vyb.diagnostics).replace("response","<br/>response");
            }
        }

        this.renderWithTemplate();
        this.postRenderStuff();
    },

    postRenderStuff: function(){

        if(this.model.editing==true){
            if(this.model.value==this.model.placeholder) this.model.value="";
            if(this.model.entity=="vyb" && this.model.inputType=="select"){
                this.el.querySelector("select[data-param='frequency']," +
                    "select[data-param='type']," +
                    "select[data-param='access']," +
                    "select[data-param='triggerType']"
                ).querySelector("option[value='"+this.model.value+"']").selected = 'selected';
            }

            var self = this;
            if(this.model.inputType!="button") setTimeout(
                function(){ if(self.el.querySelector(".vedit")!=null) self.el.querySelector(".vedit").focus(); },
                10);

            //schedule trigger
            if(this.model.name==="schedule") app.utils.configScheduleSettings('current',this);

            //geofence trigger
            if(this.model.name==="geofenceDisplay") app.utils.configGeofenceSettings(this);

            app.utils.setDisplayMode();
        }
    },

    bindings: {

        "model.name" : {
            type: "attribute",
            name: "data-param",
            selector: ".vedit"
        },

        "model.value" : [
            {
                type: "attribute",
                name: "value",
                selector: ".vedit"
            },
            {
                type: "text",
                selector: "textarea"
            }
        ],
        "model.display" : {
            type: "innerHTML",
            selector: "div.vparam"
        },
        "model.cursor" : {
            type: "booleanClass",
            name: "vnone",
            selector: "div.vparam"
        },
        "model.displayPlaceholder" : {
            type: "booleanClass",
            name: "vplaceholder",
            selector: "div.vparam"
        },

        //for vyb send
        "model.shakeElement" : {
            type: "booleanClass",
            name: "vybshake",
            selector: "button.vybtest > span"
        }
    },

    events: {
        "click div.vparam" : "editParam",
        "blur input,textarea,select" : "saveParamOnBlur",
        "change select" : "saveParam",
        "keypress" : "saveOnEnter",
        "click button.vybtest" : "sendVyb",
        "click .vts-cancel" : "cancelSave"
    },

    cancelSave: function(){
        app.vyb.set('editing',false);
        app.header.set('editing',false);
        this.model.set('editing',false);
    },

    editParam: function(){

        if(this.model.type=="none"
            || this.model.type=="button"
            ||(app.currentPageName=="VybPage" && app.vyb.name==undefined && this.model.name!="name")
            || (this.model.bypassSave && app.header.get("editing")===true)
        ) return;

        console.log('editParam');

        if(this.model.inputType=="page"){
            if(this.model.name=='soundName'){
                var view =   new app.SoundsPageView({mode:"vyb", model: app.vyb });
            }else if(this.model.name=='displayFollowers'){
                var view =   new app.PeepsPageView({mode:"vyb", model: app.vyb });
            }
            app.pageSwitcher.set(view);
            return;
        }

        app.header.set({editing:true});
        this.model.set({editing:true});

        if(this.model.bypassSave==true) app.header.set("bypassSave",true);
    },

    saveParam: function(){

        if(this.model.bypassSave && app.header.get("editing")===true) return;
        this.bypassBlurSave = true;

        console.log('saveParam');

        var prop = this.model.name;
        var curVal = this.model.value;
        var inputVal = null;
        if(this.el.querySelector("[data-param='"+prop+"']")!=null
            && this.el.querySelector("[data-param='"+prop+"']")!=undefined){
            inputVal = this.el.querySelector("[data-param='"+prop+"']").value;
        }

        if(prop=="name" || prop=="description") inputVal = inputVal.replace(/<[^]*?>/ig,'');

        if(curVal==inputVal){
            this.model.set('editing',false);
            app.header.set('ignoreclick',true);
            app.header.set('editing',false);
            return;
        }

        var data = {};
        data[this.model.name] = inputVal;
        app[this.model.entity].patch(data, this.model);

    },

    saveOnEnter: function(event){
        if(this.model.inputType!="textarea" && event.keyCode===13){
            this.bypassBlurSave = true;
            this.saveParam();
        }
    },

    saveParamOnBlur: function(){

      if(this.bypassBlurSave){
          this.bypassBlurSave = false;
          return;
      }

      console.log('saveParam on blur');
      this.saveParam();
    },

    sendVyb: function(){

        app.vyb.testing = true;

        var vyb = app.vyb
        var test = new VybTest({triggerKey: vyb.triggerKey, type:vyb.type});
        var request = vyb.type=="event" ? vyb.eventUri : vyb.pollUri;
        this.model.set("pinging",true);
        var self = this;

        test.fetch({
            success: function(model,response){
                var diagnostics = {request:request, response: response};
                vyb.set("diagnostics",diagnostics);
                self.model.set("pinging",false);
            },
            error: function(model,response){
                console.log(response);
                var diagnostics = {request:request, response: response.body};
                vyb.set("diagnostics",diagnostics);
                self.model.set("pinging",false);
                //TODO, redirect to log if account suspended
            }
        });
    }
});

module.exports = VparamView;
