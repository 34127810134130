/**
 * @fileOverView
 * @description Peep View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var PeepTemplate = require('../../templates/widgets/peeptemplate.html');
var app = require('ampersand-app');

var PeepView = AmpersandView.extend({
    template: PeepTemplate,

    render: function(){
        this.renderWithTemplate();
    },

    bindings: {
        "model.name": {
            type: 'text',
            hook: 'name'
        },
        "model.statusDisplay" : {
            type: "innerHTML",
            hook: "status"
        }
    },

    events: {
        "click [data-hook=remove]" : "removePeep",
    },

    removePeep: function(){
        var msg = "remove this peep?";
        if(this.model.accessStatus=="invited"){
            msg = "revoke invitation?";
        }
        if(confirm(msg)){
            app.tmpDisableScroll = true;
            this.model.destroy();
        }
    }

});

module.exports = PeepView;
