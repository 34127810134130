/**
 * @fileOverView
 * @description Doc Model, pulls current documentation<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandModel = require('ampersand-model');
var app = require("ampersand-app");

var Subscription = AmpersandModel.extend({

    idAttribute: 'key',

    urlRoot: function(){
        return app.config.restApiUrl + "/subscription";
    },

    props: {
        key: 'string',
        name: 'string',
        ownerName: 'string',
        description: 'string',
        imageUrl: 'string',
        linkUrl: 'string',
        soundKey: 'string',
        soundType: 'string',
        access: 'string'
    },

    session: {
        pinging: ['boolean',true,false]
    },

    derived: {

        link: {
            deps: ['key'],
            fn: function(){
                var targetUrl = encodeURIComponent(app.config.baseApiUrl + "?skclick=1&sk=" + this.key);
                targetUrl = "https://" + app.config.dynamicLinkDomain + "?link=" + targetUrl;
                return targetUrl;
            }
        },

        imgLink: {
            deps: ['imageUrl','linkUrl'],
            fn: function(){

                var html = "";

                if(this.imageUrl!=undefined && this.imageUrl.length>0){
                    html = '<img alt="" class="vlogimg" src="'+this.imageUrl+'">';

                    if(this.linkUrl!=undefined && this.linkUrl.length>0){
                        html = '<a href="'+this.linkUrl+'" target="_blank" onclick="app.utils.cancelBubble()"><img alt="" class="vlogimg" src="'+this.imageUrl+'"></a>';
                    }
                }else if(this.linkUrl!=undefined && this.linkUrl.length>0){
                    html = '<a href="'+this.linkUrl+'" target="_blank" onclick="app.utils.cancelBubble()">Destination Page</a>';
                }

                return html;

            }
        },

        subscriptionButton: {
            deps: ['access'],
            fn: function(){
                var copy = '<div>SUBSCRIBE TO THIS VYBIT ›</div>';
                copy +=  '<div class="vsowner"> from <span data-hook="vsowner">' + this.ownerName + '</span></div>';

                if(this.access=="private") copy += '<div class="vsubinvite">INVITATION BY OWNER REQUIRED</div>';

                return copy;
            }
        },

        soundFile: {
            deps: ['soundKey','soundType'],
            fn: function(){
                return this.soundKey + '.' + this.soundType;
            }
        },

        qrCode: {
            deps: ['key'],
            fn: function () {
                return '<img src="' + app.config.qrcodeLib + '/' + this.key + '.png">';
            }
        },

        shakeElement: {
            deps: ['pinging'],
            fn: function(){
                if(this.pinging) return true;
                else return false;
            }
        },

        followKey: {
            deps: ['key'],
            fn: function(){
                return '<button class="btn btn-sm btn-outline-success vsubbutton">SUBSCRIBE</button>';
            }
        }
    }

});

module.exports = Subscription;
