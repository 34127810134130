/**
 * @fileOverView
 * @description Sounds View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var Sounds = require('../../collections/sounds');
var SoundsTemplate = require('../../templates/widgets/soundstemplate.html');
var SoundView = require('./soundview');
var SoundCandidateView = require('./soundcandidateview');
var app = require('ampersand-app');

var SoundsView = AmpersandView.extend({

    template: SoundsTemplate,

    initialize: function (options) {
        var self = this;
        this.mode = options.mode;
        this.collection = app.sounds;
        this.soundCandidatesAppended = false;
        this.listenTo(this.collection,'sync',function(){
            self.collection.lastLength = self.collection.curLength;
            self.collection.curLength = self.collection.models.length;

            if(self.collection.curLength < 10 || self.collection.curLength==self.collection.lastLength){
                app.sounds.appendLock = false;
                self.appendSoundCandidates(self);
            }

        });

        this.lastLoad = Date.now();

    },

    render: function(){

        var self = this;

        this.renderWithTemplate(this);

        var self = this;
        this.collection.forEach(function(model){
            model.set('mode',self.mode);
        });

        this.renderCollection(this.collection,SoundView,'#soundstable');
        if(app.soundCandidates.models.length>0){
            this.renderCollection(app.soundCandidates,SoundCandidateView,'#soundcandidatestable',{viewOptions:{mode:this.mode}});
        }

        this.collection.curLength = this.collection.models.length;


        if(app.platform=="app") this.soundFileDir = app.platformId=="ios" ? cordova.file.tempDirectory : cordova.file.cacheDirectory;
        this.soundUsername = app.profile!=undefined ? app.profile.name.replace(" ","_") : "";
        app.sfutils.validateAudioFile(this);

        this.scrollEl = this.query("div.vsounds");
        this.scrollEl.addEventListener("scroll", function(){ self.infiniteScroll(self); } );

    },

    events: {
        "click #record" : "startRecording",
        "click #stop"   : "stopRecording",
        "click #play"   : "playRecording",
        "click #save"   : "uploadRecording",
        "click #saveupload"   : "uploadFile",
        "click #reset, #resetupload" : "resetSound"
    },

    startRecording: function(){ app.sfutils.startRecording(this); },
    stopRecording: function(){ app.sfutils.stopRecording(this); },
    playRecording: function(){ app.sfutils.playRecording(this); },
    uploadRecording: function(event){
        if(!app.auth.authenticated){
            alert('You must be signed in to save recorded sounds.');
            return;
        }
        app.sfutils.uploadSound(event,this);
        },
    uploadFile: function(event){
        if(!app.auth.authenticated){
            alert('You must be signed in to save imported sound files.');
            return;
        }
        //TODO, corner case bug where local file upload fails following a recording
        app.sfutils.uploadSound(event,this);
    },

    resetSound: function(){
        app.sfutils.deleteRecording(this);
        this.clearForms();
    },

    clearForms: function(){
        this.message("");
        this.query("input[name='name']").value = "";
        this.query("input[name='desc']").value = "";
        this.query("input[name='nameupload']").value = "";
        this.query("input[name='descupload']").value = "";
        this.query("input[name='audio']").value = "";
        this.query("input[name='fileurl']").value = "";
        this.query("#filechosen").innerHTML = "";
        this.resetButtons();
    },

    resetButtons: function(){
        this.query("button[id='record']").blur();
        this.query("button[id='stop']").blur();
        this.query("button[id='play']").blur();
        this.query("button[id='save']").blur();
        this.query("button[id='save']").blur();
        this.query("button[id='saveupload']").blur();
        this.query("button[id='reset']").blur();
        this.query("button[id='resetupload']").blur();

    },

    message: function(message="",mode='notice',append=false){
        var rmsg = this.query("div.vrmessage");
        var umsg = this.query("div.vumessage");

        rmsg.classList.remove("vwarning");
        rmsg.classList.remove("verror");
        umsg.classList.remove("vwarning");
        umsg.classList.remove("verror");

        rmsg.innerHTML = append ? rmsg.innerHTML + '. ' + message : message;
        umsg.innerHTML = append ? umsg.innerHTML + '. ' + message : message;

        if(mode=="warning"){
            rmsg.classList.add("vwarning");
            umsg.classList.add("vwarning");
        }

        if(mode=="error"){
            rmsg.classList.add("verror");
            umsg.classList.add("verror");
        }
    },

    infiniteScroll: function(self){


        if(app.sounds.appendLock==true){
            console.log('sounds appendLocked');
            return;
        }

        var wait = 1000;
        var now = Date.now();

        if (self.scrollEl.scrollHeight - self.scrollEl.scrollTop - 10 <= self.scrollEl.clientHeight) {

            //throttle
            /*var diff = now - self.lastLoad;
            if(diff < wait){
                console.log('waiting');
                return;
            }else{
                self.lastLoad = now;
            }*/

            app.utils.startSpinner();
            if(app.sounds.curLength==app.sounds.lastLength){
                app.sounds.appendLock = false;
                self.appendSoundCandidates(self);
                return;
            }

            app.sounds.appendLock = true;
            //TODO, modify offset to use page number instead
            var data = {offset:self.collection.models.length};
            if(app.sounds.searchQuery!=undefined && app.sounds.searchQuery.length>0) data.query = app.sounds.searchQuery;

            self.collection.fetch({
                wait: true,
                remove:false,
                reset:false,
                add:true,
                data:data,
                success: function(collection){
                    app.sounds.appendLock = false;
                    console.log('loaded more sounds');

                    collection.forEach(function(model){
                        model.set('mode',self.mode);
                    });

                    app.utils.stopSpinner();
                },
                error: function(){ app.utils.stopSpinner(); }
            });
        }
    },

    appendSoundCandidates: function(self){

        if(app.soundCandidates.next==null){
            app.utils.stopSpinner();
            return;
        }
        if(app.soundCandidates.appendLock==true) return;

        if(document.querySelector("div.vnoresults")!=null) document.querySelector("div.vnoresults").remove();

        app.soundCandidates.appendLock = true;
        app.soundCandidates.fetch({
            wait: true,
            remove: false,
            reset: false,
            add: true,
            data:{query:app.sounds.searchQuery, filter:'existing', page: app.soundCandidates.page},

            success: function(collection,response){
                if(self.soundCandidatesAppended==false){
                    self.renderCollection(collection,SoundCandidateView,'#soundcandidatestable',{viewOptions:{mode:self.mode}});
                    self.soundCandidatesAppended = true;
                }
                app.soundCandidates.appendLock = false;
                app.utils.stopSpinner();
                console.log('loaded more candidate sounds');
                //self.render();

                //for empty results
                if(self.collection.models.length==0 && collection.models.length==0) document.querySelector("div.vtable").innerHTML = '<div class="vnoresults">no results</div>';

            },
            error: function(){ app.utils.stopSpinner(); }
        });
    }
});

module.exports = SoundsView;
