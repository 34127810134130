/**
 * @fileOverView
 * @description Geofence View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var GeofenceTemplate = require('../../templates/components/geofence.html');
var AmpersandView = require('ampersand-view');
var app = require('ampersand-app');

var GeofenceView = AmpersandView.extend({

    autoRender: true,

    template : GeofenceTemplate,

    initialize: function(options){
        for (const key in options){
            this[key] = options[key];
        }
    },

    bindings: {
        glocation : {
            "hook" : "glocation",
            "type" : "text"
        },
        gmap : {
            "hook" : "gmap",
            "type" : "innerHTML"
        },
        gradius : {
            "hook" : "gradius",
            "type" : "text"
        },
        gradius_units : {
            "hook" : "gradius_units",
            "type" : "text"
        },
        gtype : {
            "hook" : "gtype",
            "type" : "text"
        },
        gtime : {
            "hook" : "gtime",
            "type" : "text"
        },
        gsub : {
            "hook" : "gsub",
            "type" : "text"
        },
    },


});

module.exports = GeofenceView;
