/**
 * @fileOverView
 * @description Logs View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var Logs = require('../../collections/logs');
var LogsTemplate = require('../../templates/widgets/logstemplate.html');
var EmptyTemplate = require("../../templates/widgets/emptytemplate.html");
var LogView = require('./logview');
var app = require('ampersand-app');

var LogsView = AmpersandView.extend({

    template: LogsTemplate,

    emptyTemplate: EmptyTemplate,

    initialize: function (options) {
        this.collection = options.logsScope=='vyb' && app.vyb.logs!=undefined ? app.vyb.logs : app.logs;
        this.entity = "logs";
        this.lastLoad = Date.now();
    },

    render: function(){

        var self = this;

        if(app.vybs!=undefined && app.vybs.length>0 && this.el.querySelector("div.vfirst")!=null) this.el.querySelector("div.vfirst").remove();

        //process subscriptions here
        if(window.localStorage.getItem('subscriptionKey')!=null) app.utils.processSubscription();

        if(this.collection==undefined){
            console.log('logs undefined');
            this.renderWithTemplate(this, '<div id="logs" class="container vcontent animated">\n' +
                '    <div id="logstable" class="vtable">' +
                '</div>\n' +
                '</div>');
            return;
        }else if(this.collection.models.length==0){
            console.log('logs length 0');
            setTimeout(function(){ self.renderWithTemplate(self, self.emptyTemplate); },2000);
            return;
        }else{
            this.renderWithTemplate(this);
        }

        this.renderCollection(this.collection,LogView,'#logstable');

        var scrollClass = app.currentPageName=="MainPage" ? "vscrollbothalf" : "vscrollfull";
        this.el.classList.add(scrollClass);

        //handle selected demo sound
        if(window.localStorage.getItem('demoSoundKey')) app.renderVybPageForDemoSound();

        //handle query string page direction
        if(app.queryParams.p!=undefined) this.switchPageFromQueryParam();

    },

    events: {
        "scroll" : "infiniteScroll",
        "click button.vcreate" : "switchToVybPage"
    },

    infiniteScroll : function(){ app.utils.infiniteScroll(this); },

    switchToVybPage: function(){
        var vybpageview =   new app.VybPageView({model:app.vyb});
        app.pageSwitcher.set(vybpageview);
    },

    switchPageFromQueryParam: function(){

        var pageView = "";

        //sounds page
        if(app.queryParams.p=="sounds"){
            delete app.queryParams.squery;
            var pageView = app.soundsPageView;
        }

        //sound acquistion page
        if(app.queryParams.p=="soundac"){
            var pageView = new app.SoundAcquisitionPageView({viewOptions: {mode:'lib'}});
        }

        delete app.queryParams.p;

        app.pageSwitcher.set(pageView);
    }

});

module.exports = LogsView;
