/**
 * @fileOverView
 * @description Public Website View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var WwwPageTemplate = require('../../templates/pages/wwwpage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var DocsView = require('../widgets/docsview');
var SearchDemoView = require('../widgets/searchdemoview');

var app = require('ampersand-app');

var WwwPage = AmpersandView.extend({

    template : WwwPageTemplate,

    render: function(){
        var self = this;

        this.renderWithTemplate();
        //this.resizeVdocbox();

        window.addEventListener('resize', () => {
            self.resizeVdocbox();
        });
    },

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                return new HeaderView({
                    el: el,
                    parent: this,
                    noback: true,
                    login: true,
                    maintitle: 'Welcome to Vybit!'
                });
            }
        },

        docs: {
            selector : '#docs',
            prepareView: function(el) {
                return new DocsView({
                    el: el,
                    parent: this
                });
            }
        },

        vsdemo: {
            selector: "div.vsdemobox",
            prepareView: function(el) {
                return new SearchDemoView({
                    el: el,
                    parent: this
                });
            }
        }
    },

    events: {
        "click button.vsdemo" : "demoSoundSearch",
        "keypress input.vsdemo" : "dssOnEnter",
    },

    demoSoundSearch: function(){ app.utils.demoSoundSearch(); },

    dssOnEnter: function(event){ app.utils.dssOnEnter(event); },

    resizeVdocbox: function(){
        document.querySelector('div.vdocbox').style.setProperty('height', (window.innerHeight - 260).toString() + 'px');
    }


});

module.exports = WwwPage;
