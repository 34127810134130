/**
 * @fileOverView
 * @description Subscription View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var AmpersandView = require('ampersand-view');
var VybFollowing = require('../../models/vybfollowing');
var SubscriptionTemplate = require('../../templates/widgets/subscriptiontemplate.html');
var config = require("../../etc/config");
var app = require('ampersand-app');

var SubscriptionView = AmpersandView.extend({
    template: SubscriptionTemplate,

    bindings: {

        "model.name" : {
            "hook" : "vsubname",
            "type" : "text"
        },
        "model.ownerName" : {
            "hook" : "vsowner",
            "type" : "text"
        },
        "model.description" : {
            "hook" : "vsubdesc",
            "type" : "innerHTML"
        },
        "model.soundKey" : {
            "hook" : "soundKey",
            "type" : "attribute",
            "name" : "data-soundKey"
        },
        "model.key" : {
            "hook" : "vsubkey",
            "type" : "attribute",
            "name" : "data-vybsubkey"
        },
        "model.imgLink" : {
            "hook" : "vsubimg",
            "type" : "innerHTML"
        },
        "model.subscriptionButton" : {
            "hook" : "vsubkey",
            "type" : "innerHTML"
        },
        "model.qrCode" : {
            "hook" : "vqrcode",
            "type" : "innerHTML"
        },
        "model.followKey" : {
            "hook" : "logDate",
            "type" : "innerHTML"
        },


        //sound img shake
        "model.shakeElement" : {
            type : "booleanClass",
            name : "vybshake",
            selector : "img.vdeltaimg"
        }
    },

    events: {
        "click .vdeltaimg, .vdeltaclick" : "playSound",
        "click .vybclick" : "switchToSubscriptionPage",
        "click .vsubbutton" : "subscribeVyb"
    },

    playSound: function(){
        app.utils.stopShakes();
        this.model.set('pinging',true);
        app.soundViews[this.model.soundKey] = this.model;
        app.utils.playSoundFileFromKey(this.model.soundKey, this.model.soundType);
    },

    openSubscriptionCardPage: function(){
        window.open("/subscription/" + this.model.key, "_blank");
    },

    switchToSubscriptionPage: function(){
        if(window.getSelection().toString().length>0) return;
        else app.utils.switchToSubscriptionPage(this.model);
    },

    subscribeVyb: function(){
        app.utils.subscribeToVybFromKey(this.model.key);
    }


});

module.exports = SubscriptionView;
