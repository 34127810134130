/**
 * @fileOverView
 * @description Single Peep Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var PeepPageTemplate = require('../../templates/pages/peeppage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var PeepFullView = require('../widgets/peepfullview');
var Peep = require("../../models/peep");
var app = require('ampersand-app');

var PeepPageView = AmpersandView.extend({

    initialize: function(options){
      if(!this.model) this.model = options.model ? options.model : new Peep();
      app.peep = this.model;
    },

    template : PeepPageTemplate,

    pageName: "PeepPage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    model: this.model,
                    maintitle: 'Add New Peep'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        peep: {
            selector : '#content',
            prepareView: function(el) {
                return new PeepFullView({
                    el: el,
                    parent: this,
                    model: app.peep
                });
            }
        }
    },

    events: {

    }

});

module.exports = PeepPageView;
