/**
 * @fileOverView
 * @description Single Vyb Page View<br>
 * @copyright 2015-present Flatirontek LLC
 **/

var ProfilePageTemplate = require('../../templates/pages/profilepage.html');
var AmpersandView = require('ampersand-view');
var HeaderView = require('../widgets/header');
var FooterView = require('../widgets/footer');
var ProfileView = require('../widgets/profileview');
var app = require('ampersand-app');

var ProfilePageView = AmpersandView.extend({

    template : ProfilePageTemplate,

    pageName: "ProfilePage",

    subviews: {
        mpheader: {
            selector : 'header',
            prepareView: function(el) {
                app.currentPageName = this.pageName;
                return new HeaderView({
                    el: el,
                    parent: this,
                    maintitle: 'Profile'
                });
            }
        },

        mpfooter: {
            selector : 'footer',
            prepareView: function(el) {
                return new FooterView({
                    el: el,
                    parent: this
                });
            }
        },

        profile: {
            selector : '#content',
            prepareView: function(el) {
                return new ProfileView({
                    el: el,
                    parent: this
                });
            }
        }
    }
});

module.exports = ProfilePageView;
