var ViewSwitcher = require('ampersand-view-switcher');
var app = require('ampersand-app');

var pageSwitcher = new ViewSwitcher(app.pageContainer(), {

    waitForRemove: true,

    hide: function(oldView,cb){

        if(app.back){
            app.lastPage = app.beforeLastPage!=undefined ? app.beforeLastPage : app.mainPageView();
            delete app.beforeLastPage;
            app.back = false;
        }else{
            if(app.lastPage!=undefined) app.beforeLastPage = app.lastPage;
            app.lastPage = oldView;
            app.lastPageName = app.currentPageName!=undefined ? app.currentPageName : "unspecified";
        }

        //console.log('last page was ' + app.lastPageName);

        oldView.el.querySelector("div.vcontent").classList.add('fadeOut');
        setTimeout(function () {
            if(oldView.el.querySelector("div.vcontent")) oldView.el.querySelector("div.vcontent").classList.remove('fadeOut');
            cb();
        }, 200);

    },

    show: function (newView) {

        // set our document title
        document.title = newView.pageTitle || 'Vybit';

        // scroll to the top
        document.body.scrollTop = 0;

        app.currentPage = newView;
        app.utils.setDisplayMode();

        app.currentPageName = newView.pageName!=undefined ? newView.pageName : "unspecified";
        //console.log('current page is ' + app.currentPageName)

        newView.el.querySelector("div.vcontent")?.classList.add('fadeIn');
        setTimeout(function () {
            if(newView.el.querySelector("div.vcontent")) newView.el.querySelector("div.vcontent").classList.remove('fadeIn');
        }, 200);

        //reinstate active tabs
        if(newView.el.querySelector("a.vtab.active")){
            if(window.sessionStorage.getItem(app.currentPageName + "ActiveTab")==null) return;
            newView.el.querySelector("a.vtab.active").classList.remove("active");
            newView.el.querySelector("#" + window.sessionStorage.getItem(app.currentPageName + "ActiveTab")).classList.add("active");
            newView.el.querySelector("div.tab-pane.active.show").classList.remove("active","show");
            newView.el.querySelector("#" + window.sessionStorage.getItem(app.currentPageName + "ActiveTab").replace("-tab","")).classList.add("active", "show");

        }
    }
});

module.exports = pageSwitcher;
